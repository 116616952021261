import React, { Component } from 'react';
import { FacebookProvider, EmbeddedPost, Subscribe } from 'react-facebook';
import loader from '../../../../assets/img/loader.svg';

export default class FacebookPost extends Component {
	state = {
		showLoader: true,
		showPost: 'hide'
	};

	handleChange = () => {
		setTimeout(() => {
			this.setState({
				showLoader: false,
				showPost: ''
			});
		  }, 100);
	}

	render() {
		return (
			<div className="facebook-embed-post text-center">
				{ this.state.showLoader ? <div style={{width: '100%', paddingTop: '10px', textAlign: 'center'}}><img src={loader} alt="loading..." /></div> : null }
				<div className={this.state.showPost}>
					<FacebookProvider appId={process.env.REACT_APP_FB_APP_ID} version={process.env.REACT_APP_FB_VERSION}>
						<Subscribe event="xfbml.render" onChange={this.handleChange}>
							<EmbeddedPost width={document.getElementById('conversation_comment').clientWidth - 27} href={"https://www.facebook.com/" + this.props.post_id.split('_')[0] + '/posts/' + this.props.post_id.split('_')[1]}  />
						</Subscribe>
					</FacebookProvider>
				</div>
			</div>
		);
	}
}