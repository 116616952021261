import React from 'react';
import { Modal } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';

const InstaList = (props) => {

	return (
		<Modal className="fb-page-list" show={props.show} onHide={props.manage.bind(this, 'insta_list_modal')}>
			<Modal.Header closeButton>
				<Modal.Title>List of Instagram Accounts</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="col-sm-12">
						<Scrollbars style={{ height: '400px' }}>
							<div className="page-list-item">
								<div className="row">
									<div className="col-sm-9 text-left">
										<h2 className="page-title">
											@{props.insta_username}
										</h2>
									</div>
									<div className="col-sm-3 text-center">
										<div className="page-subscribe-btn">
											<span className="btn btn-primary" onClick={props.manage.bind(this, 'insta_list_modal')}>Subscribe</span>
										</div>
									</div>
								</div>
							</div>
						</Scrollbars>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default InstaList;