import React from 'react';
import Logo from './Logo/Logo';
import Menu from './Menu/Menu';
import * as UserService from '../../services/UserService';

const Header = (props) => {
	const isLoggedIn = UserService.isLoggedIn();
	return (
		<header>
			<nav className="navbar navbar-default listenyzen-navbar">
				<div className="container-fluid">
					<Logo />
					{isLoggedIn ? <Menu /> : null}
				</div>
			</nav>
		</header>
	);
};

export default Header;