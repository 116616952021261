import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import loader from '../../../assets/img/loader.svg';

const msToHMS = ( ms ) => {
    var seconds = ms / 1000;
	var hours = parseInt( seconds / 3600 );
    seconds = seconds % 3600;
    var minutes = parseInt( seconds / 60 );
    seconds = parseInt(seconds % 60);
    return ("0" + hours).slice(-2)+":"+("0" + minutes).slice(-2)+":"+("0" + seconds).slice(-2);
}

const numberWithCommas = (x) => {
	var parts = x.toString().split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return parts.join(".");
}

const CardSection = (props) => {
	return (
		<div className="analytics-cards">
			<div className="row">
				<div className="col-sm-6">
					<Link to={'/databank/' + props.match.params.project_id}>
						<div className="analytics-card-item query-received">
							<h4>Query Received</h4>
							<i className="fa fa-envelope"></i>
							<span className="count-numbers">{props.loading ? <img src={loader} alt="loader" style={{marginBottom: '20px'}} /> : numberWithCommas(props.counts.received_count)}</span>
						</div>
					</Link>
				</div>
				<div className="col-sm-6">
					<div className="analytics-card-item query-resolved">
						<h4>Resolved</h4>
						<i className="fa fa-check-circle"></i>
						{props.loading ? <span className="count-numbers"><img src={loader} alt="loader" style={{marginBottom: '20px'}} /></span> : 
						<><span className="count-numbers">{props.counts.received_count !==0 ? ((props.counts.resolved_count/props.counts.received_count) * 100).toFixed(2) : 0}%</span>
						<span className="count-name"><OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">No of queries resolved</Tooltip>}><span>{numberWithCommas(props.counts.resolved_count)}</span></OverlayTrigger></span></>}
					</div>
				</div>
				<div className="col-sm-6">
					<div className="analytics-card-item query-unresolved">
						<h4>Unresolved</h4>
						<i className="fa fa-times"></i>
						{props.loading ? <span className="count-numbers"><img src={loader} alt="loader" style={{marginBottom: '20px'}} /></span> : 
						<><span className="count-numbers">{props.counts.received_count !==0 ? (100 - ((props.counts.resolved_count/props.counts.received_count) * 100).toFixed(2)).toFixed(2) : 0}%</span>
						<span className="count-name"><OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">No of queries unresolved</Tooltip>}><span>{numberWithCommas(props.counts.received_count - props.counts.resolved_count)}</span></OverlayTrigger></span></>}
					</div>
				</div>
				<div className="col-sm-6">
					<div className="analytics-card-item query-hidden">
						<h4>Queries Hidden</h4>
						<i className="fa fa-eye-slash"></i>
						{props.loading ? <span className="count-numbers"><img src={loader} alt="loader" style={{marginBottom: '20px'}} /></span> : 
						<><span className="count-numbers">{props.counts.received_count !==0 ? ((props.counts.hide_count/props.counts.received_count) * 100).toFixed(2) : 0}%</span>
						<span className="count-name"><OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">No of queries hidden</Tooltip>}><span>{numberWithCommas(props.counts.hide_count)}</span></OverlayTrigger></span></>}
					</div>
				</div>
				<div className="col-sm-6">
					<div className="analytics-card-item query-deleted">
						<h4>Queries Deleted</h4>
						<i className="fa fa-trash"></i>
						{props.loading ? <span className="count-numbers"><img src={loader} alt="loader" style={{marginBottom: '20px'}} /></span> : 
						<><span className="count-numbers">{props.counts.received_count !==0 ? ((props.counts.delete_count/props.counts.received_count) * 100).toFixed(2) : 0}%</span>
						<span className="count-name"><OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">No of queries deleted</Tooltip>}><span>{numberWithCommas(props.counts.delete_count)}</span></OverlayTrigger></span></>}
					</div>
				</div>
				<div className="col-sm-6">
					<div className="analytics-card-item query-liked">
						<h4>Queries Liked</h4>
						<i className="fa fa-thumbs-up"></i>
						{props.loading ? <span className="count-numbers"><img src={loader} alt="loader" style={{marginBottom: '20px'}} /></span> : 
						<><span className="count-numbers">{props.counts.received_count !==0 ? ((props.counts.like_count/props.counts.received_count) * 100).toFixed(2) : 0}%</span>
						<span className="count-name"><OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">No of queries liked</Tooltip>}><span>{numberWithCommas(props.counts.like_count)}</span></OverlayTrigger></span></>}
					</div>
				</div>
				<div className="col-sm-6">
					<div className="analytics-card-item query-response-time">
						<h4>Avg. Response Time</h4>
						<i className="fa fa-clock-o"></i>
						<span className="count-numbers">{props.loading ? <img src={loader} alt="loader" style={{marginBottom: '20px'}} /> : msToHMS(props.counts.avg_response_time)}</span>
					</div>
				</div>
				<div className="col-sm-6">
					<div className="analytics-card-item auto-replied">
						<h4>Query Manager Replied</h4>
						<i className="fa fa-reply-all"></i>
						{props.loading ? <span className="count-numbers"><img src={loader} alt="loader" style={{marginBottom: '20px'}} /></span> : 
						<><span className="count-numbers">{props.counts.total_replied_count !==0 ? ((props.counts.qm_replied_count/props.counts.total_replied_count) * 100).toFixed(2) : 0}%</span>
						<span className="count-name"><OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">No of replies made by query managers</Tooltip>}><span>{numberWithCommas(props.counts.qm_replied_count)}</span></OverlayTrigger> (<OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">No of replies made in total</Tooltip>}><span>{numberWithCommas(props.counts.total_replied_count)}</span></OverlayTrigger>)</span></>}
					</div>
				</div>
				<div className="col-sm-6">
					<div className="analytics-card-item query-liked">
						<h4>Query Tagged</h4>
						<i className="fa fa-envelope"></i>
						{props.loading ? <span className="count-numbers"><img src={loader} alt="loader" style={{marginBottom: '20px'}} /></span> : 
						<><span className="count-numbers">{props.counts.received_count !==0 ? ((props.counts.tagged_count/props.counts.received_count) * 100).toFixed(2) : 0}%</span>
						<span className="count-name"><OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">No of queries tagged</Tooltip>}><span>{numberWithCommas(props.counts.tagged_count)}</span></OverlayTrigger></span></>}
					</div>
				</div>
				<div className="col-sm-6">
					<div className="analytics-card-item query-hidden">
						<h4>Queries in Followup</h4>
						<i className="fa fa-eye-slash"></i>
						{props.floading || props.loading ? <span className="count-numbers"><img src={loader} alt="loader" style={{marginBottom: '20px'}} /></span> : 
						<><span className="count-numbers">{props.fcounts.followup_count !==0 ? ((props.fcounts.followup_count/props.counts.received_count) * 100).toFixed(2) : 0}%</span>
						<span className="count-name"><OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">No of queries in Followup</Tooltip>}><span>{numberWithCommas(props.fcounts.followup_count)}</span></OverlayTrigger></span></>}
					</div>
				</div>
				<div className="col-sm-6">
					<div className="analytics-card-item query-resolved">
						<h4>New Users</h4>
						<i className="fa fa-user-circle-o"></i>
						<span className="count-numbers">{props.uloading || props.loading ? <img src={loader} alt="loader" style={{marginBottom: '20px'}} /> : numberWithCommas(props.ucounts.new_user_count)}</span>
					</div>
				</div>
				<div className="col-sm-6">
					<div className="analytics-card-item query-deleted">
						<h4>Existing Users</h4>
						<i className="fa fa-user"></i>
						<span className="count-numbers">{props.eloading || props.loading ? <img src={loader} alt="loader" style={{marginBottom: '20px'}} /> : numberWithCommas(props.ecounts.existing_user_count)}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		loading: state.report_state.loading,
		floading: state.report_state.floading,
		uloading: state.report_state.uloading,
		eloading: state.report_state.eloading,
		counts: state.report_state.counts,
		fcounts: state.report_state.fcounts,
		ucounts: state.report_state.ucounts,
		ecounts: state.report_state.ecounts
	};
}

export default withRouter(connect(mapStateToProps)(CardSection));