import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { gettagByProject, deleteTagbyProject } from '../../../services/TagService';

class TableSection extends Component {

	state = {
		tags: []
	};

	componentDidMount() {
		gettagByProject(this.props.match.params.project_id)
		.then(response => {
			this.setState({
				...this.state,
				tags: this.state.tags.concat(response.data)
			});
		})
		.catch(err => {});
	}

	deleteTag = (id) => {
		deleteTagbyProject(this.props.match.params.project_id, id)
		.then(response => {
			toast.success("😀 Tag was deleted successfully!", {
				autoClose: false,
				position: 'bottom-right',
				className: 'notification-message'
			});
			var myArray = _.cloneDeep(this.state);
			myArray = myArray.tags.filter(function( obj ) {
				return obj._id !== id;
			});
			this.setState({
				tags: myArray
			});
		})
		.catch(err => {});
	}

	render() {
		return (
			<table className="table table-bordered table-hover" style={{width: '90%', margin: '0 auto'}}>
				<thead>
					<tr>
						<th className="text-center">Tag</th>
						<th className="text-center">Delete</th>
					</tr>
				</thead>
				<tbody>
				{this.state.tags.map(data => (
					<tr key={data._id}>
						<td width="70%" className="text-center">{data.tag_title}</td>
						<td width="30%" className="text-center"><i className="fa fa-trash-o" onClick={this.deleteTag.bind(this, data._id)} style={{cursor: 'pointer'}}></i></td>
					</tr>
				))}
				</tbody>
			</table>
		);
	}
};

export default withRouter(TableSection);