import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Wrapper from '../../components/Wrapper/Wrapper';
import RfilterBar from '../../components/RfilterBar/RfilterBar';
import ReplymakerContent from '../../components/ReplymakerContent/ReplymakerContent';
import ReplySuggestionModal from '../../components/Modals/ReplySuggestion/ReplySuggestion';

class Replymaker extends Component {
	state = {
		modalShow: false,
		intent: [],
		entities: [],
		suggestion: '',
		id: ''
	};

	componentDidMount() {
		document.title = 'Replymaker - listenyzen';
		document.body.classList.add('main-body');
	}

	componentWillUnmount() {
		document.body.classList.remove('main-body');
	}

	manageModal = (data) => {
		if(this.state.modalShow === false) {
			if(data && data._id) {
				this.setState(prevstate => ({
					modalShow: !prevstate.modalShow,
					intent: [{
						value: data.intent,
						label: data.intent
					}],
					entities: data.entities.map(entity => ({value: entity, label: entity})),
					suggestion: data.suggestion,
					id: data._id
				}));
			} else {
				this.setState(prevstate => ({
					modalShow: !prevstate.modalShow,
					intent: [],
					entities: [],
					suggestion: '',
					id: ''
				}));
			}
		} else {
			this.setState(prevstate => ({
				modalShow: !prevstate.modalShow,
				intent: [],
				entities: [],
				id: ''
			}));
		}
	}

	render() {
		return (
			<div className="Replymaker">
				<Header />
				<Wrapper>
					<div className="analytics-dashboard">
						<div className="container-fluid">
							<RfilterBar intents={this.props.location.state.intents} entities={this.props.location.state.entities} manage={this.manageModal} />
							<ReplymakerContent manage={this.manageModal} />
							<ReplySuggestionModal project_id={this.props.match.params.project_id} default_intent={this.state.intent} default_entities={this.state.entities} default_suggestion={this.state.suggestion} intents={this.props.location.state.intents.map(intent => intent.title)} entities={this.props.location.state.entities.map(entity => entity.title)} id={this.state.id} show={this.state.modalShow} manage={this.manageModal} />
						</div>
					</div>
				</Wrapper>
			</div>
		);
	}
};

export default withRouter(Replymaker);