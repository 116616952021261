import axios from 'axios';
import { toast } from 'react-toastify';

const instance = axios.create({
	baseURL: process.env.REACT_APP_SERVER_URL,
	withCredentials: true
});

// error handling
instance.interceptors.response.use(function (response) {
	return response;
}, function (error) {
	if (error.response) {
		if(error.response.data.message) {
			toast.error("😪 " + error.response.data.message, {
				autoClose: false,
				position: 'bottom-right',
				className: 'notification-message'
			});
		} else {
			toast.error("😪 There was a problem processing your request!", {
				autoClose: 5000,
				position: 'bottom-right',
				className: 'notification-message'
			});
		}
	} else {
		toast.error("😪 There was an error processing your request!", {
			autoClose: 5000,
			position: 'bottom-right',
			className: 'notification-message'
		});
	}
	return Promise.reject(error);
});

export default instance;