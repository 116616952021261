import React from 'react';
import Header from './Header/Header';
import Toolbar from './Toolbar/Toolbar';
import UserList from './UserList/UserList';

const LeftSection = (props) => {
	return (
		<div className="col-sm-3 left-section" id="left-section">
			<div className="side-one">
				<Header />
				<Toolbar />
				<UserList />
			</div>
		</div>
	);
};

export default LeftSection;