import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'moment-timezone';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../store/actions/report_actions';

class TableSection extends Component {

	loadData = (project_id, platform, skip, keyword) => {
		this.props.getDatabank(project_id, platform, skip, keyword);
	}

	componentDidMount() {
		this.props.getSuggestions(this.props.match.params.project_id);
	}

	deleteSuggestion = (id) => {
		var r = window.confirm("Are you sure you want to delete this reply suggestion? This action can not be undone.");
		if (r === true) {
			this.props.deleteSuggestion(this.props.match.params.project_id, id);
		} else {
			alert("You canelled deleting this reply suggestion!");
		}
	}

	render() {
		return (
			<table className="table table-bordered table-hover" style={{width: '90%', margin: '0 auto'}}>
				<thead>
					<tr>
						<th className="text-center">Intent</th>
						<th className="text-center">Entity</th>
						<th className="text-center">Reply Suggestion</th>
						<th className="text-center">Action</th>
					</tr>
				</thead>
				<tbody>
				{this.props.suggestions.map(data => (
					<tr key={data._id}>
						<td width="10%" className="text-center">{data.intent}</td>
						<td className="text-center">{data.entities.join(', ')}</td>
						<td width="60%">{data.suggestion}</td>
						<td className="text-center">
							<div className="row">
								<div className="col-sm-4"></div>
								<div className="col-sm-2">
									<i className="fa fa-pencil" onClick={this.props.manage.bind(this, data)}></i>
								</div>
								<div className="col-sm-2">
									<i className="fa fa-trash-o" onClick={this.deleteSuggestion.bind(this, data._id)} style={{fontSize: '16px'}}></i>
								</div>
								<div className="col-sm-4"></div>
							</div>
						</td>
					</tr>
				))}
				</tbody>
			</table>
		);
	}
};

const mapStateToProps = state => {
	return {
		suggestions: state.report_state.replies
	};
}

const mapActionToProps = dispatch => {
	return {
		clearReports: () => dispatch(actions.clearReports()),
		getSuggestions: (project_id, intent) => dispatch(actions.getSuggestions(project_id, intent)),
		deleteSuggestion: (project_id, id) => dispatch(actions.deleteSuggestion(project_id, id))
	};
};

export default withRouter(connect(mapStateToProps, mapActionToProps)(TableSection));