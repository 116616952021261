import React, { Component } from 'react';
import Header from '../Header/Header';
import Wrapper from '../Wrapper/Wrapper';
import notfi from '../../assets/img/404.png';

class NotFound extends Component {
	componentDidMount() {
		document.title = 'Page not found - listenyzen';
		document.body.classList.add('main-body');
	}

	componentWillUnmount() {
		document.body.classList.remove('main-body');
	}

	render() {
		return (
			<div className="NotFound">
				<Header />
				<Wrapper>
					<div className="text-center mt pt" style={{fontSize: '16px'}}>
						<img src={notfi} alt="Not Found 404" />
						<p style={{marginTop: '10px'}}>Oops...couldn't find the page you are looking for! You can click the listenyzen Logo on top left to go Home</p>
					</div>
				</Wrapper>
			</div>
		);
	}
};

export default NotFound;