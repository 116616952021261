import React, { Component } from "react";
import Header from "../../components/Header/Header";
import Wrapper from "../../components/Wrapper/Wrapper";

class Terms extends Component {
  componentDidMount() {
    document.title = "Terms of Use - listenyzen";
    document.body.classList.add("main-body");
  }

  componentWillUnmount() {
    document.body.classList.remove("main-body");
  }

  render() {
    return (
      <div className="Terms">
        <Header />
        <Wrapper>
          <div className="terms-container">
            <h3>Terms and Privacy Policy</h3>

            <p>
              listenyze<sup>n</sup> is committed to protecting your privacy and
              complying with applicable data protection laws. This Privacy
              Policy outlines how listenyze<sup>n</sup> collects, uses,
              discloses, and safeguards information about you. We also describe
              the choices you have about your information.
            </p>

            <h4>Our Services</h4>
            <p>
              listenyze<sup>n</sup> offers a suite of social media customer
              query management tools that are accessible via our website. These
              tools allow you to bring together all of your social media
              customer queries for easy access and management through a single
              online portal. Through this portal, you can gather all your social
              media customer queries, respond to those queries, engage with your
              audiences and analyze the results of these activities.
              Collectively, we refer to these tools as our "Services".{" "}
            </p>
            <p>
              Any collection, use, and management of personal information by the
              social networks, including Facebook, Messenger, Instagram and
              Viber (collectively, the "Social Networks") are governed by their
              respective privacy policies and terms. When using Social Networks,
              you are required to comply with their privacy policies and terms.
              We recommend you carefully review their privacy policies and
              terms, as listenyze<sup>n</sup> is not responsible for the Social
              Networks.
            </p>
            <p>
              Our Services are not intended for use by children and should only
              be accessed by individuals who are at least 18 years old and are
              using the Services for business purposes.
            </p>

            <h4>What information do we collect?</h4>
            <p>
              We collect the following information when you use our Services:
            </p>
            <p>
              <b>Account information:</b>
            </p>
            <ul>
              <li>
                Your contact and profile information including your name, email
                address, organization name, and address; your preferences such
                as language, time zone, and the types of communication you would
                like to receive from us; and image (if you choose to provide
                this). We may also obtain this information if you choose to use
                a social login service, such as Facebook Login, to create or
                access your account.
              </li>
              <li>
                Billing and other payment information (if you sign up for a paid
                service), including payment method details, such as credit card
                number.
              </li>
              <li>
                The Services you have acquired from us, including the type of
                plan, number of team members, and transaction information
                related to the Services.
              </li>
            </ul>

            <p>
              <b>Content:</b>
            </p>
            <ul>
              <li>
                Your social profile information for Social Networks you choose
                to connect to the Services. For example, your Facebook profile
                information may include your Facebook nam e and profile image.
              </li>
              <li>
                Your phone number if you choose to share this information.
              </li>
              <li>
                Your messages, posts, comments, images, advertising, and other
                material you curate on and upload to the Services; and
                information that is collected from the Social Networks that you
                choose to connect to and which is displayed on our Services
              </li>
              <li>
                Content that you may send and receive through Social Networks
                may contain personal information that listenyze<sup>n</sup> does
                not directly collect or process. This may include information
                such as: names, photos, age, gender, geographic location,
                opinions, preferences, and phone numbers.
              </li>
            </ul>

            <p>
              <b>Logs, usage, and support data:</b>
            </p>
            <ul>
              <li>
                Log data, which may include your IP address, the address of the
                web page you visited before using the Services, your browser
                type and settings, your device information (such as make, model,
                and OS), the date and time when you used the Services,
                information about your browser configuration, language
                preferences, unique identifiers, and cookies.
              </li>
              <li>
                Usage data and analytics, which may include the frequency of
                login, and the different types of activity undertaken by users.
              </li>
              <li>
                General Location information, such as IP address and the region
                in which you are located when you are logging in and using the
                Services.
              </li>
              <li>
                Customer support questions, issues, and general feedback that
                you choose to provide.
              </li>
            </ul>

            <h4>Disclosure of User Information</h4>
            <p>
              listenyze<sup>n</sup> does not sell, trade or transfer User
              Information to third parties. However, we may share User
              Information with our business partners for marketing, advertising
              or product/service offering purposes. For example, we provide User
              Information to our service providers for direct emailing of our
              newsletters, online surveys or notifications. We also disclose
              User Information if: we have your consent; we need to share it in
              order to provide you with the products and/or services you
              requested; we respond to a court order or you violate our Terms of
              Use. You may separately agree to provide your personal information
              to third parties that provide content for Listenyen Offerings, in
              order to access and/or use their products and/or services. If you
              agree to provide such information to these third parties, then
              your personal information will be subject to their privacy
              policies.
            </p>

            <h4>What are your rights regarding the information about you?</h4>
            <p>
              When using our Services, you may provide us your social account
              access so that we can provide you with our smooth services. But
              you have the complete control over this access. Requests to
              access, correct, update, or delete your information can be made in
              writing to our privacy team at mail@analyzenbd.com and will be
              handled within fourteen (14) days unless they are unusually
              extensive or complex, in which case we will advise you of the
              expected timeline for handling your request. If you have
              authorized us to access your Social Network account to provide the
              Services, you may revoke this access at any time by following the
              below instructions:
            </p>

            <p>
              <b>
                Revoke listenyze<sup>n</sup> access to your Facebook account:
              </b>
            </p>
            <ul>
              <li>
                Sign in to Facebook using this link:{" "}
                <a
                  href="https://facebook.com/settings?tab=business_tools."
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  facebook.com/settings?tab=business_tools
                </a>
                .
              </li>
              <li>Select the box beside "listenyzen".</li>
              <li>
                Select <b>Remove</b>*.
              </li>
              <li>
                * Revoking Facebook access disconnects any Facebook Pages your
                profile is admin of, and any Instagram Business profiles linked
                to those Pages. Each Instagram profile will need to be
                reconnected.
              </li>
            </ul>
          </div>
        </Wrapper>
      </div>
    );
  }
}

export default Terms;
