import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

class FullReport extends Component {

	hideModal = () => {
		this.props.manage();
	}

	numberWithCommas = (x) => {
		var parts = x.toString().split(".");
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return parts.join(".");
	}

	render() {
		return (
			<Modal className="qm-list" show={this.props.show} onHide={this.hideModal}>
				<Modal.Header closeButton>
					<Modal.Title>Full Report</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				<div className="row">
					<div className="col-sm-12">
						<div className="qm-list-item">
							<div className="row">
								<div className="col-sm-12">
									<table className="table table-bordered table-hover">
										<thead>
											<tr>
												<th>{this.props.type === 'intent' ? 'User Intent' : this.props.type === 'entity' ? 'Entity' : 'Tag'}</th>
												<th className="text-center">Count</th>
											</tr>
										</thead>
										{this.props.data ? <tbody>
											{this.props.data.map((d, index) => (
												<tr key={index}>
													<td>{d._id}</td>
													<td className="text-center">{this.numberWithCommas(d.count)}</td>
												</tr>
											))}
										</tbody> : null}
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				</Modal.Body>
			</Modal>
		);
	}
}

export default FullReport;