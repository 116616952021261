import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import Copyright from '../../components/Copyright/Copyright';
import { Form, FormControl, FormGroup, ControlLabel } from 'react-bootstrap';
import * as UserService from '../../services/UserService';
import analyzenLogo from '../../assets/img/analyzen-logo.png';

class Signup extends Component {
	constructor(props) {
		super(props);
		this.varticalCenterStuff = this.varticalCenterStuff.bind(this);
		this.schema = yup.object({
			name: yup.string().required('Name is required'),
			email: yup.string().email('Email is invalid').required('Email is required'),
			password: yup.string().required('Password is required'),
			company: yup.string(),
			role: yup.string().required('Please choose your role'),
			terms: yup.bool().oneOf([true], 'You must agree to our Terms & Conditions to proceed')
		});

		this.initialValues = { name: '', email: '', password: '', company: '', role: '', terms: false };
		// set initial values
		if(props.location.state) {
			if(props.location.state.user) {
				if(props.location.state.user.email) this.initialValues['email'] =  props.location.state.user.email;
				if(props.location.state.user.name) this.initialValues['name'] =  props.location.state.user.name;
				if(props.location.state.user.picture) this.initialValues['picture'] =  props.location.state.user.picture;
				if(props.location.state.user.facebook_user_id) this.initialValues['facebook_user_id'] =  props.location.state.user.facebook_user_id;
			} else {
				props.history.push('/');
			}
		} else {
			props.history.push('/');
		}
	}

	varticalCenterStuff = () => {
		var loginBoxHeight = document.querySelectorAll('.login-block')[0].clientHeight;
		var mathLogin = (window.innerHeight / 2) - (loginBoxHeight / 2);
		document.querySelectorAll('.login-block')[0].style.marginTop = mathLogin + "px";
	}

	componentDidMount() {
		document.title = 'Signup - listenyzen';
		document.body.classList.add('login-body');
		this.varticalCenterStuff();
		window.addEventListener("resize", this.varticalCenterStuff);
	}

	componentWillUnmount() {
		document.body.classList.remove('login-body');
		window.removeEventListener("resize", this.varticalCenterStuff);
	}

	render() {
		return (
			<div className="Signup">
				<div id="login-wrapper">
					<section className="login-block">
						<div className="container">
							<div className="row">
								<div className="col-sm-4 login-sec">
									<div className="login-heading text-center">
										<h2 className="title text-center">Sign up</h2>
									</div>
									<Formik
										initialValues={this.initialValues}
										validationSchema={this.schema} 
										onSubmit={(values, { setSubmitting }) => {
											UserService.signup(values)
												.then(response => {
													if(response.data.verify_email) {
														window.location.replace(process.env.REACT_APP_CLIENT_URL + '/verify/' + values.email);
													}
												})
												.catch(err => {
													setSubmitting(false);
												});
										}}
									>
										{({
											values,
											touched,
											errors,
											isSubmitting,
											handleChange,
											handleBlur,
											handleSubmit
										}) => (
											<Form onSubmit={handleSubmit} className="login-form">
												<FormControl type="hidden" name="facebook_user_id" value={values.facebook_user_id} onChange={handleChange} onBlur={handleBlur} />
												<FormControl type="hidden" name="picture" value={values.picture} onChange={handleChange} onBlur={handleBlur} />
												<FormGroup>
													{/* <ControlLabel>Name</ControlLabel> */}
													<FormControl type="text" name="name" placeholder="Your name" value={values.name} onChange={handleChange} onBlur={handleBlur} className={
														errors.name && touched.name ? 'error' : ''
													} />
													{errors.name && touched.name && <div className="input-feedback">{errors.name}</div>}
												</FormGroup>
												<FormGroup>
													{/* <ControlLabel>Email</ControlLabel> */}
													<FormControl type="text" name="email" placeholder="Your email address" value={values.email} onChange={handleChange} className={
														errors.email && touched.email ? 'error' : ''
													} />
													{errors.email && touched.email && <div className="input-feedback">{errors.email}</div>}
												</FormGroup>
												<FormGroup>
													{/* <ControlLabel>Email</ControlLabel> */}
													<FormControl type="password" name="password" placeholder="Your password" value={values.password} onChange={handleChange} className={
														errors.password && touched.password ? 'error' : ''
													} />
													{errors.password && touched.password && <div className="input-feedback">{errors.password}</div>}
												</FormGroup>
												<FormGroup>
													{/* <ControlLabel>Company</ControlLabel> */}
													<FormControl type="text" name="company" placeholder="Your company name" value={values.company} onChange={handleChange} className={
														errors.company && touched.company ? 'error' : ''
													} />
													{errors.company && touched.company && <div className="input-feedback">{errors.company}</div>}
												</FormGroup>
												<FormGroup>
													{/* <ControlLabel>You are signing up as a -</ControlLabel><br/> */}
													<ControlLabel className="radio">Project Manager <FormControl type="radio" name="role" value='company' onChange={handleChange} className={
														errors.role && touched.role ? 'error' : ''
													} /><span className="checkround" /></ControlLabel>
													<ControlLabel className="radio">Query Manager <FormControl type="radio" name="role" value='query_manager' onChange={handleChange} className={
														errors.role && touched.role ? 'error' : ''
													} /><span className="checkround" /></ControlLabel>
													{errors.role && touched.role && <div className="input-feedback">{errors.role}</div>}
												</FormGroup>
												<FormGroup>
													<FormControl type="checkbox" name="terms" onChange={handleChange} className={
														errors.terms && touched.terms ? 'terms error' : 'terms'
													} /> &nbsp;&nbsp;By signing up I agree to all <a href="/terms" target="_blank">Terms & Privacy</a> policy
													{errors.terms && touched.terms && <div className="input-feedback">{errors.terms}</div>}
												</FormGroup>
												<div className="form-check text-center">
													<button type="submit" disabled={isSubmitting} className="btn btn-login float-right">Submit</button>
												</div>
											</Form>
										)}
										</Formik>
									<Copyright />
								</div>
								<div className="col-sm-8 banner-sec"></div>
							</div>
						</div>
						<div className="container footer">
							<div className="col-sm-6">
								&copy; {new Date().getFullYear()} <a href="https://www.analyzenbd.com" target="_blank" rel="noopener noreferrer"><img src={analyzenLogo} alt="Analyzen" style={{verticalAlign: 'sub', width: '10%'}} /></a>. All rights reserved.
							</div>
							<div className="col-sm-6 text-right">
								<Link to="/terms">Terms & Privacy</Link>
							</div>
						</div>
					</section>
				</div>
			</div>
		);
	}
};

export default withRouter(Signup);