import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/img/logo.png';

const Logo = (props) => {
	return (
		<div className="navbar-header">
			<div className="navbar-header fixed-brand">
				<Link to="/projects" className="navbar-brand">
					<img src={logo} alt="listenyzen Logo" />
				</Link>
			</div>
		</div>
	);
};

export default Logo;