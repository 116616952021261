import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as yup from 'yup';
import * as UserService from '../../services/UserService';

class ResetPassword extends Component {

	componentDidMount() {
		setTimeout(() => {
			document.getElementById("submitbuttton").click();
		}, 100);
	}

	render() {
		let schema = yup.object({
			password: yup.string().required('New password is required'),
			confirm_password: yup.string().required('You must confirm password')
		});

		let initialValues = { password: '', confirm_password: '' };

		return (
			<Formik
				initialValues={initialValues}
				validationSchema={schema} 
				onSubmit={(values, { setSubmitting, resetForm }) => {
					values.userid = this.props.match.params.userid;
					values.reset_code = this.props.match.params.reset_code;
					UserService.resetPassword(values)
						.then(response => {
							if(response.data.success) {
								setSubmitting(false);
								resetForm();
								setTimeout(() => {
									window.location.replace(process.env.REACT_APP_CLIENT_URL + '/?reset_success=true');
								}, 1000);
							} else {
								setSubmitting(false);
								toast.error("😪 Sorry! There was a problem processing your request. Please try again.", {
									autoClose: 5000,
									position: 'bottom-right',
									className: 'notification-message'
								});
							}
						})
						.catch(err => {
							setSubmitting(false);
						});
				}}
			>
				{({
						values,
						touched,
						errors,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit
					}) => (
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label className="control-label">New Password</label>
								<input name="password" placeholder="Enter your new password" type="password" value={values.password} onChange={handleChange} onBlur={handleBlur} className={
										errors.password && touched.password ? 'form-control error' : 'form-control'
									} />
								{errors.password && touched.password && <div className="input-feedback">{errors.password}</div>}
							</div>
							<div className="form-group">
								<label className="control-label">Confirm New Password</label>
								<input name="confirm_password" placeholder="Confirm your new password" type="password" value={values.confirm_password} onChange={handleChange} onBlur={handleBlur} className={
										errors.confirm_password && touched.confirm_password ? 'form-control error' : 'form-control'
									} />
								{errors.confirm_password && touched.confirm_password && <div className="input-feedback">{errors.confirm_password}</div>}
							</div>
							<button id="submitbuttton" type="submit" disabled={isSubmitting} className="btn btn-success btn-block" style={{fontSize: '16px'}}>Reset Password</button>
						</form>
			)}
			</Formik>
		);
	}
}

export default withRouter(ResetPassword);