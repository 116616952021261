import React, { Component } from 'react';
import userIcon from '../../../assets/img/user-icon.png';
import { Nav, NavDropdown, MenuItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as classService from '../../../services/ClassService';
import { getUserData } from '../../../services/UserService';

class Menu extends Component {
	navTitle = '';
	soundClass = (JSON.parse(localStorage.getItem('siteData')) && JSON.parse(localStorage.getItem('siteData')).hasOwnProperty('sound') && JSON.parse(localStorage.getItem('siteData')).sound === false) ? 'fa fa-volume-down sound' : 'fa fa-volume-up sound';

	state = {
		sound: (JSON.parse(localStorage.getItem('siteData')) && JSON.parse(localStorage.getItem('siteData')).hasOwnProperty('sound') && JSON.parse(localStorage.getItem('siteData')).sound === false) ? false : true
	};

	componentDidMount() {
		if(getUserData().hasOwnProperty('picture') && getUserData().picture !== '') {
			this.navTitle = <img src={getUserData().picture} alt={getUserData().name} title={getUserData().name} />;
		} else {
			this.navTitle = <img src={userIcon} alt={getUserData().name} title={getUserData().name} />;
		}
	}

	changeSound = () => {
		this.setState((prevState) => ({
			...prevState,
			sound: !prevState.sound
		}));
		
		setTimeout(
			function() {
				if(!this.state.sound) {
					classService.removeClass(document.getElementById('sound'), 'fa-volume-up');
					classService.addClass(document.getElementById('sound'), 'fa-volume-down');

					localStorage.setItem('siteData', JSON.stringify({
						sound: false
					}));
				} else {
					classService.removeClass(document.getElementById('sound'), 'fa-volume-down');
					classService.addClass(document.getElementById('sound'), 'fa-volume-up');

					localStorage.setItem('siteData', JSON.stringify({
						sound: true
					}));
				}
			}
			.bind(this),
			100
		);
	}

	render() {
		return (
			<div className="collapse navbar-collapse">
				<Nav className="navbar-nav navbar-right">
					<NavDropdown title={this.navTitle} id="basic-nav-dropdown">
						{/* <MenuItem ><i className="fa fa-address-book"></i> User Profile</MenuItem> */}
						<MenuItem componentClass={Link} href="/projects" to="/projects"><i className="fa fa-window-maximize"></i> Projects</MenuItem>
						{/* <MenuItem componentClass={Link} href="/pricing" to="/pricing"><i className="fa fa-credit-card"></i> Pricing</MenuItem> */}
						<MenuItem componentClass={Link} href="/terms" to="/terms"><i className="fa fa-book"></i> Terms & Privacy</MenuItem>
						<MenuItem divider />
						<MenuItem componentClass={Link} href="/logout" to="/logout"><i className="fa fa-sign-out"></i> Log Out</MenuItem>
					</NavDropdown>
				</Nav>
				<i id="sound" className={this.soundClass} onClick={this.changeSound} title="Turn on/off Notification sound"></i>
			</div>
		);
	}
};

export default Menu;