import * as actions from '../actions/report_actions';
import _ from 'lodash';

const initialState = {
	project_image: '',
	project_title: '',
	project_ai: false,
	loading: false,
	floading: false,
	uloading: false,
	eloading: false,
	qrrloading: false,
	tagloading: false,
	entityloading: false,
	genderloading: false,
	performanceloading: false,
	platform: {
		label: 'Any',
		value: ''
	},
	start_date: new Date(),
	end_date: new Date(),
	counts: {
		_id: "",
		received_count: 0,
		resolved_count: 0,
		resolved_query_count: 0,
		hide_count: 0,
		hide_query_count: 0,
		like_count: 0,
		like_query_count: 0,
		delete_count: 0,
		delete_query_count: 0,
		total_replied_count: 0,
		qm_replied_count: 0,
		total_response_time: 0,
		resolved_without_reply: 0,
		tagged_count: 0
	},
	fcounts: {
		_id: "",
		followup_count: 0
	},
	ucounts: {
		new_user_count: 0
	},
	ecounts: {
		existing_user_count: 0
	},
	dwrr: [],
	performance: [],
	tags: [],
	customer: [],
	intents: [],
	entities: [],
	databank: [],
	skip_databank: 0,
	keyword_databank: '',
	databank_count: 0,
	replies: [],
	dstart_date: new Date(),
	dend_date: new Date()
};

const searchArray = (elementKey, myArray, element) => {
    for (var i=0; i < myArray.length; i++) {
		if(myArray[i][element]) {
			if (myArray[i][element] === elementKey) {
				return i;
			}
		}
	}
	return false;
}

const reducer = (state = initialState, action) => {
	switch(action.type) {
		case actions.REPORT_PROJECT_INFO:
			const projectInfo = _.cloneDeep(state);
			projectInfo.project_image = action.project_image;
			projectInfo.project_title = action.project_title;
			projectInfo.project_ai = action.project_ai;
			return projectInfo
		case actions.GET_REPORTS:
			const reportState = _.cloneDeep(state);
			reportState.start_date = action.start_date;
			reportState.end_date = action.end_date;
			reportState.platform = action.platform;
			if(action.reports._id) {
				reportState.counts = action.reports;
			} else {
				reportState.counts = initialState.counts
			}
			return reportState
		case actions.GET_FOLLOWUP:
			const followupState = _.cloneDeep(state);
			followupState.start_date = action.start_date;
			followupState.end_date = action.end_date;
			followupState.platform = action.platform;
			if(action.reports._id) {
				followupState.fcounts = action.reports;
			} else {
				followupState.fcounts = initialState.fcounts
			}
			return followupState
		case actions.GET_USERS:
			const userState = _.cloneDeep(state);
			userState.start_date = action.start_date;
			userState.end_date = action.end_date;
			userState.platform = action.platform;
			if(action.user_type === 'new') {
				if(action.reports) {
					userState.ucounts = action.reports;
				} else {
					userState.ucounts = initialState.reports;
				}
			} else if(action.user_type === 'existing') {
				if(action.reports) {
					userState.ecounts = action.reports;
				} else {
					userState.ecounts = initialState.reports;
				}
			}
			return userState
		case actions.GET_QRR_REPORTS:
			const reportqrState = _.cloneDeep(state);
			if(action.reports && action.reports.length > 0) {
				reportqrState.dwrr = action.reports;
			} else {
				reportqrState.dwrr = initialState.dwrr
			}
			return reportqrState
		case actions.GET_TAG_REPORTS:
			const reporttagState = _.cloneDeep(state);
			if(action.reports && action.reports.length > 0) {
				reporttagState.tags = action.reports;
			} else {
				reporttagState.tags = initialState.tags
			}
			return reporttagState
		case actions.GET_ENTITY_REPORTS:
			const reportentityState = _.cloneDeep(state);
			if(action.reports && action.reports.length > 0) {
				reportentityState.entities = action.reports;
			} else {
				reportentityState.entities = initialState.entities
			}
			return reportentityState
		case actions.GET_INTENT_REPORTS:
			const reportintentState = _.cloneDeep(state);
			if(action.reports && action.reports.length > 0) {
				reportintentState.intents = action.reports;
			} else {
				reportintentState.intents = initialState.intents
			}
			return reportintentState
		case actions.GET_GENDER_REPORTS:
			const reportgenderState = _.cloneDeep(state);
			if(action.reports && action.reports.length > 0) {
				reportgenderState.customer = action.reports;
			} else {
				reportgenderState.customer = initialState.customer
			}
			return reportgenderState
		case actions.GET_PERFORMANCE_REPORTS:
			const reportperformanceState = _.cloneDeep(state);
			if(action.reports && action.reports.length > 0) {
				reportperformanceState.performance = action.reports;
			} else {
				reportperformanceState.performance = initialState.performance
			}
			return reportperformanceState
		case actions.GET_DATABANK:
			const databankState = _.cloneDeep(state);
			if(action.reports && action.reports.length > 0) {
				databankState.databank = action.reports;
				databankState.databank_count = action.count;
				databankState.start_date = action.start_date;
				databankState.end_date = action.end_date;
				if(action.skip === 0) {
					databankState.skip_databank = 50;
				} else {
					databankState.skip_databank = databankState.skip_databank + action.reports.length;
				}
			} else {
				databankState.databank = initialState.databank;
				databankState.databank_count = action.count;
			}
			return databankState
		case actions.SET_REPORT_PLATFORM:
			const platformState = _.cloneDeep(state);
			platformState.platform = action.platform;
			return platformState
		case actions.SET_REPORT_KEYWORD:
			const keywordState = _.cloneDeep(state);
			keywordState.keyword_databank = action.keyword;
			return keywordState
		case actions.SET_REPORT_START_DATE:
			const startDateState = _.cloneDeep(state);
			startDateState.start_date = action.start_date;
			return startDateState
		case actions.SET_REPORT_END_DATE:
			const endDateState = _.cloneDeep(state);
			endDateState.end_date = action.end_date;
			return endDateState
		case actions.SET_DREPORT_START_DATE:
			const startdDateState = _.cloneDeep(state);
			startdDateState.dstart_date = action.start_date;
			return startdDateState
		case actions.SET_DREPORT_END_DATE:
			const enddDateState = _.cloneDeep(state);
			enddDateState.dend_date = action.end_date;
			return enddDateState
		case actions.REPORT_LOADING:
			const loadingState = _.cloneDeep(state);
			loadingState.loading = !loadingState.loading;
			return loadingState
		case actions.FOLLOWUP_LOADING:
			const loadingFState = _.cloneDeep(state);
			loadingFState.floading = !loadingFState.floading;
			return loadingFState
		case actions.USERS_LOADING:
			const loadingUState = _.cloneDeep(state);
			loadingUState.uloading = !loadingUState.uloading;
			return loadingUState
		case actions.EUSERS_LOADING:
			const loadingEState = _.cloneDeep(state);
			loadingEState.eloading = !loadingEState.eloading;
			return loadingEState
		case actions.QRRREPORT_LOADING:
			const qrrloadingState = _.cloneDeep(state);
			qrrloadingState.qrrloading = !qrrloadingState.qrrloading;
			return qrrloadingState
		case actions.TAGREPORT_LOADING:
			const tagloadingState = _.cloneDeep(state);
			tagloadingState.tagloading = !tagloadingState.tagloading;
			return tagloadingState
		case actions.ENTITYREPORT_LOADING:
			const entityloadingState = _.cloneDeep(state);
			entityloadingState.entityloading = !entityloadingState.entityloading;
			return entityloadingState
		case actions.INTENTREPORT_LOADING:
			const intentloadingState = _.cloneDeep(state);
			intentloadingState.intentloading = !intentloadingState.intentloading;
			return intentloadingState
		case actions.GENDERREPORT_LOADING:
			const genderloadingState = _.cloneDeep(state);
			genderloadingState.genderloading = !genderloadingState.genderloading;
			return genderloadingState
		case actions.PERFORMANCEREPORT_LOADING:
			const performanceloadingState = _.cloneDeep(state);
			performanceloadingState.performanceloading = !performanceloadingState.performanceloading;
			return performanceloadingState
		case actions.CLEAR_REPORTS:
			let clearedState = _.cloneDeep(state);
			clearedState = initialState;
			return clearedState
		case actions.GET_SUGGESTIONS:
			let suggestionState = _.cloneDeep(state);
			suggestionState.replies = action.suggestions;
			return suggestionState
		case actions.DELETE_SUGGESTION:
			let deletedSuggestion = _.cloneDeep(state);
			if(deletedSuggestion.replies.length > 0) {
				let found_id = searchArray(action.id, deletedSuggestion.replies, '_id');
				if(found_id !== false) {
					deletedSuggestion.replies.splice(found_id, 1);
				}
			}
			return deletedSuggestion
		case actions.UPDATE_REPLIES:
			let updatedState = _.cloneDeep(state);
			let found_id = searchArray(action.data._id, updatedState.replies, '_id');
			if(found_id !== false) {
				updatedState.replies[found_id] = action.data;
			} else {
				updatedState.replies = updatedState.replies.concat(action.data);
			}
			return updatedState
		default:
			break;
	}

	return state;
}
export default reducer;