import * as reportService from '../../services/ReportService';
import * as projectService from '../../services/ProjectService';
import { getUserData } from '../../services/UserService';

// function formatDate(date) {
//     var d = new Date(date),
//         month = '' + (d.getMonth() + 1),
//         day = '' + d.getDate(),
//         year = d.getFullYear();

//     if (month.length < 2) month = '0' + month;
//     if (day.length < 2) day = '0' + day;

//     return [year, month, day].join('-');
// }

///////////////////////// Report Actions //////////////////////
// update project info
export const REPORT_PROJECT_INFO = 'REPORT_PROJECT_INFO';
export const projectaInfo = (project_name, project_photo, ai) => {
	return {
		type: REPORT_PROJECT_INFO,
		project_image: project_photo,
		project_title: project_name,
		project_ai: ai
	}
}
export const projectInfo = (project_id) => {
	return dispatch => {
		projectService.getProject(project_id)
		.then(response => {
			dispatch(projectaInfo(response.data.name, response.data.photo, response.data.ai));
			if(response.data.ai && (getUserData().role === 'admin' || getUserData().role === 'company')) {
				var start_date = new Date(new Date().setMonth(new Date().getMonth() - 1));
				var end_date = new Date();
				dispatch(getentityReports(project_id, { value: '', label: 'Any' }, start_date, end_date));
				dispatch(getintentReports(project_id, { value: '', label: 'Any' }, start_date, end_date));
			}
		})
		.catch(err => {});
	}
};

// report loading
export const REPORT_LOADING = 'REPORT_LOADING';
export const setLoading = () => {
	return {
		type: REPORT_LOADING
	}
}

// followup loading
export const FOLLOWUP_LOADING = 'FOLLOWUP_LOADING';
export const setFLoading = () => {
	return {
		type: FOLLOWUP_LOADING
	}
}

// new users loading
export const USERS_LOADING = 'USERS_LOADING';
export const setULoading = () => {
	return {
		type: USERS_LOADING
	}
}

// existing users loading
export const EUSERS_LOADING = 'EUSERS_LOADING';
export const setELoading = () => {
	return {
		type: EUSERS_LOADING
	}
}

// qrr report loading
export const QRRREPORT_LOADING = 'QRRREPORT_LOADING';
export const setqrrLoading = () => {
	return {
		type: QRRREPORT_LOADING
	}
}

// tag report loading
export const TAGREPORT_LOADING = 'TAGREPORT_LOADING';
export const settagLoading = () => {
	return {
		type: TAGREPORT_LOADING
	}
}

// entity report loading
export const ENTITYREPORT_LOADING = 'ENTITYREPORT_LOADING';
export const setentityLoading = () => {
	return {
		type: ENTITYREPORT_LOADING
	}
}

// intent report loading
export const INTENTREPORT_LOADING = 'INTENTREPORT_LOADING';
export const setintentLoading = () => {
	return {
		type: INTENTREPORT_LOADING
	}
}

// gender report loading
export const GENDERREPORT_LOADING = 'GENDERREPORT_LOADING';
export const setgenderLoading = () => {
	return {
		type: GENDERREPORT_LOADING
	}
}

// performance report loading
export const PERFORMANCEREPORT_LOADING = 'PERFORMANCEREPORT_LOADING';
export const setperformanceLoading = () => {
	return {
		type: PERFORMANCEREPORT_LOADING
	}
}

// get reports
export const GET_REPORTS = 'GET_REPORTS';
export const getaReports = (data, start_date, end_date, platform) => {
	return {
		type: GET_REPORTS,
		reports: data,
		start_date: start_date,
		end_date: end_date,
		platform: platform
	}
}
export const getReports = (project_id, platform, start_date, end_date) => {
	return dispatch => {
		dispatch(setLoading());
		var formattedPlatform = '';
		if(platform.hasOwnProperty('value') && platform.hasOwnProperty('label')) {
			formattedPlatform = platform.value;
		} else {
			formattedPlatform = '';
		}
		reportService.getReport(project_id, formattedPlatform, start_date, end_date)
		.then(response => {
			dispatch(setLoading());
			dispatch(getaReports(response.data, start_date, end_date, platform));
		})
		.catch(err => {});
	}
};

// get followup
export const GET_FOLLOWUP = 'GET_FOLLOWUP';
export const getaFollowup = (data, start_date, end_date, platform) => {
	return {
		type: GET_FOLLOWUP,
		reports: data,
		start_date: start_date,
		end_date: end_date,
		platform: platform
	}
}
export const getFollowup = (project_id, platform, start_date, end_date) => {
	return dispatch => {
		dispatch(setFLoading());
		var formattedPlatform = '';
		if(platform.hasOwnProperty('value') && platform.hasOwnProperty('label')) {
			formattedPlatform = platform.value;
		} else {
			formattedPlatform = '';
		}
		reportService.getFollowup(project_id, formattedPlatform, start_date, end_date)
		.then(response => {
			dispatch(setFLoading());
			dispatch(getaFollowup(response.data, start_date, end_date, platform));
		})
		.catch(err => {});
	}
};

// get existing and new user
export const GET_USERS = 'GET_USERS';
export const getaUsers = (data, start_date, end_date, platform, user_type) => {
	return {
		type: GET_USERS,
		reports: data,
		start_date: start_date,
		end_date: end_date,
		platform: platform,
		user_type: user_type
	}
}
export const getUsers = (project_id, platform, start_date, end_date, user_type) => {
	return dispatch => {
		dispatch(setULoading());
		var formattedPlatform = '';
		if(platform.hasOwnProperty('value') && platform.hasOwnProperty('label')) {
			formattedPlatform = platform.value;
		} else {
			formattedPlatform = '';
		}
		reportService.getUsers(project_id, formattedPlatform, start_date, end_date, user_type)
		.then(response => {
			dispatch(setULoading());
			dispatch(getaUsers(response.data, start_date, end_date, platform, user_type));
		})
		.catch(err => {});
	}
};

// get qrr
export const GET_QRR_REPORTS = 'GET_QRR_REPORTS';
export const getaqrrReports = (data) => {
	return {
		type: GET_QRR_REPORTS,
		reports: data
	}
}

export const getqrrReports = (project_id, platform, start_date, end_date) => {
	return dispatch => {
		dispatch(setqrrLoading());
		var formattedPlatform = '';
		if(platform.hasOwnProperty('value') && platform.hasOwnProperty('label')) {
			formattedPlatform = platform.value;
		} else {
			formattedPlatform = '';
		}
		reportService.getResolvedCount(project_id, formattedPlatform, start_date, end_date)
		.then(response => {
			dispatch(setqrrLoading());
			dispatch(getaqrrReports(response.data));
		})
		.catch(err => {});
	}
};

// get tag count
export const GET_TAG_REPORTS = 'GET_TAG_REPORTS';
export const getatagReports = (data) => {
	return {
		type: GET_TAG_REPORTS,
		reports: data
	}
}

export const gettagReports = (project_id, platform, start_date, end_date) => {
	return dispatch => {
		dispatch(settagLoading());
		var formattedPlatform = '';
		if(platform.hasOwnProperty('value') && platform.hasOwnProperty('label')) {
			formattedPlatform = platform.value;
		} else {
			formattedPlatform = '';
		}
		reportService.gettagCount(project_id, formattedPlatform, start_date, end_date)
		.then(response => {
			dispatch(settagLoading());
			dispatch(getatagReports(response.data));
		})
		.catch(err => {});
	}
};

// get entity count
export const GET_ENTITY_REPORTS = 'GET_ENTITY_REPORTS';
export const getaentityReports = (data) => {
	return {
		type: GET_ENTITY_REPORTS,
		reports: data
	}
}

export const getentityReports = (project_id, platform, start_date, end_date) => {
	return dispatch => {
		dispatch(setentityLoading());
		var formattedPlatform = '';
		if(platform.hasOwnProperty('value') && platform.hasOwnProperty('label')) {
			formattedPlatform = platform.value;
		} else {
			formattedPlatform = '';
		}
		reportService.getentityCount(project_id, formattedPlatform, start_date, end_date)
		.then(response => {
			dispatch(setentityLoading());
			dispatch(getaentityReports(response.data));
		})
		.catch(err => {});
	}
};

// get intent count
export const GET_INTENT_REPORTS = 'GET_INTENT_REPORTS';
export const getaintentReports = (data) => {
	return {
		type: GET_INTENT_REPORTS,
		reports: data
	}
}

export const getintentReports = (project_id, platform, start_date, end_date) => {
	return dispatch => {
		dispatch(setintentLoading());
		var formattedPlatform = '';
		if(platform.hasOwnProperty('value') && platform.hasOwnProperty('label')) {
			formattedPlatform = platform.value;
		} else {
			formattedPlatform = '';
		}
		reportService.getintentCount(project_id, formattedPlatform, start_date, end_date)
		.then(response => {
			dispatch(setintentLoading());
			dispatch(getaintentReports(response.data));
		})
		.catch(err => {});
	}
};

// get gender count
export const GET_GENDER_REPORTS = 'GET_GENDER_REPORTS';
export const getagenderReports = (data) => {
	return {
		type: GET_GENDER_REPORTS,
		reports: data
	}
}

export const getgenderReports = (project_id, start_date, end_date) => {
	return dispatch => {
		dispatch(setgenderLoading());
		reportService.getgenderCount(project_id, start_date, end_date)
		.then(response => {
			dispatch(setgenderLoading());
			dispatch(getagenderReports(response.data));
		})
		.catch(err => {});
	}
};

// get performance
export const GET_PERFORMANCE_REPORTS = 'GET_PERFORMANCE_REPORTS';
export const getaPerformance = (data) => {
	return {
		type: GET_PERFORMANCE_REPORTS,
		reports: data
	}
}
export const getPerformance = (project_id, platform, start_date, end_date) => {
	return dispatch => {
		dispatch(setperformanceLoading());
		var formattedPlatform = '';
		if(platform.hasOwnProperty('value') && platform.hasOwnProperty('label')) {
			formattedPlatform = platform.value;
		} else {
			formattedPlatform = '';
		}
		reportService.getPerformance(project_id, formattedPlatform, start_date, end_date)
		.then(response => {
			dispatch(setperformanceLoading());
			dispatch(getaPerformance(response.data));
		})
		.catch(err => {});
	}
};

// get databank
export const GET_DATABANK = 'GET_DATABANK';
export const getaDatabank = (data, count, skip) => {
	return {
		type: GET_DATABANK,
		reports: data,
		count: count,
		skip: skip
	}
}
export const getDatabank = (project_id, platform, skip, keyword, start_date, end_date) => {
	return dispatch => {
		var formattedPlatform = '';
		if(platform.hasOwnProperty('value') && platform.hasOwnProperty('label')) {
			formattedPlatform = platform.value;
		} else {
			formattedPlatform = '';
		}
		reportService.getDatabank(project_id, formattedPlatform, skip, keyword, start_date, end_date)
		.then(response => {
			dispatch(getaDatabank(response.data.messages, response.data.count, skip));
		})
		.catch(err => {});
	}
};

// set platform
export const SET_REPORT_PLATFORM = 'SET_REPORT_PLATFORM';
export const setPlatform = (platform) => {
	return {
		type: SET_REPORT_PLATFORM,
		platform: platform
	}
}

// set keyword
export const SET_REPORT_KEYWORD = 'SET_REPORT_KEYWORD';
export const setKeyword = (keyword) => {
	return {
		type: SET_REPORT_KEYWORD,
		keyword: keyword
	}
}

// set start date
export const SET_REPORT_START_DATE = 'SET_REPORT_START_DATE';
export const setStartDate = (start_date) => {
	return {
		type: SET_REPORT_START_DATE,
		start_date: start_date
	}
}

// set end date
export const SET_REPORT_END_DATE = 'SET_REPORT_END_DATE';
export const setEndDate = (end_date) => {
	return {
		type: SET_REPORT_END_DATE,
		end_date: end_date
	}
}

// set databank start date
export const SET_DREPORT_START_DATE = 'SET_DREPORT_START_DATE';
export const setdStartDate = (start_date) => {
	return {
		type: SET_DREPORT_START_DATE,
		start_date: start_date
	}
}

// set databank end date
export const SET_DREPORT_END_DATE = 'SET_DREPORT_END_DATE';
export const setdEndDate = (end_date) => {
	return {
		type: SET_DREPORT_END_DATE,
		end_date: end_date
	}
}

// clear reports
export const CLEAR_REPORTS = 'CLEAR_REPORTS';
export const clearReports = () => {
	return {
		type: CLEAR_REPORTS
	}
}

// get reply suggestions
export const GET_SUGGESTIONS = 'GET_SUGGESTIONS';
export const getaSuggestions = (data) => {
	return {
		type: GET_SUGGESTIONS,
		suggestions: data
	}
}
export const getSuggestions = (project_id, intent) => {
	return dispatch => {
		reportService.getSuggestions(project_id, intent)
		.then(response => {
			dispatch(getaSuggestions(response.data));
		})
		.catch(err => {});
	}
};

// delete reply suggestion
export const DELETE_SUGGESTION = 'DELETE_SUGGESTION';
export const deleteaSuggestion = (id) => {
	return {
		type: DELETE_SUGGESTION,
		id: id
	}
}
export const deleteSuggestion = (project_id, id) => {
	return dispatch => {
		reportService.deleteSuggestion(project_id, id)
		.then(response => {
			dispatch(deleteaSuggestion(id));
		})
		.catch(err => {});
	}
};

// update replies
export const UPDATE_REPLIES = 'UPDATE_REPLIES';
export const updateReplies = (data) => {
	return {
		type: UPDATE_REPLIES,
		data: data
	}
}