import React from 'react';
import { connect } from 'react-redux';
import PlatformIcon from './PlatformIcon';
import { Link, withRouter } from 'react-router-dom';
import * as actions from '../../../store/actions/inbox_actions';
import ReactImageFallback from "react-image-fallback";
import spinner from '../../../assets/img/login_spinner.gif';
import nophoto from '../../../assets/img/nophoto.png';

const Header = (props) => {
	const platforms = [];
	let messenger_unresolved_count = 0, facebook_unresolved_count = 0, instagram_unresolved_count = 0, viber_unresolved_count = 0;

	if(props.facebook_connected) {
		platforms.push({
			'slug': 'messenger',
			'title': 'Messenger'
		});
		platforms.push({
			'slug': 'facebook',
			'title': 'Facebook Feed'
		});
		if(props.messenger_count_active) {
			messenger_unresolved_count = (props.message_users.filter((obj) => obj.resolved_status === 'unresolved').length);
		} else {
			messenger_unresolved_count = props.muc;
		}
		if(props.facebook_count_active) {
			facebook_unresolved_count = props.comment_users.filter((obj) => obj.resolved_status === 'unresolved').length;
		} else {
			facebook_unresolved_count = props.fuc;
		}
	}

	if(props.instagram_connected) {
		platforms.push({
			'slug': 'instagram',
			'title': 'Instagram'
		});
		if(props.instagram_count_active) {
			instagram_unresolved_count = props.instagram_users.filter((obj) => obj.resolved_status === 'unresolved').length;
		} else {
			instagram_unresolved_count = props.iuc;
		}
	}

	if(props.viber_connected) {
		platforms.push({
			'slug': 'viber',
			'title': 'Viber Public Account'
		});
		if(props.viber_count_active) {
			viber_unresolved_count = props.viber_users.filter((obj) => obj.resolved_status === 'unresolved').length;
		} else {
			viber_unresolved_count = props.vuc;
		}
	}

	const changePlatform = (platform) => {
		props.clearPlatform();
		props.clearCurrentComments();
		props.clearCurrentiComments();
		props.clearCurrentMessages();
		props.clearCurrentvMessages();
		if(platform === 'messenger') {
			if(props.messenger_clicked === false) {
				props.clearMessages();
				props.usersLoading();
				props.getMessageUsers(props.match.params.project_id, 0, process.env.REACT_APP_MSG_USER_PER_PAGE, props.keyword, null, null, true);
			}
		}
		if(platform === 'facebook') {
			if(props.facebook_clicked === false) {
				props.clearComments();
				props.usersLoading();
				props.getFacebookUsers(props.match.params.project_id, 0, process.env.REACT_APP_CMT_USER_PER_PAGE, props.keyword_comment, null, null, null, true);
			}
		}
		if(platform === 'viber') {
			if(props.viber_clicked === false) {
				props.clearvMessages();
				props.usersLoading();
				props.getvMessageUsers(props.match.params.project_id, 0, process.env.REACT_APP_MSG_USER_PER_PAGE, props.keyword_viber, null, null, true);
			}
		}
		if(platform === 'instagram') {
			if(props.instagram_clicked === false) {
				props.cleariComments();
				props.usersLoading();
				props.getInstagramUsers(props.match.params.project_id, 0, process.env.REACT_APP_CMT_USER_PER_PAGE, props.keyword_instagram, null, null, true);
			}
		}
	}


	return (
		<div className="row">
			<div className="app-navigation">
				<div className="col-sm-12">
					<div className="app-navigation-inner">
						<ReactImageFallback src={props.project_image} alt={props.project_title} className="projectLogo" fallbackImage={nophoto} initialImage={spinner} />
						<ul className="list-inline text-center">
							{ platforms.map((platform, index) => (
								<li key={index}>
									{props.match.params.platform !== platform.slug ? <Link onClick={changePlatform.bind(this, platform.slug)} to={'/inbox/' + props.match.params.project_id + '/' + platform.slug}><PlatformIcon platform={platform.slug} title={platform.title} unresolved_count={platform.slug === 'messenger' ? messenger_unresolved_count : platform.slug === 'facebook' ? facebook_unresolved_count : platform.slug === 'viber' ? viber_unresolved_count : platform.slug === 'instagram' ? instagram_unresolved_count : 0} /></Link> : <PlatformIcon status='active' platform={platform.slug} title={platform.title} unresolved_count={platform.slug === 'messenger' ? messenger_unresolved_count : platform.slug === 'facebook' ? facebook_unresolved_count : platform.slug === 'viber' ? viber_unresolved_count : platform.slug === 'instagram' ? instagram_unresolved_count : 0} />}
								</li>
							)) }
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		message_users: state.message_state.userList,
		comment_users: state.comment_state.userList,
		viber_users: state.viber_state.userList,
		instagram_users: state.instagram_state.userList,
		skip: state.message_state.skip_user,
		skip_comment: state.comment_state.skip_user,
		skip_viber: state.viber_state.skip_user,
		skip_instagram: state.instagram_state.skip_user,
		keyword: state.message_state.keyword,
		keyword_comment: state.message_state.keyword_comment,
		keyword_viber: state.viber_state.keyword,
		keyword_instagram: state.instagram_state.keyword,
		project_image: state.inbox_state.project_image,
		project_title: state.inbox_state.project_title,
		facebook_connected: state.inbox_state.facebook_connected,
		instagram_connected: state.inbox_state.instagram_connected,
		viber_connected: state.inbox_state.viber_connected,
		messenger_clicked: state.inbox_state.messenger_clicked,
		facebook_clicked: state.inbox_state.facebook_clicked,
		instagram_clicked: state.inbox_state.instagram_clicked,
		viber_clicked: state.inbox_state.viber_clicked,
		viber_count_active: state.inbox_state.viber_count_active,
		messenger_count_active: state.inbox_state.messenger_count_active,
		facebook_count_active: state.inbox_state.facebook_count_active,
		instagram_count_active: state.inbox_state.instagram_count_active,
		muc: state.inbox_state.messenger_unresolved_count,
		fuc: state.inbox_state.facebook_unresolved_count,
		iuc: state.inbox_state.instagram_unresolved_count,
		vuc: state.inbox_state.viber_unresolved_count
    };
}
const mapActionToProps = dispatch => {
    return {
        clearInbox: () => dispatch(actions.clearInbox()),
        clearPlatform: () => dispatch(actions.clearPlatform()),
        clearCurrentMessages: () => dispatch(actions.clearCurrentMessages()),
		clearCurrentComments: () => dispatch(actions.clearCurrentComments()),
		clearCurrentiComments: () => dispatch(actions.clearCurrentiComments()),
		clearCurrentvMessages: () => dispatch(actions.clearCurrentvMessages()),
		getMessageUsers: (project_id, skip, limit, keyword, start_date, end_date, loading) => dispatch(actions.getMessageUsers(project_id, skip, limit, keyword, start_date, end_date, loading)),
		getFacebookUsers: (project_id, skip, limit, keyword, start_date, end_date, post_id, loading) => dispatch(actions.getFacebookUsers(project_id, skip, limit, keyword, start_date, end_date, post_id, loading)),
		getvMessageUsers: (project_id, skip, limit, keyword, start_date, end_date, loading) => dispatch(actions.getvMessageUsers(project_id, skip, limit, keyword, start_date, end_date, loading)),
		getInstagramUsers: (project_id, skip, limit, keyword, start_date, end_date, loading) => dispatch(actions.getInstagramUsers(project_id, skip, limit, keyword, start_date, end_date, loading)),
		usersLoading: () => dispatch(actions.userLoading()),
		clearComments: () => dispatch(actions.clearComments()),
		clearMessages: () => dispatch(actions.clearMessages()),
		clearvMessages: () => dispatch(actions.clearvMessages()),
		cleariComments: () => dispatch(actions.cleariComments())
    };
};

export default withRouter(connect(mapStateToProps, mapActionToProps)(Header));