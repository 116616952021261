import axios from '../api/private';

export const getUsers = (project_id, skip = 0, limit = process.env.REACT_APP_MSG_USER_PER_PAGE, keyword='', start_date = null, end_date = null) => {
	return axios.get('/vmessages/' + project_id, {
		params: {
		  skip: skip,
		  limit: limit,
			keyword: keyword,
			start_date: start_date,
			end_date: end_date
		}
	});
};

export const getConversation = (project_id, user_id, skip = 0, limit = process.env.REACT_APP_MSG_PER_PAGE) => {
	return axios.get('/vmessages/' + project_id + '/' + user_id, {
		params: {
		  skip: skip,
		  limit: limit
		}
	});
};

export const replyViber = (formData) => {
	return axios.post('/vmessages/reply', formData);
}