import axios from '../api/private';

export const getUsers = (project_id, skip = 0, limit = process.env.REACT_APP_CMT_USER_PER_PAGE, keyword='', start_date = null, end_date = null, postid = null, followup_status = '', fcom_status = '') => {
	return axios.get('/comments/' + project_id, {
		params: {
		  skip: skip,
		  limit: limit,
		  keyword: keyword,
		  start_date: start_date,
		  end_date: end_date,
		  postid: postid,
		  followup: followup_status,
		  fcom: fcom_status
		}
	});
};

export const getConversation = (project_id, comment_id) => {
	return axios.get('/comment/' + project_id + '/' + comment_id);
};

export const replyComment = (formData) => {
	return axios.post('/comment/reply', formData);
}

export const replyCommentAsMessage = (formData) => {
	return axios.post('/comment/replyAsMessage', formData);
}

export const hidesComment = (project_id, comment_id, status, csrf) => {
	return axios.post('/comment/hide/' + project_id + '/' + comment_id, {
		is_hidden: status,
		_csrf: csrf
	});
}

export const deletesComment = (project_id, comment_id, csrf) => {
	return axios.post('/comment/delete/' + project_id + '/' + comment_id, {
		_csrf: csrf
	});
}

export const deletesPageComment = (project_id, comment_id, csrf) => {
	return axios.post('/comment/deletePage/' + project_id + '/' + comment_id, {
		_csrf: csrf
	});
}

export const likesComment = (project_id, comment_id, status, csrf) => {
	return axios.post('/comment/like/' + project_id + '/' + comment_id, {
		like: status,
		_csrf: csrf
	});
}

export const getPosts = (project_id) => {
	return axios.get('/posts?project_id=' + project_id);
}

export const followupComment = (project_id, status, comment_id, csrf) => {
	return axios.post('/comment_followup/' + project_id + '/' + comment_id, {
		status: status,
		comment_id: comment_id,
		_csrf: csrf
	});
};

export const fcomComment = (project_id, status, comment_id, csrf) => {
	return axios.post('/comment_fcom/' + project_id + '/' + comment_id, {
		status: status,
		comment_id: comment_id,
		_csrf: csrf
	});
};