import * as actions from '../actions/inbox_actions';
import _ from 'lodash';

const initialState = {
	userList: [],
	userCount: 0,
	currentMessages: [],
	currentComment: {},
	currentCommentId: '',
	currentPostId: '',
	fbPostShown: false,
	messageSuggestions: [],
	skip_user: 0,
	keyword: '',
	start_date: null,
	end_date: null,
	postid: null,
	selectedComments: []
};

const searchArray = (elementKey, myArray, element) => {
    for (var i=0; i < myArray.length; i++) {
		if(myArray[i][element]) {
			if (myArray[i][element] === elementKey) {
				return i;
			}
		}
	}
	return false;
}

const reducer = (state = initialState, action) => {
	switch(action.type) {
		case actions.CLEAR_COMMENTS:
			const clearedComments = _.cloneDeep(state);
			clearedComments.userList = [];
			clearedComments.userCount = 0;
			clearedComments.currentMessages = [];
			clearedComments.currentComment = {};
			clearedComments.currentPostId = '';
			clearedComments.currentCommentId = '';
			clearedComments.messageSuggestions = [];
			clearedComments.skip_user = 0;
			clearedComments.keyword = '';
			clearedComments.start_date = null;
			clearedComments.end_date = null;
			clearedComments.postid = null;
			clearedComments.selectedComments = [];
			return clearedComments
		case actions.CLEAR_CURRENT_COMMENTS:
			const clearedCurrentComments = _.cloneDeep(state);
			clearedCurrentComments.currentMessages = [];
			clearedCurrentComments.currentComment = {};
			clearedCurrentComments.currentPostId = '';
			clearedCurrentComments.currentCommentId = '';
			clearedCurrentComments.messageSuggestions = [];
			clearedCurrentComments.selectedComments = [];
			return clearedCurrentComments
		case actions.SEARCH_FACEBOOK_USERS:
			const searchedState = _.cloneDeep(state);
			searchedState.userList = action.users.comments;
			if(action.users.hasOwnProperty('count')) {
				searchedState.userCount = action.users.count;
			}
			searchedState.currentMessages = [];
			searchedState.currentComment = {};
			searchedState.messageSuggestions = [];
			searchedState.skip_user = action.users.comments.length;
			searchedState.keyword = action.keyword;
			searchedState.start_date = action.start_date;
			searchedState.end_date = action.end_date;
			searchedState.postid = action.postid;
			return searchedState
		case actions.GET_FACEBOOK_USERS:
			const getFacebookUsers = _.cloneDeep(state);
			if(action.users.hasOwnProperty('count')) {
				getFacebookUsers.userCount = action.users.count;
			}
			getFacebookUsers.skip_user = state.skip_user + action.users.comments.length;
			getFacebookUsers.keyword = action.keyword;
			getFacebookUsers.start_date = action.start_date;
			getFacebookUsers.end_date = action.end_date;
			getFacebookUsers.postid = action.postid;
			getFacebookUsers.userList = getFacebookUsers.userList.concat(action.users.comments);
			return getFacebookUsers
		case actions.UPDATE_USER_LIST_COMMENT:
			const updateCommentUsers = _.cloneDeep(state);
			updateCommentUsers.userList[action.index].customer = action.user;
			return updateCommentUsers
		case actions.GET_COMMENT_CONVERSATION:
			const conversations = _.cloneDeep(state);
			
			if(action.comment.comment_level === 'post') {
				conversations.fbPostShown = true;
			} else {
				conversations.fbPostShown = false;
			}
			conversations.currentMessages = action.conversation.comments;
			conversations.currentPostId = action.conversation.post_id;
			conversations.currentCommentId = action.comment_id;
			conversations.messageSuggestions = action.conversation.suggestions;
			conversations.currentComment = action.comment
			return conversations
		case actions.CHANGE_FB_POST_SHOW:
			return {
				...state,
				fbPostShown: !state.fbPostShown
			}
		case actions.CLEAR_FB_POST:
			return {
				...state,
				fbPostShown: false
			}
		case actions.NEW_COMMENT:
			const newComment = _.cloneDeep(state);
			if(action.comment.comment.sender_is && action.comment.comment.sender_is === 'user') {
				let found_id = searchArray(action.comment.comment._id, newComment.userList, '_id');
				if(found_id === false) {
					if((!newComment.keyword || newComment.keyword === '') && !newComment.start_date && !newComment.end_date && !newComment.postid) {
						newComment.userList = newComment.userList.concat(action.comment.comment);
						newComment.userCount = newComment.userCount + 1;
						newComment.skip_user = newComment.skip_user + 1;
					} else {
						let afound_id = searchArray(action.comment.comment.post_id, newComment.userList, 'post_id');
						if(afound_id === false) {
							// do nothing
						} else {
							if(!newComment.keyword || newComment.keyword === '') {
								newComment.userList = newComment.userList.concat(action.comment.comment);
								newComment.userCount = newComment.userCount + 1;
								newComment.skip_user = newComment.skip_user + 1;
							}
						}
					}
				} else {
					// update comment
					newComment.userList[found_id] = action.comment.comment;
				}
				
				if(newComment.currentMessages.length > 0) {
					found_id = searchArray(action.comment.comment._id, newComment.currentMessages, '_id');
					if(found_id !== false) {
						newComment.currentMessages[found_id] = action.comment.comment;
						newComment.currentComment = action.comment.comment;
					}
				}
			} else {
				if(newComment.currentMessages.length > 0) {
					let found_id = searchArray(action.comment.comment.reply_of, newComment.currentMessages, 'comment_id');
					if(found_id !== false) {
						newComment.currentMessages = newComment.currentMessages.concat(action.comment.comment);
					} else {
						found_id = searchArray(action.comment.comment.reply_of, newComment.currentMessages, 'post_id');
						if(found_id !== false) {
							newComment.currentMessages = newComment.currentMessages.concat(action.comment.comment);
						} else {
							if(action.comment.comment.reply_of === newComment.currentPostId) {
								newComment.currentMessages = newComment.currentMessages.concat(action.comment.comment);
							}
						}
					}
				}
			}
			return newComment
		case actions.UPDATE_COMMENT:
			const updatedComment = _.cloneDeep(state);
			if(updatedComment.currentMessages.length > 0) {
				let ufound_id = searchArray(action.comment.comment.comment_id, updatedComment.currentMessages, 'comment_id');
				if(ufound_id !== false) {
					updatedComment.currentMessages[ufound_id].comment_level = action.comment.comment.comment_level;
					if(action.comment.comment.image && action.comment.comment.image !== '')
					updatedComment.currentMessages[ufound_id].image = action.comment.comment.image;
				}
			}
			return updatedComment
		case actions.PAGE_COMMENT_DELETED:
			const deletedComment = _.cloneDeep(state);
			if(deletedComment.currentMessages.length > 0) {
				let ufound_id = searchArray(action.comment.comment_id, deletedComment.currentMessages, 'comment_id');
				if(ufound_id !== false) {
					deletedComment.currentMessages.splice(ufound_id, 1);
				}
			}
			return deletedComment
		case actions.CHANGE_CURRENT_COMMENT_STATUS:
			const currentComment = _.cloneDeep(state);
			currentComment.currentComment.resolved_status = action.status;
			return currentComment
		case actions.SELECT_COMMENT:
			const selectedComment = _.cloneDeep(state);
			if(selectedComment.selectedComments.includes(action.id)) {
				selectedComment.selectedComments.splice( selectedComment.selectedComments.indexOf(action.id), 1 );
			} else {
				selectedComment.selectedComments = selectedComment.selectedComments.concat(action.id);
			}
			return selectedComment
		case actions.ADD_COMMENT_TAG:
			const tagged = _.cloneDeep(state);
			action.ids.forEach(id => {
				let comment = tagged.currentMessages.find(obj => {
					return obj._id === id
				});
				if(!comment.tags) comment.tags = [];
				action.tags.forEach(tag => {
					let found = comment.tags.find(obj => {
						return obj.title === tag
					});
					if(!found) {
						comment.tags.push({
							title: tag
						});
					}
				});
			});
			tagged.selectedComments = [];
			return tagged
		case actions.REMOVE_COMMENT_TAGS:
			const rtagged = _.cloneDeep(state);
			let comment = rtagged.currentMessages.find(obj => {
				return obj._id === action.id
			});
			let found = comment.tags.find(obj => {
				return obj.title === action.tag
			});
			if(found) {
				let myArray = comment.tags.filter(function( obj ) {
					return obj.title !== action.tag;
				});
				comment.tags = myArray;
			}
			rtagged.selectedComments = [];
			return rtagged
		case actions.UPDATE_COMMENT_FOLLOWUP:
			const followup = _.cloneDeep(state);
			followup.currentComment.followup_status = action.status;
			return followup
		case actions.UPDATE_COMMENT_FCOM:
			const fcom = _.cloneDeep(state);
			fcom.currentComment.fcom_status = action.status;
			return fcom
		case actions.SET_KEYWORD_COMMENT:
			const setKeyword = _.cloneDeep(state);
			setKeyword.keyword = action.keyword;
			return setKeyword
		default:
			break;
	}

	return state;
}
export default reducer;