import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import ReactImageFallback from "react-image-fallback";
import spinner from '../../../assets/img/login_spinner.gif';
import nophoto from '../../../assets/img/nophoto.png';

const User = (props) => { // props are 'name', 'userIcon' & 'online_status'
	const classes = ['fa', 'fa-circle', 'time-meta', props.resolved_status].join(' ');
	const containerClasses = ['row', 'sideBar-body'];
	const containerClassesFollowup = ['row', 'sideBar-body', 'followup-user'];
	if(props.activeClass) containerClasses.push('uactive');

	return (
		<div className={props.followup_status && props.followup_status === 'yes' ? containerClassesFollowup.join(' ') : containerClasses.join(' ')} onClick={props.click}>
			<div className="col-sm-2 sideBar-avatar">
				<div className="avatar-icon">
					<ReactImageFallback src={props.userIcon} alt={props.name} fallbackImage={nophoto} initialImage={spinner} />
				</div>
			</div>
			<div className="col-sm-10 sideBar-main">
				<div className="row">
					<div className="col-sm-8 col-xs-8 sideBar-name">
						<span className="name-meta">{props.name} {props.new_user ? '🤚' : null}</span><br/>
						<span className="message-meta"><Moment fromNow tz="Asia/Dhaka" date={props.time} /> {props.resolved_by !== '' ? <>&nbsp;&nbsp;<i className="fa fa-check"></i> {props.resolved_by}</> : null} {props.followup_by && props.followup_by !== '' ? <><br/><i className="fa fa-history"></i> {props.followup_by}</> : null}</span>
					</div>
					<div className="col-sm-4 col-xs-4 pull-right sideBar-time">
						<i className={classes}></i> {props.platform === 'messenger' && props.replied_by === 'Auto' && props.resolved_status === 'resolved' ? <i className="fa fa-circle time-meta auto"></i> : null}
					</div>
				</div>
			</div>
		</div>
	);
};

export default User;