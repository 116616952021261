import * as actions from '../actions/inbox_actions';
import _ from 'lodash';

const initialState = {
	project_image: '',
	project_title: '',
	facebook_connected: false,
	instagram_connected: false,
	viber_connected: false,
	messenger_unresolved_count: 0,
	facebook_unresolved_count: 0,
	viber_unresolved_count: 0,
	instagram_unresolved_count: 0,
	messenger_clicked: false,
	facebook_clicked: false,
	instagram_clicked: false,
	viber_clicked: false,
	bot_enabled: false,
	messenger_count_active: false,
	facebook_count_active: false,
	instagram_count_active: false,
	viber_count_active: false,
	fixed_replies: [],
	userLoading: false,
	userMoreLoading: false,
	conversationLoading: false,
	conversationMoreLoading: false,
	currentUser: {},
	popularTags: []
};

const reducer = (state = initialState, action) => {
	switch(action.type) {
		case actions.CLEAR_INBOX:
			const clearedInbox = _.cloneDeep(state);
			clearedInbox.userLoading = false;
			clearedInbox.userMoreLoading = false;
			clearedInbox.conversationLoading = false;
			clearedInbox.conversationMoreLoading = false;
			clearedInbox.currentUser = {};
			clearedInbox.popularTags = [];
			return clearedInbox
		case actions.CLEAR_PLATFORM:
			const clearedPlatform = _.cloneDeep(state);
			clearedPlatform.userLoading = false;
			clearedPlatform.userMoreLoading = false;
			clearedPlatform.conversationLoading = false;
			clearedPlatform.conversationMoreLoading = false;
			clearedPlatform.currentUser = {};
			return clearedPlatform
		case actions.CLEAR_POP_TAGS:
			const clearedPTags = _.cloneDeep(state);
			clearedPTags.popularTags = [];
			return clearedPTags
		case actions.CLEAR_PROJECT_INFO:
			const clearedProject = _.cloneDeep(state);
			clearedProject.project_image = '';
			clearedProject.project_title = '';
			clearedProject.facebook_connected = false;
			clearedProject.instagram_connected = false;
			clearedProject.viber_connected = false;
			clearedProject.messenger_unresolved_count = 0;
			clearedProject.facebook_unresolved_count = 0;
			clearedProject.viber_unresolved_count = 0;
			clearedProject.instagram_unresolved_count = 0;
			clearedProject.bot_enabled = false;
			clearedProject.fixed_replies = [];
			return clearedProject;
		case actions.USER_LOADING:
			return {
				...state,
				userLoading: !state.userLoading
			}
		case actions.USER_MORE_LOADING:
			return {
				...state,
				userMoreLoading: !state.userMoreLoading
			}
		case actions.CONVERSATION_LOADING:
			return {
				...state,
				conversationLoading: !state.conversationLoading
			}
		case actions.CONVERSATION_MORE_LOADING:
			return {
				...state,
				conversationMoreLoading: !state.conversationMoreLoading
			}
		case actions.SET_CURRENT_USER:
			const currentUser = _.cloneDeep(state);
			currentUser.currentUser = action.user;
			return currentUser
		case actions.CLEAR_CURRENT_USER:
			const clearedUser = _.cloneDeep(state);
			clearedUser.currentUser = {};
			return clearedUser
		case actions.PROJECT_INFO:
			const projectInfo = _.cloneDeep(state);
			projectInfo.project_image = action.project_image;
			projectInfo.project_title = action.project_title;
			projectInfo.messenger_unresolved_count = action.messenger_unresolved_count;
			projectInfo.facebook_unresolved_count = action.facebook_unresolved_count;
			projectInfo.viber_unresolved_count = action.viber_unresolved_count;
			projectInfo.instagram_unresolved_count = action.instagram_unresolved_count;
			projectInfo.facebook_connected = action.facebook_connected;
			projectInfo.instagram_connected = action.instagram_connected;
			projectInfo.viber_connected = action.viber_connected;
			projectInfo.bot_enabled = action.bot_enabled;
			return projectInfo
		case actions.FIXED_REPLIES:
			const fixedReplies = _.cloneDeep(state);
			fixedReplies.fixed_replies = action.fixed_replies
			return fixedReplies
		case actions.POPULAR_TAGS:
			const popularTags = _.cloneDeep(state);
			popularTags.popularTags = popularTags.popularTags.concat(action.tags);
			return popularTags
		case actions.PLATFORM_CLICKED:
				const platformClicked = _.cloneDeep(state);
				if(action.platform === 'messenger') {
					platformClicked.messenger_clicked = true;
				} else if(action.platform === 'facebook') {
					platformClicked.facebook_clicked = true;
				} else if(action.platform === 'instagram') {
					platformClicked.instagram_clicked = true;
				} else if (action.platform === 'viber') {
					platformClicked.viber_clicked = true;
				}
				return platformClicked
		case actions.CLEAR_PLATFORM_CLICKED:
			const clearedPclicked = _.cloneDeep(state);
			clearedPclicked.messenger_clicked = false;
			clearedPclicked.facebook_clicked = false;
			clearedPclicked.instagram_clicked = false;
			clearedPclicked.viber_clicked = false;
			return clearedPclicked
		case actions.PLATFORM_COUNT:
			const platformCount = _.cloneDeep(state);
			if(action.platform === 'messenger') {
				platformCount.messenger_count_active = true;
			} else if(action.platform === 'facebook') {
				platformCount.facebook_count_active = true;
			} else if(action.platform === 'instagram') {
				platformCount.instagram_count_active = true;
			} else if (action.platform === 'viber') {
				platformCount.viber_count_active = true;
			}
			return platformCount
		case actions.CLEAR_PLATFORM_COUNT:
			const clearedPcount = _.cloneDeep(state);
			clearedPcount.messenger_count_active = false;
			clearedPcount.facebook_count_active = false;
			clearedPcount.instagram_count_active = false;
			clearedPcount.viber_count_active = false;
			return clearedPcount
		case actions.UPDATE_CURRENT_THREAD:
			const currentThread = _.cloneDeep(state);
			currentThread.currentUser.current_thread = action.status
			return currentThread
		default:
			break;
	}

	return state;
}
export default reducer;