import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { BulletList as Loader } from 'react-content-loader';
import nophoto from '../../../assets/img/nophoto.png';
import { getQueryManagers, unassign, assign  } from '../../../services/ProjectService';
import * as classService from '../../../services/ClassService';

class QmList extends Component {
	state = {
		assigned_query_managers: [],
		showLoader: false
	};

	hideModal = () => {
		this.setState({
			...this.state,
			assigned_query_managers: []
		});
		this.props.manage('qm_list_modal', this.props.project_id);
	}

	fetchQM = () => {
		this.setState({
			...this.state,
			showLoader: true
		});

		getQueryManagers(this.props.project_id)
			.then(response => {
				this.setState({
					showLoader: false,
					assigned_query_managers: this.state.assigned_query_managers.concat(response.data)
				});
			})
			.catch(er => {});
	}

	unassign = (user_id) => {
		if(document.getElementById(user_id)) classService.addClass(document.getElementById(user_id), 'gdisabled');
		if(document.getElementById(user_id)) document.getElementById(user_id).innerText = 'Unassigning...';
		unassign(this.props.project_id, user_id)
			.then(response => {
				if(response.data.message) {
					let managers = [...this.state.assigned_query_managers];
					let managerIndex = managers.findIndex(manager => manager._id === user_id);
					managers[managerIndex].status = 'unassigned';
					this.setState({
						...this.state,
						assigned_query_managers: managers
					});
				}
				if(document.getElementById(user_id)) classService.removeClass(document.getElementById(user_id), 'gdisabled');
			})
			.catch(er => {});
	}

	assign = (user_id) => {
		if(document.getElementById(user_id)) classService.addClass(document.getElementById(user_id), 'gdisabled');
		if(document.getElementById(user_id)) document.getElementById(user_id).innerText = 'Assigning...';
		assign(this.props.project_id, user_id)
			.then(response => {
				if(response.data.message) {
					let managers = [...this.state.assigned_query_managers];
					let managerIndex = managers.findIndex(manager => manager._id === user_id);
					managers[managerIndex].status = 'assigned';
					this.setState({
						...this.state,
						assigned_query_managers: managers
					});
				}
				if(document.getElementById(user_id)) classService.removeClass(document.getElementById(user_id), 'gdisabled');
			})
			.catch(er => {});
	}

	render() {
		return (
			<Modal className="qm-list" show={this.props.show} onEnter={this.fetchQM} onHide={this.hideModal}>
				<Modal.Header closeButton>
					<Modal.Title>Query Manager List</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				<div className="row">
					<div className="col-sm-12">
						{ this.state.showLoader ? <Loader width="250" /> : null}
						{ this.state.assigned_query_managers.map((manager, index) => (
							<div key={manager._id} className="qm-list-item">
								<div className="row">
									<div className="col-sm-1">
										<div className="qm-avatar">
											<img src={manager.picture ? manager.picture : nophoto} alt={manager.name} />
										</div>
									</div>
									<div className="col-sm-8 text-left">
										<h2 className="qm-title">
											{manager.name}
										</h2>
									</div>
									<div className="col-sm-3 text-center">
										<div className="qm-assign-btn">
											{ manager.status === 'assigned' ? <span id={manager._id} className="btn btn-primary assigned" onClick={() => this.unassign(manager._id)}>Unassign</span> : <span id={manager._id} className="btn btn-primary unassigned" onClick={() => this.assign(manager._id)}>Assign</span> }
										</div>
									</div>
								</div>
							</div>
						)) }
					</div>
				</div>
				</Modal.Body>
			</Modal>
		);
	}
}

export default QmList;