import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Legend } from 'recharts';
import { connect } from 'react-redux';
import loader from '../../../assets/img/loader.svg';

const COLORS = ['#0088FE', '#FFBB28', '#DA3A36'];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index, value
}) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
			{`${(percent * 100).toFixed(0)}% (${value})`}
		</text>
	);
};

const mergeCustomer = (customers) => {
	var updated_customer = [];
	var null_count = 0;
	if(customers.length > 0) {
		customers.forEach(customer => {
			if(customer._id !== "" && customer._id !== null) {
				updated_customer.push(customer);
			} else {
				null_count += customer.count;
			}
		});
		updated_customer.push({
			_id: "N/A",
			count: null_count
		});
	}
	return updated_customer;
};

const GenderPieSection = (props) => {
	return (
		<div className="tag-cloud-graph">
			<div className="panel panel-default">
				<div className="panel-heading">
					<h3 className="panel-title">Customer Demographics</h3>
				</div>
				<div className="panel-body" style={{height: '400px', textAlign: 'center'}}>
					{props.genderloading ? <img src={loader} alt="loader" className="aligncenter" /> : props.customer && props.customer.length > 0 ? <ResponsiveContainer height="100%">
						<PieChart>
							<Pie  isAnimationActive={false}
								data={mergeCustomer(props.customer)}
								labelLine={false}
								label={renderCustomizedLabel}
								outerRadius={150}
								fill="#8884d8"
								dataKey="count"
								nameKey="_id"
								>
								{
									mergeCustomer(props.customer).map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
								}
							</Pie>
							<Legend verticalAlign="top" height={36}/>
						</PieChart>
					</ResponsiveContainer> : <p className="aligncenter">No data available</p>}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		genderloading: state.report_state.genderloading,
		customer: state.report_state.customer
	};
}

export default connect(mapStateToProps)(GenderPieSection);