import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Wrapper from '../../components/Wrapper/Wrapper';
import TagManagerContent from '../../components/TagManagerContent/TagManagerContent';

class TagManager extends Component {

	componentDidMount() {
		document.title = 'Tag Manager - listenyzen';
		document.body.classList.add('main-body');
	}

	componentWillUnmount() {
		document.body.classList.remove('main-body');
	}

	render() {
		return (
			<div className="TagManager">
				<Header />
				<Wrapper>
					<div className="analytics-dashboard">
						<div className="container-fluid">
							<TagManagerContent />
						</div>
					</div>
				</Wrapper>
			</div>
		);
	}
};

export default withRouter(TagManager);