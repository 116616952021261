import * as actions from '../actions/inbox_actions';
import _ from 'lodash';

const initialState = {
	userList: [],
	userCount: 0,
	messageCount: 0,
	currentMessages: [],
	currentMessage: {},
	messageSuggestions: [],
	skip_user: 0,
	skip_message: 0,
	keyword: '',
	start_date: null,
	end_date: null,
	selectedMessages: []
};

const searchArray = (elementKey, myArray, element) => {
    for (var i=0; i < myArray.length; i++) {
        if (myArray[i][element] === elementKey) {
            return i;
        }
	}
	return false;
}

const reducer = (state = initialState, action) => {
	switch(action.type) {
		case actions.CLEAR_VMESSAGE:
			const clearedState = _.cloneDeep(state);
			clearedState.userList = [];
			clearedState.userCount = 0;
			clearedState.messageCount = 0;
			clearedState.currentMessages = [];
			clearedState.currentMessage = {};
			clearedState.messageSuggestions = [];
			clearedState.skip_user = 0;
			clearedState.skip_message = 0;
			clearedState.keyword = '';
			clearedState.start_date = null;
			clearedState.end_date = null;
			clearedState.selectedMessages = [];
			return clearedState
		case actions.SEARCH_VMESSAGE_USERS:
			const searchedState = _.cloneDeep(state);
			searchedState.userList = action.users.messages;
			if(action.users.hasOwnProperty('count')) {
				searchedState.userCount = action.users.count;
			}
			searchedState.currentMessages = [];
			searchedState.currentMessage = {};
			searchedState.messageSuggestions = [];
			searchedState.skip_user = action.users.messages.length;
			searchedState.keyword = action.keyword;
			searchedState.start_date = action.start_date;
			searchedState.end_date = action.end_date;
			return searchedState
		case actions.CLEAR_CURRENT_VMESSAGES:
			const clearedMessage = _.cloneDeep(state);
			clearedMessage.currentMessages = [];
			clearedMessage.currentMessage = {};
			clearedMessage.messageSuggestions = [];
			clearedMessage.skip_message = 0;
			clearedMessage.selectedMessages = [];
			return clearedMessage
		case actions.GET_VMESSAGE_USERS:
			const getVmessageUsers = _.cloneDeep(state);
			if(action.users.hasOwnProperty('count')) {
				getVmessageUsers.userCount = action.users.count;
			}
			getVmessageUsers.skip_user = state.skip_user + action.users.messages.length;
			getVmessageUsers.keyword = action.keyword;
			getVmessageUsers.start_date = action.start_date;
			getVmessageUsers.end_date = action.end_date;
			getVmessageUsers.userList = getVmessageUsers.userList.concat(action.users.messages);
			return getVmessageUsers
		case actions.GET_VCONVERSATION:
			const conversations = _.cloneDeep(state);
			conversations.currentMessages = action.conversation.messages;
			conversations.currentMessage = action.currentMessage;
			if(action.conversation.hasOwnProperty('count')) {
				conversations.messageCount = action.conversation.count;
			}
			conversations.messageSuggestions = action.conversation.suggestions;
			conversations.skip_message = state.skip_message + action.conversation.messages.length;
			return conversations
		case actions.LOAD_VCONVERSATION:
			const loadedConversations = _.cloneDeep(state);
			loadedConversations.currentMessages = state.currentMessages.concat(action.conversation.messages);
			if(action.conversation.hasOwnProperty('count')) {
				loadedConversations.messageCount = action.conversation.count;
			}
			loadedConversations.skip_message = state.skip_message + action.conversation.messages.length;
			loadedConversations.messageSuggestions = action.conversation.suggestions;
			return loadedConversations
		case actions.NEW_VMESSAGE:
			const newMessage = _.cloneDeep(state);
			if(action.message.message.hasOwnProperty('customer') && action.message.message.customer.hasOwnProperty('id') && action.message.message.customer.id !== '') { // sender is user
				let found_id = searchArray(action.message.message.customer.id, newMessage.userList, 'user_id');
				if(found_id === false) {
					if((!newMessage.keyword || newMessage.keyword === '') && !newMessage.start_date && !newMessage.end_date) {
						newMessage.userList = newMessage.userList.concat(action.message.message);
						newMessage.userCount = newMessage.userCount + 1;
						newMessage.skip_user = newMessage.skip_user + 1;
					}
				} else {
					newMessage.userList[found_id].text_content = action.message.message.text_content;
					newMessage.userList[found_id].attachments = action.message.message.attachments;
					newMessage.userList[found_id].time_stamp = action.message.message.time_stamp;
					if(action.message.message.resolved_status && action.message.message.resolved_status === 'resolved') {
						newMessage.userList[found_id].resolved_status = 'resolved';
					} else {
						newMessage.userList[found_id].resolved_status = 'unresolved';
					}

					if(action.currentUser.hasOwnProperty('id')) {
						if(action.message.message.user_id === action.currentUser.id) {
							action.message.message['sender_is'] = 'user';
							if(action.message.message.text_content === '' && action.message.message.attachments.length === 0) {
								// do nothing
							} else {
								//console.log(action.message.message);
								newMessage.currentMessages = newMessage.currentMessages.concat(action.message.message);
								newMessage.currentMessage = action.message.message;
								newMessage.skip_message = newMessage.skip_message + 1;
								newMessage.messageCount = newMessage.messageCount + 1;
							}
						}
					}
				}
			} else { // sender is page
				let found_id = searchArray(action.message.message.receiver, newMessage.userList, 'user_id');
				if(found_id !== false) {
					newMessage.userList[found_id].resolved_status = 'resolved';
				}
				if(action.currentUser.hasOwnProperty('id')) {
					if(action.message.message.receiver === action.currentUser.id) {
						newMessage.currentMessage.resolved_status = 'resolved';
						newMessage.currentMessages= newMessage.currentMessages.concat(action.message.message);
						newMessage.skip_message = newMessage.skip_message + 1;
						newMessage.messageCount = newMessage.messageCount + 1;
					}
				}
			}
			return newMessage
		case actions.CHANGE_CURRENT_VMESSAGE_STATUS:
			const currentMessage = _.cloneDeep(state);
			currentMessage.currentMessage.resolved_status = action.status;
			return currentMessage
		case actions.SELECT_VMESSAGE:
			const selectedMessage = _.cloneDeep(state);
			if(selectedMessage.selectedMessages.includes(action.id)) {
				selectedMessage.selectedMessages.splice( selectedMessage.selectedMessages.indexOf(action.id), 1 );
			} else {
				selectedMessage.selectedMessages = selectedMessage.selectedMessages.concat(action.id);
			}
			return selectedMessage
		case actions.ADD_VMESSAGE_TAG:
			const tagged = _.cloneDeep(state);
			action.ids.forEach(id => {
				let message = tagged.currentMessages.find(obj => {
					return obj._id === id
				});
				if(!message.tags) message.tags = [];
				action.tags.forEach(tag => {
					let found = message.tags.find(obj => {
						return obj.title === tag
					});
					if(!found) {
						message.tags.push({
							title: tag
						});
					}
				});
			});
			tagged.selectedMessages = [];
			return tagged
		case actions.REMOVE_VMESSAGE_TAGS:
			const rtagged = _.cloneDeep(state);
			let message = rtagged.currentMessages.find(obj => {
				return obj._id === action.id
			});
			let found = message.tags.find(obj => {
				return obj.title === action.tag
			});
			if(found) {
				let myArray = message.tags.filter(function( obj ) {
					return obj.title !== action.tag;
				});
				message.tags = myArray;
			}
			rtagged.selectedMessages = [];
			return rtagged
		default:
			break;
	}

	return state;
}
export default reducer;