import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import 'moment-timezone';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../store/actions/report_actions';

class TableSection extends Component {
	loadData = (project_id, platform, skip, keyword) => {
		this.props.getDatabank(project_id, platform, skip, keyword);
	}

	render() {
		return (
			<><table className="table table-bordered table-hover" style={{width: '90%', margin: '0 auto'}}>
				<thead>
					<tr>
						<th>Sender</th>
						<th className="text-center">Date</th>
						<th className="text-center">Message</th>
						<th className="text-center">Tag</th>
						<th className="text-center">Intent</th>
						<th className="text-center">Entity</th>
					</tr>
				</thead>
				<tbody>
				{this.props.databank.map(data => (
					<tr key={data._id}>
						<td width="10%">{data.customer && data.customer.name ? data.customer.name : 'Name N/A'}</td>
						<td className="text-center"><Moment tz="Asia/Dhaka" date={data.time_stamp} /></td>
						<td width="40%">{data.text_content}</td>
						<td width="15%">{data.tags && data.tags.length > 0 ? Array.prototype.map.call(data.tags, s => s.title).toString() : 'N/A'}</td>
						<td width="10%">{data.intent ? data.intent : 'N/A'}</td>
						<td width="15%">{data.entities && data.entities.length > 0 ? Array.prototype.map.call(data.entities, s => s.title).toString() : 'N/A'}</td>
					</tr>
				))}
				</tbody>
			</table>
				{this.props.databank_count > 0 && this.props.databank_count > this.props.skip ? <div className="next" style={{float: 'right', margin: '10px 5% 0 0'}}><button type="button" className="btn" onClick={this.loadData.bind(this, this.props.match.params.project_id, this.props.platform, this.props.skip, this.props.keyword)}>Load More</button></div> : null}</>
		);
	}
};

const mapStateToProps = state => {
	return {
		databank: state.report_state.databank,
		databank_count: state.report_state.databank_count,
		skip: state.report_state.skip_databank,
		platform: state.report_state.platform,
		keyword: state.report_state.keyword_databank
	};
}

const mapActionToProps = dispatch => {
	return {
		clearReports: () => dispatch(actions.clearReports()),
		getDatabank: (project_id, platform, skip, keyword) => dispatch(actions.getDatabank(project_id, platform, skip, keyword))
	};
};

export default withRouter(connect(mapStateToProps, mapActionToProps)(TableSection));