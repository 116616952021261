import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as yup from 'yup';
import * as UserService from '../../services/UserService';

class ForgetPassword extends Component {

	componentDidMount() {
		setTimeout(() => {
			document.getElementById("submitbuttton").click();
		}, 100);
	}

	render() {
		let schema = yup.object({
			email: yup.string().email('Email is invalid').required('Email is required')
		});

		let initialValues = { email: '' };

		return (
			<Formik
				initialValues={initialValues}
				validationSchema={schema} 
				onSubmit={(values, { setSubmitting, resetForm }) => {
					UserService.forgetPassword(values)
						.then(response => {
							if(response.data.email_sent) {
								setSubmitting(false);
								resetForm();
								toast.success("😀 " + response.data.message, {
									autoClose: false,
									position: 'bottom-right',
									className: 'notification-message'
								});
							} else {
								setSubmitting(false);
								toast.error("😪 Sorry! There was a problem processing your request. Please try again.", {
									autoClose: 5000,
									position: 'bottom-right',
									className: 'notification-message'
								});
							}
						})
						.catch(err => {
							setSubmitting(false);
						});
				}}
			>
				{({
						values,
						touched,
						errors,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit
					}) => (
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label className="control-label">Email Address</label>
								<input name="email" placeholder="Enter your email address" type="text" value={values.email} onChange={handleChange} onBlur={handleBlur} className={
										errors.email && touched.email ? 'form-control error' : 'form-control'
									} />
								{errors.email && touched.email && <div className="input-feedback">{errors.email}</div>}
							</div>
							<button id="submitbuttton" type="submit" disabled={isSubmitting} className="btn btn-success btn-block" style={{fontSize: '16px'}}>Send Password Reset link</button>
						</form>
			)}
			</Formik>
		);
	}
}

export default ForgetPassword;