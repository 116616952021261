import axios from '../api/private';

export const getReport = (project_id, platform, start_date, end_date) => {
	if (platform !== 'messenger' && platform !== 'fb_comment' && platform !== 'viber' && platform !== 'instagram') {
		return axios.get('/analytics/basic_report/', {
			params: {
				project_id: project_id,
				start_date: start_date,
				end_date: end_date
			}
		});
	} else {
		return axios.get('/analytics/basic_report/', {
			params: {
				project_id: project_id,
				type: platform,
				start_date: start_date,
				end_date: end_date
			}
		});
	}
};

export const getFollowup = (project_id, platform, start_date, end_date) => {
	if (platform !== 'messenger' && platform !== 'fb_comment' && platform !== 'viber' && platform !== 'instagram') {
		return axios.get('/analytics/followup_count/', {
			params: {
				project_id: project_id,
				start_date: start_date,
				end_date: end_date
			}
		});
	} else {
		return axios.get('/analytics/followup_count/', {
			params: {
				project_id: project_id,
				type: platform,
				start_date: start_date,
				end_date: end_date
			}
		});
	}
};

export const getUsers = (project_id, platform, start_date, end_date, user_type) => {
	return axios.get('/analytics/userCount/' + user_type, {
		params: {
			project_id: project_id,
			type: platform,
			start_date: start_date,
			end_date: end_date
		}
	});
};

export const getPerformance = (project_id, platform, start_date, end_date) => {
	if (platform !== 'messenger' && platform !== 'fb_comment' && platform !== 'viber' && platform !== 'instagram') {
		return axios.get('/analytics/performance/', {
			params: {
				project_id: project_id,
				start_date: start_date,
				end_date: end_date
			}
		});
	} else {
		return axios.get('/analytics/performance/', {
			params: {
				project_id: project_id,
				platform: platform,
				start_date: start_date,
				end_date: end_date
			}
		});
	}
};

export const getResolvedCount = (project_id, platform, start_date, end_date) => {
	if (platform !== 'messenger' && platform !== 'fb_comment' && platform !== 'viber' && platform !== 'instagram') {
		return axios.get('/analytics/resolvedVsReceivedCountByDate/', {
			params: {
				project_id: project_id,
				start_date: start_date,
				end_date: end_date
			}
		});
	} else {
		return axios.get('/analytics/resolvedVsReceivedCountByDate/', {
			params: {
				project_id: project_id,
				type: platform,
				start_date: start_date,
				end_date: end_date
			}
		});
	}
}

export const gettagCount = (project_id, platform, start_date, end_date) => {
	if (platform !== 'messenger' && platform !== 'fb_comment' && platform !== 'viber' && platform !== 'instagram') {
		return axios.get('/analytics/tagCountByProject/', {
			params: {
				project_id: project_id,
				start_date: start_date,
				end_date: end_date
			}
		});
	} else {
		return axios.get('/analytics/tagCountByProject/', {
			params: {
				project_id: project_id,
				type: platform,
				start_date: start_date,
				end_date: end_date
			}
		});
	}
}

export const getentityCount = (project_id, platform, start_date, end_date) => {
	if (platform !== 'messenger' && platform !== 'fb_comment' && platform !== 'viber' && platform !== 'instagram') {
		return axios.get('/analytics/entityCountByProject/', {
			params: {
				project_id: project_id,
				start_date: start_date,
				end_date: end_date
			}
		});
	} else {
		return axios.get('/analytics/entityCountByProject/', {
			params: {
				project_id: project_id,
				type: platform,
				start_date: start_date,
				end_date: end_date
			}
		});
	}
}

export const getintentCount = (project_id, platform, start_date, end_date) => {
	if (platform !== 'messenger' && platform !== 'fb_comment' && platform !== 'viber' && platform !== 'instagram') {
		return axios.get('/analytics/intentCountByProject/', {
			params: {
				project_id: project_id,
				start_date: start_date,
				end_date: end_date
			}
		});
	} else {
		return axios.get('/analytics/intentCountByProject/', {
			params: {
				project_id: project_id,
				type: platform,
				start_date: start_date,
				end_date: end_date
			}
		});
	}
}

export const getgenderCount = (project_id, start_date, end_date) => {
	return axios.get('/analytics/genderWiseCount/' + project_id, {
		params: {
			start_date: start_date,
			end_date: end_date
		}
	});
}

export const getReportExport = (project_id, platform, start_date, end_date) => {
	if (platform !== 'messenger' && platform !== 'fb_comment' && platform !== 'viber' && platform !== 'instagram') {
		return axios.get('/analytics/export/', {
			params: {
				project_id: project_id,
				start_date: start_date,
				end_date: end_date
			}
		});
	} else {
		return axios.get('/analytics/export/', {
			params: {
				project_id: project_id,
				platform: platform,
				start_date: start_date,
				end_date: end_date
			}
		});
	}
}

export const getDatabank = (project_id, platform, skip, keyword, start_date, end_date) => {
	return axios.get('/analytics/databank/' + project_id, {
		params: {
			platform: platform,
			skip: skip,
			keyword: keyword,
			start_date: start_date,
			end_date: end_date
		}
	});
}

export const getSuggestions = (project_id, intent) => {
	var params = {
		project_id: project_id
	};
	if(intent && intent !== '') {
		params.intent = intent;
	}
	return axios.get('/suggestions', {
		params: params
	});
}

export const deleteSuggestion = (project_id, id) => {
	return axios.post('/suggestion', {
		project_id: project_id,
		id: id
	});
}

export const updateSuggestion = (formData) => {
	return axios.post('/suggestions/add', formData);
}

export const clearDataReport = (project_id) => {
	return axios.get('/analytics/clearData/', {
		params: {
			project_id: project_id
		}
	});
}