import React, { Component } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../store/actions/report_actions';
import "react-datepicker/dist/react-datepicker.css";
import { getReportExport } from '../../services/ReportService';
import { getUserData } from '../../services/UserService';

const options_platform = [
	{ value: '', label: 'Any' },
	{ value: 'messenger', label: 'Messenger' },
	{ value: 'fb_comment', label: 'Facebook Comment' },
	{ value: 'viber', label: 'Viber' },
	{ value: 'instagram', label: 'Instagram' }
];

class FilterBar extends Component {

	handlePlatformChange = (selectedPlatform) => {
		this.props.setPlatform(selectedPlatform);
	}

	handleStartDate = (date) => {
		this.props.setStartDate(date);
	}

	handleEndDate = (date) => {
		this.props.setEndDate(date);
	}

	changeData = (project_id, platform, start_date, end_date) => {
		if(getUserData().role === 'admin' || getUserData().role === 'company') {
			this.props.getReports(project_id, platform, start_date, end_date);
			this.props.getFollowup(project_id, platform, start_date, end_date);
			this.props.getUsers(project_id, platform, start_date, end_date, 'new');
			this.props.getUsers(project_id, platform, start_date, end_date, 'existing');
			this.props.getqrrReports(project_id, platform, start_date, end_date);
			this.props.gettagReports(project_id, platform, start_date, end_date);
			this.props.getentityReports(project_id, platform, start_date, end_date);
			this.props.getintentReports(project_id, platform, start_date, end_date);
			this.props.getgenderReports(project_id, start_date, end_date);
		}
		this.props.getperformanceReports(project_id, platform, start_date, end_date);
	}

	formatDate = (date) => {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
	
		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;
	
		return [year, month, day].join('-');
	}

	getExport = (project_id, platform, start_date, end_date) => {
		if(document.getElementById('export')) document.getElementById('export').style.pointerEvents = 'none';
		var formattedPlatform = '';
		if(platform.hasOwnProperty('value') && platform.hasOwnProperty('label')) {
			formattedPlatform = platform.value;
		} else {
			formattedPlatform = '';
		}
		getReportExport(project_id, formattedPlatform, start_date, end_date)
		.then(response => {
			if(response.data.message) {
				if(document.getElementById('export')) document.getElementById('export').style.pointerEvents = 'auto';
				window.location = response.data.message;
			}
			// console.log(response.data);
		})
		.catch(err => {});
		// alert("We are currently working on this feature. Please check again later.");
	};

	render() {
		return (
			<div className="analytics-control-bar">
				<div className="control-bar-inner">
					<div className="left-side-control">
						<div className="row">
							<div className="col-sm-3">
								<div>
									<img src={this.props.project_image} alt={this.props.project_title} title={this.props.project_title} className="projectLogo" />
								</div>
							</div>
							<div className="col-sm-3">
								<div style={{marginTop: '10px'}}>
									<Select id="platform" value={this.props.platform} onChange={this.handlePlatformChange} options={options_platform} placeholder="Select platform" />
								</div>
							</div>
							<div className="col-sm-3 text-center">
								<div className="project-duration" style={{marginTop: '10px'}}>
									<div className="form-group">
										<div className="input-group">
											<div className="input-group-addon">
												<i className="fa fa-calendar"></i>
											</div>
											{/* <input type="text" className="form-control" id="projDuration" /> */}
											<DatePicker
											showTimeSelect
											selected={this.props.start_date}
											onChange={this.handleStartDate}
											className="form-control"
											dateFormat="YYYY-MM-dd h:mm aa"
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="col-sm-3 text-center">
								<div className="project-duration" style={{marginTop: '10px'}}>
									<div className="form-group">
										<div className="input-group">
											<div className="input-group-addon">
												<i className="fa fa-calendar"></i>
											</div>
											<DatePicker
											showTimeSelect
											selected={this.props.end_date}
											onChange={this.handleEndDate}
											className="form-control"
											dateFormat="YYYY-MM-dd h:mm aa"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="right-side-control">
						<div className="run-query text-right">
							<button id="go" type="button" className="btn" onClick={this.changeData.bind(this, this.props.match.params.project_id, this.props.platform, this.props.start_date, this.props.end_date)} style={{marginRight: '10px'}}>Go</button>
							<button className="btn" onClick={this.getExport.bind(this, this.props.match.params.project_id, this.props.platform, this.props.start_date, this.props.end_date)}>Export</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		start_date: state.report_state.start_date,
		end_date: state.report_state.end_date,
		platform: state.report_state.platform,
		project_image: state.report_state.project_image,
		project_title: state.report_state.project_title
	};
}

const mapActionToProps = dispatch => {
	return {
		setPlatform: (platform) => dispatch(actions.setPlatform(platform)),
		setStartDate: (start_date) => dispatch(actions.setStartDate(start_date)),
		setEndDate: (end_date) => dispatch(actions.setEndDate(end_date)),
		getgenderReports: (project_id, start_date, end_date) => dispatch(actions.getgenderReports(project_id, start_date, end_date)),
		getReports: (project_id, platform, start_date, end_date) => dispatch(actions.getReports(project_id, platform, start_date, end_date)),
		getFollowup: (project_id, platform, start_date, end_date) => dispatch(actions.getFollowup(project_id, platform, start_date, end_date)),
		getUsers: (project_id, platform, start_date, end_date, user_type) => dispatch(actions.getUsers(project_id, platform, start_date, end_date, user_type)),
		getqrrReports: (project_id, platform, start_date, end_date) => dispatch(actions.getqrrReports(project_id, platform, start_date, end_date)),
		gettagReports: (project_id, platform, start_date, end_date) => dispatch(actions.gettagReports(project_id, platform, start_date, end_date)),
		getentityReports: (project_id, platform, start_date, end_date) => dispatch(actions.getentityReports(project_id, platform, start_date, end_date)),
		getintentReports: (project_id, platform, start_date, end_date) => dispatch(actions.getintentReports(project_id, platform, start_date, end_date)),
		getperformanceReports: (project_id, platform, start_date, end_date) => dispatch(actions.getPerformance(project_id, platform, start_date, end_date))
	};
};

export default withRouter(connect(mapStateToProps, mapActionToProps)(FilterBar));