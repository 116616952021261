import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import loader from '../../../../assets/img/loader.svg';
import 'moment-timezone';
import FacebookPost from './FacebookPost';
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';
import { toast } from 'react-toastify';
import io from "socket.io-client";
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import avatar from '../../../../assets/img/avatars/avatar1.png';
import video from '../../../../assets/img/video.jpg';
// import logo from '../../../../assets/img/logo.png';
import instruction from '../../../../assets/img/instruction.png';
import { replyComment, replyCommentAsMessage, hidesComment, deletesComment, deletesPageComment, likesComment } from '../../../../services/CommentService';
import * as actions from '../../../../store/actions/inbox_actions';
import { deleteFixedReply } from '../../../../services/ProjectService';
import { getUserData } from '../../../../services/UserService';
import ReactImageFallback from "react-image-fallback";
import spinner from '../../../../assets/img/login_spinner.gif';
import nophoto from '../../../../assets/img/nophoto.png';

class Conversation extends Component {
	constructor(props) {
		super(props);
		this.adjustScreen = this.adjustScreen.bind(this);
		this.state = {
			imagePopup: '',
			isOpen: false,
			fixedReplyShown: false,
			fileDisabled: false,
			replyTextDisabled: false,
			replyValue: null,
			replyImage: '',
			submitting: false,
			canPublish: true,
			emojiShown: false,
			messagesendAllowed: true,
			messagesend: false,
			fixed_replies: []
		};
		this.socket = io(process.env.REACT_APP_SERVER_URL, {
			transports: ['websocket']
		});
	}

	offset = (el) => {
		var rect = el.getBoundingClientRect(),
			scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
			scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		return {
			top: rect.top + scrollTop,
			left: rect.left + scrollLeft
		}
	}

	adjustScreen = () => {
		if (document.querySelectorAll('.facebook-post-content')[0]) document.querySelectorAll('.facebook-post-content')[0].style.height = (window.innerHeight - 90 - this.offset(document.getElementsByClassName("facebook-post-content")[0]).top) + "px";
	}

	verticalCenterInstruction = () => {
		if(document.querySelectorAll('.instruction-comment')[0]) {
			var windowHeight = document.querySelectorAll('.facebook-post-content')[0].clientHeight;
			var instructionBoxHeight = document.querySelectorAll('.instruction-comment')[0].offsetHeight;
			var mathLogin = (windowHeight / 2) - (instructionBoxHeight / 2);
			document.querySelectorAll('.instruction-comment')[0].style.marginTop = mathLogin + "px";
		}
	}

	sendTyping = () => {
		if(this.state.canPublish) {
			this.socket.emit('is_typing', {
				status: true,
				type: 'comment',
				id: this.props.currentComment._id,
				userid: getUserData().userId,
				username: getUserData().username
			});
			this.setState({
				canPublish: false
			});
			setTimeout(function() {
				this.setState({
					canPublish: true
				});
			}.bind(this), 2000);
		}
	}

	removeTyping = () => {
		this.setState({
			canPublish: true
		});
		this.socket.emit('is_typing', {
			status: false,
			type: 'comment',
			id: this.props.currentComment._id,
			userid: getUserData().userId,
			username: getUserData().username
		});
	}

	searchFixedReplies = (event) => {
		const self = this;
		self.setState({
		   fixed_replies: this.props.fixed_replies.filter(r => r.toLowerCase().includes(event.target.value.toLowerCase()))
		});
	}

	componentDidMount() {
		this.verticalCenterInstruction();
		this.adjustScreen();
		window.addEventListener("resize", this.adjustScreen);
		window.addEventListener("resize", this.verticalCenterInstruction);
		
		this.socket.on("is_typing", data => {
			if(data.status) { // typing
				if(data.type === 'comment' && data.userid !== getUserData().userId && this.props.currentComment._id === data.id) {
					if(document.getElementById('reply-conversation-comment')) document.getElementById('reply-conversation-comment').value = data.username + ' is typing...';
					this.setState({
						replyTextDisabled: true
					});
				}
			} else { // typing close
				if(data.type === 'comment' && data.userid !== getUserData().userId && this.props.currentComment._id === data.id) {
					if(document.getElementById('reply-conversation-comment')) document.getElementById('reply-conversation-comment').value = '';
					this.setState({
						replyTextDisabled: false
					});
				}
			}
		});
	}

	componentDidUpdate() {
		this.verticalCenterInstruction();
		this.adjustScreen();
	}

	componentWillUnmount() {
		window.removeEventListener("resize",  this.adjustScreen);
		window.removeEventListener("resize",  this.verticalCenterInstruction);
		this.socket.close();
	}

	openImagePopup = (image_url) => {
		this.setState({
			isOpen: true,
			imagePopup: image_url 
		});
	}

	openFixedReplies = () => {
		this.setState({
			fixed_replies: this.props.fixed_replies,

		});
		document.getElementById("fixed_search").value = '';

		if(this.state.replyImage !== 'has') {
			this.setState({
				fixedReplyShown: !this.state.fixedReplyShown
			});
		}
	}

	fillUpReplyWithReplies = (reply) => {
		if(document.getElementById('reply-conversation-comment')) document.getElementById('reply-conversation-comment').value = document.getElementById('reply-conversation-comment').value + ' ' + reply;
		this.setState({
			fixedReplyShown: !this.state.fixedReplyShown,
			fileDisabled: true,
			replyValue: reply
		});
		if(document.getElementById('reply-conversation-comment')) document.getElementById('reply-conversation-comment').focus();
	}

	getReplyInput = (event) => {
		this.sendTyping();
		if(event.target.value) {
			this.setState({
				fileDisabled: true,
				replyValue: event.target.value
			});
		} else {
			this.setState({
				fileDisabled: false,
				replyValue: ''
			});
		}
	}

	clearImage = () => {
		if(document.getElementById("upload_comment_image")) document.getElementById("upload_comment_image").value = "";
		if(document.getElementById("file-uploaded-comment")) document.getElementById("file-uploaded-comment").src = '';
		this.setState({
			replyTextDisabled: false,
			replyImage: '',
			messagesendAllowed: true
		});
	}

	submitReply = () => {
			this.setState({
				submitting: true
			});

			let formData = new FormData();
			formData.append('project_id', this.props.match.params.project_id);
			formData.append('_csrf', this.props.csrf);
			if(this.state.replyImage === 'has') {
				if(document.getElementById("upload_comment_image")) formData.append('commentImage', document.getElementById("upload_comment_image").files[0]);
			} else {
				if(document.getElementById("reply-conversation-comment")) formData.append('message', document.getElementById("reply-conversation-comment").value);
			}

			if(this.props.currentComment.comment_level === 'post') {
				// we are replying to post
				formData.append('id', this.props.currentComment.post_id);
				formData.append('reply_of', this.props.currentComment.post_id);
				formData.append('reply_to', 'post');
			} else {
				// we are replying to comment
				formData.append('reply_of', this.props.currentComment.comment_id);
				formData.append('reply_to', 'comment');
				if(this.props.currentComment.comment_level === 'first') {
					formData.append('id', this.props.currentComment.comment_id);
				} else {
					formData.append('id', this.props.currentComment.parent_id);
				}
			}

			replyComment(formData)
				.then(response => {
					if(response.data) {
						this.setState({
							fileDisabled: false,
							replyTextDisabled: false,
							replyValue: '',
							replyImage: '',
							submitting: false,
							messagesendAllowed: true
						});
						if(document.getElementById('reply-conversation-comment')) document.getElementById('reply-conversation-comment').value = "";
						if(document.getElementById("upload_comment_image")) document.getElementById("upload_comment_image").value = "";
						if(document.getElementById("file-uploaded-comment")) document.getElementById("file-uploaded-comment").src = '';
						localStorage.removeItem(this.props.match.params.project_id + '_fb_comment_' + getUserData().userId + '_' + this.props.currentComment._id);
					}
				})
				.catch(err => {
					this.setState({
						submitting: false
					});
				});
	};

	submitReplyMesssage = () => {
		this.setState({
			submitting: true
		});

		let formData = new FormData();
		formData.append('project_id', this.props.match.params.project_id);
		formData.append('_csrf', this.props.csrf);
		if(document.getElementById("reply-conversation-comment")) formData.append('message', document.getElementById("reply-conversation-comment").value);

		if(this.props.currentComment.sender) {
			formData.append('receiver', this.props.currentComment.sender);
			formData.append('entity_id', this.props.currentComment._id);
			if(this.props.currentComment.comment_level === 'post') {
				// we are replying to post
				formData.append('entity_type', 'post');
				formData.append('id', this.props.currentComment.post_id);
			} else {
				// we are replying to comment
				formData.append('entity_type', 'comment');
				formData.append('id', this.props.currentComment.comment_id);
			}
			replyCommentAsMessage(formData)
			.then(response => {
				if(response.data) {
					this.setState({
						fileDisabled: false,
						replyTextDisabled: false,
						replyValue: '',
						replyImage: '',
						submitting: false,
						messagesendAllowed: true,
						messagesend: false
					});
					if(document.getElementById('reply-conversation-comment')) document.getElementById('reply-conversation-comment').value = "";
					if(document.getElementById("upload_comment_image")) document.getElementById("upload_comment_image").value = "";
					if(document.getElementById("file-uploaded-comment")) document.getElementById("file-uploaded-comment").src = '';
					localStorage.removeItem(this.props.match.params.project_id + '_fb_comment_' + getUserData() + '_' + this.props.currentComment._id);
					toast.success("😀 Private message was sent successfully!", {
						autoClose: 5000,
						position: 'bottom-right',
						className: 'notification-message'
					});
				}
			})
			.catch(err => {
				this.setState({
					submitting: false
				});
			});
		} else {
			alert("Sender id is missing");
		}
	}
	
	hideComment = (comment_id, status, event) => {
		event.target.style.pointerEvents = 'none';
		hidesComment(this.props.match.params.project_id, comment_id, status, this.props.csrf)
				.then(response => {
					event.target.style.pointerEvents = 'auto';
					if(response.data) {
						if(status) {
							toast.success("😀 Comment was hidden successfully!", {
								autoClose: 5000,
								position: 'bottom-right',
								className: 'notification-message'
							});
						} else {
							toast.success("😀 Comment was marked as shown successfully!", {
								autoClose: 5000,
								position: 'bottom-right',
								className: 'notification-message'
							});
						}
					}
				})
				.catch(err => {
					//event.target.style.pointerEvents = 'auto';
				});
	}

	deleteComment = (comment_id, event) => {
		event.target.style.pointerEvents = 'none';
		deletesComment(this.props.match.params.project_id, comment_id, this.props.csrf)
				.then(response => {
					if(response.data) {
						toast.success("😀 Comment was deleted successfully!", {
							autoClose: 5000,
							position: 'bottom-right',
							className: 'notification-message'
						});
					}
				})
				.catch(err => {
					event.target.style.pointerEvents = 'auto';
				});
	}

	deletePageComment = (comment_id, event) => {
		event.target.style.pointerEvents = 'none';
		deletesPageComment(this.props.match.params.project_id, comment_id, this.props.csrf)
				.then(response => {
					if(response.data) {
						toast.success("😀 Comment was deleted successfully!", {
							autoClose: 5000,
							position: 'bottom-right',
							className: 'notification-message'
						});
					}
				})
				.catch(err => {
					event.target.style.pointerEvents = 'auto';
				});
	}

	likeComment = (comment_id, status, event) => {
		likesComment(this.props.match.params.project_id, comment_id, status, this.props.csrf)
				.then(response => {
					if(response.data) {
						if(status) {
							toast.success("😀 Comment was liked successfully!", {
								autoClose: 5000,
								position: 'bottom-right',
								className: 'notification-message'
							});
						} else {
							toast.success("😀 Comment was unliked successfully!", {
								autoClose: 5000,
								position: 'bottom-right',
								className: 'notification-message'
							});
						}
					}
				})
				.catch(err => {});
	}

	removeTag = (comment_id, tag) => {
		this.props.removeTags(comment_id, tag, this.props.csrf);
	}

	addEmoji = (e) => {
		if (e.unified.length <= 5){
			let emojiPic = String.fromCodePoint(`0x${e.unified}`);
			if(document.getElementById('reply-conversation-comment')) {
				document.getElementById('reply-conversation-comment').value = document.getElementById('reply-conversation-comment').value + emojiPic;
				document.getElementById('reply-conversation-comment').focus();
				this.shEmoji();

				this.setState({
					fileDisabled: true,
					replyValue: document.getElementById('reply-conversation-comment').value
				});
			}
    } else {
      let sym = e.unified.split('-');
      let codesArray = [];
      sym.forEach(el => codesArray.push('0x' + el));
      let emojiPic = String.fromCodePoint(...codesArray);
      if(document.getElementById('reply-conversation-comment')) {
				document.getElementById('reply-conversation-comment').value = document.getElementById('reply-conversation-comment').value + emojiPic;
				document.getElementById('reply-conversation-comment').focus();
				this.shEmoji();

				this.setState({
					fileDisabled: true,
					replyValue: document.getElementById('reply-conversation-comment').value
				});
			}
    }
	}

	shEmoji = () => {
		this.setState((prevState) => ({ ...prevState, emojiShown: !prevState.emojiShown }));
	}

	deleteReply = (project_id, reply) => {
		deleteFixedReply(project_id, reply)
		.then(response => {
			if(response.data) {
				toast.success("😀 Fixed reply was deleted successfully!", {
					autoClose: 2000,
					position: 'bottom-right',
					className: 'notification-message'
				});
				this.props.fixedReplies(this.props.match.params.project_id);
			}
		})
		.catch(err => {})
	}

	setAsMessage = () => {
		this.setState({
			messagesend: !this.state.messagesend
		});
	}

	confirm = () => {
		var r = window.confirm("You are sending reply as private message! Are you sure?");
		if (r === true) {
			this.submitReplyMesssage();
		} else {
			alert("You cancelled sending reply as private message!");
		}
	}

	saveDraft = () => {
		localStorage.setItem(this.props.match.params.project_id + '_fb_comment_' + getUserData() + '_' + this.props.currentComment._id, document.getElementById('reply-conversation-comment') ? document.getElementById('reply-conversation-comment').value: null);
		toast.success("😀 Draft saved successfully!", {
			autoClose: 2000,
			position: 'bottom-right',
			className: 'notification-message'
		});
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.conversationLoading) {
			this.setState({
				replyValue: null
			});
		}
	}

	render() {
		const style1 = {
			paddingRight: '0px'
		};
		const style2 = {
			paddingLeft: '0px',
			paddingRight: '20px'
		};
		let quick_chat_show_status;
		if(this.state.fixedReplyShown && this.props.fixed_replies.length > 0) quick_chat_show_status= ['quick-chat-item'];
		else quick_chat_show_status= ['quick-chat-item hide'];

		let current_comment_class = 'facebook-comment currentComment';

		let envelope_class = '';
		if(this.state.messagesend) {
			envelope_class = 'fa fa-envelope active';
		} else {
			envelope_class = 'fa fa-envelope';
		}

		return (
			<>
				<div className="facebook-post-content">
				{this.props.currentUser.hasOwnProperty('name') ? 
				this.props.conversationLoading ? <div style={{width: '100%', paddingTop: '50px', textAlign: 'center'}}><img src={loader} alt="loading..." /></div> : 
					<Scrollbars style={{ height: '100%' }}>
						<div className="facebook-post-content-inner" id="conversation_comment">
							{ this.props.fbPostShown ? <FacebookPost post_id={this.props.post_id} /> : null }
							{ this.props.conversations.length > 0 ? <div className="facebook-post-comment">
								<div className="facebook-card-content">
									<div className="facebook-card-comments">
										<div className="facebook-card-users-comments">
											<div className="row">
												{this.props.conversations.sort(function(a,b){
													return new Date(a.time_stamp) - new Date(b.time_stamp);
												}).map((comment, index) => (comment.comment_level === 'first') ? (comment.sender_is === 'user') ? 
													<div key={comment._id} className="main-comment">
														<div className="col-sm-1" style={style1}>
															<a className="facebook-card-user-image" href="#/">
																<ReactImageFallback className="facebook-card-user-image" src={comment.customer.profile_pic} alt={comment.customer.name} fallbackImage={nophoto} initialImage={spinner} />
															</a>
														</div>
														<div className="col-sm-11" style={style2}>
															{this.props.currentComment._id === comment._id ? <div className={current_comment_class}>
																<div className="comment-tools" id={comment._id}>
																	{ comment.deleted === 'false' ? 
																	comment.hidden === 'true' ? <><span className="tool comment-hide" title="Unhide Comment" onClick={(event) => this.hideComment(comment.comment_id, false, event)}>
																		<i className="fa fa-eye"></i>
																	</span> <span className="tool comment-delete" title="Delete Comment" onClick={(event) => this.deleteComment(comment.comment_id, event)}>
																		<i className="fa fa-trash-o"></i>
																	</span></> : <><span className="tool comment-hide" title="Hide Comment" onClick={(event) => this.hideComment(comment.comment_id, true, event)}>
																		<i className="fa fa-eye"></i>
																	</span> <span className="tool comment-delete" title="Delete Comment" onClick={(event) => this.deleteComment(comment.comment_id, event)}>
																		<i className="fa fa-trash-o"></i>
																	</span></> : null }
																	<span className="tool comment-tag" title="Add Tag" onClick={this.props.selectComment.bind(this, comment._id)}>
																		<i className="fa fa-tags"></i>
																	</span>
																</div>
																{ comment.hidden === 'true' && comment.deleted === 'true' ? <div id={comment._id} className="hiddenComment deleted">
																	<a className="facebook-card-content-user" href="#/">{comment.customer.name}</a>
																	{comment.text_content}
																	{((!comment.text_content || comment.text_content === '') && (!comment.image || comment.image === '') && (!comment.video || comment.video === '')) ? 'Could not fetch comment. Please use the direct comment link to see the comment content at Facebook' : null }
																</div> : comment.hidden === 'true' ? <div id={comment._id} className="hiddenComment">
																	<a className="facebook-card-content-user" href="#/">{comment.customer.name}</a>
																	{comment.text_content}
																	{((!comment.text_content || comment.text_content === '') && (!comment.image || comment.image === '') && (!comment.video || comment.video === '')) ? 'Could not fetch comment. Please use the direct comment link to see the comment content at Facebook' : null }
																</div> : comment.deleted === 'true' ? <div id={comment._id} className="deleted">
																	<a className="facebook-card-content-user" href="#/">{comment.customer.name}</a>
																	{comment.text_content}
																	{((!comment.text_content || comment.text_content === '') && (!comment.image || comment.image === '') && (!comment.video || comment.video === '')) ? 'Could not fetch comment. Please use the direct comment link to see the comment content at Facebook' : null }
																</div> : <div id={comment._id}>
																	<a className="facebook-card-content-user" href="#/">{comment.customer.name}</a>
																	{comment.text_content}
																	{((!comment.text_content || comment.text_content === '') && (!comment.image || comment.image === '') && (!comment.video || comment.video === '')) ? 'Could not fetch comment. Please use the direct comment link to see the comment content at Facebook' : null }
																</div> }
															</div> : <div className="facebook-comment">
																{/* <div className="comment-tools" id={comment._id}>
																	{ comment.deleted === 'false' ? 
																	comment.hidden === 'true' ? <><span className="tool comment-hide" title="Unhide Comment" onClick={(event) => this.hideComment(comment.comment_id, false, event)}>
																		<i className="fa fa-eye"></i>
																	</span> <span className="tool comment-delete" title="Delete Comment" onClick={(event) => this.deleteComment(comment.comment_id, event)}>
																		<i className="fa fa-trash-o"></i>
																	</span></> : <><span className="tool comment-hide" title="Hide Comment" onClick={(event) => this.hideComment(comment.comment_id, true, event)}>
																		<i className="fa fa-eye"></i>
																	</span> <span className="tool comment-delete" title="Delete Comment" onClick={(event) => this.deleteComment(comment.comment_id, event)}>
																		<i className="fa fa-trash-o"></i>
																	</span></> : null }
																	<span className="tool comment-tag" title="Add Tag" onClick={this.props.selectComment.bind(this, comment._id)}>
																		<i className="fa fa-tags"></i>
																	</span>
																</div> */}
																{ comment.hidden === 'true' && comment.deleted === 'true' ? <div id={comment._id} className="hiddenComment deleted">
																	<a className="facebook-card-content-user" href="#/">{comment.customer.name}</a>
																	{comment.text_content}
																	{((!comment.text_content || comment.text_content === '') && (!comment.image || comment.image === '') && (!comment.video || comment.video === '')) ? 'Could not fetch comment. Please use the direct comment link to see the comment content at Facebook' : null }
																</div> : comment.hidden === 'true' ? <div id={comment._id} className="hiddenComment">
																	<a className="facebook-card-content-user" href="#/">{comment.customer.name}</a>
																	{comment.text_content}
																	{((!comment.text_content || comment.text_content === '') && (!comment.image || comment.image === '') && (!comment.video || comment.video === '')) ? 'Could not fetch comment. Please use the direct comment link to see the comment content at Facebook' : null }
																</div> : comment.deleted === 'true' ? <div id={comment._id} className="deleted">
																	<a className="facebook-card-content-user" href="#/">{comment.customer.name}</a>
																	{comment.text_content}
																	{((!comment.text_content || comment.text_content === '') && (!comment.image || comment.image === '') && (!comment.video || comment.video === '')) ? 'Could not fetch comment. Please use the direct comment link to see the comment content at Facebook' : null }
																</div> : <div id={comment._id}>
																	<a className="facebook-card-content-user" href="#/">{comment.customer.name}</a>
																	{comment.text_content}
																	{((!comment.text_content || comment.text_content === '') && (!comment.image || comment.image === '') && (!comment.video || comment.video === '')) ? 'Could not fetch comment. Please use the direct comment link to see the comment content at Facebook' : null }
																</div> }
															</div>}
															{comment.hasOwnProperty('image') && comment.image !== '' ? <div className="commentImage" style={{backgroundImage: `url(${encodeURI(comment.image)})`}} onClick={() => this.openImagePopup(comment.image)}></div> : null}
															{comment.hasOwnProperty('video') && comment.video !== '' ? <div className="commentImage" style={{backgroundImage: `url(${video})`}} onClick={() => window.open(comment.video, '_blank')}></div> : null}
															<div className="reaction-buttons">
																{this.props.currentComment._id === comment._id ? comment.deleted === 'false' && comment.hidden === 'false' ? comment.likes === 'false' ? <a className="facebook-card-comment-option" href="#/" onClick={(event) => this.likeComment(comment.comment_id, true, event)}>Like</a> : <a className="facebook-card-comment-option" href="#/" onClick={(event) => this.likeComment(comment.comment_id, false, event)} title={comment.liked_by && comment.liked_by.username ? comment.liked_by.username : null}>Unlike</a> : null : null }
																<a className="facebook-card-comment-date" href="#/"><Moment fromNow tz="Asia/Dhaka" date={comment.time_stamp} /></a> {comment.hidden === 'true' && comment.hidden_by && comment.hidden_by.username ? <>- Hidden by {comment.hidden_by.username}</> : null} {comment.resolved_status === 'resolved' && comment.resolved_by && comment.resolved_by.username ? <>- Resolved by {comment.resolved_by.username}</> : null} {comment.private_reply_receiver && comment.private_reply_receiver !== '' ? <>- Privately Replied</> : null}
															</div>
															{comment.tags ? 
															<div className="comment-tags">
																{comment.tags.map((tag, index) => <span key={index} onClick={this.removeTag.bind(this, comment._id, tag.title)}>{tag.title} <i className="fa fa-times"></i></span>)}
															</div> : null}
														</div>
													</div> : 
													((comment.reply_of && (this.props.post_id === comment.reply_of)) || (comment.reply_of && this.props.currentComment.comment_id && (this.props.currentComment.comment_id === comment.reply_of))) ? 
													<div key={comment._id} className="page main-comment replyComment">
														<div className="col-sm-1" style={style1}>
															<a className="facebook-card-user-image" href="#/">
																<ReactImageFallback className="facebook-card-user-image" src={this.props.project_image ? this.props.project_image : avatar} alt={this.props.project_title ? this.props.project_title : 'listenyzen'} title="listenyzen" fallbackImage={nophoto} initialImage={spinner} />
															</a>
														</div>
														<div className="col-sm-11" style={style2}>
															<div className="facebook-comment">
																<div>
																	<a className="facebook-card-content-user" href="#/">{this.props.project_title ? this.props.project_title : 'listenyzen'}</a>
																	{comment.text_content}
																</div>
																<div className="comment-tools" id={comment._id}>
																	<span className="tool comment-delete" title="Delete Comment" onClick={(event) => this.deletePageComment(comment.comment_id, event)}>
																		<i className="fa fa-trash-o"></i>
																	</span>
																</div>
															</div>
															{comment.hasOwnProperty('image') && comment.image !== '' ? <div className="commentImage" style={{backgroundImage: `url(${encodeURI(comment.image)})`}} onClick={() => this.openImagePopup(comment.image)}></div> : null}
															{comment.hasOwnProperty('video') && comment.video !== '' ? <div className="commentImage" style={{backgroundImage: `url(${video})`}} onClick={() => window.open(comment.video, '_blank')}></div> : null}
															<div className="reaction-buttons">
																<a className="facebook-card-comment-date" href="#/"><Moment fromNow tz="Asia/Dhaka" date={comment.time_stamp} /></a> {comment.answered_by ? '- Replied by ' + comment.answered_by.username : null} {comment.private_reply_receiver && comment.private_reply_receiver !== '' ? <>- Privately Replied</> : null}
															</div>
														</div>
													</div> : <div key={comment._id} className="page main-comment">
														<div className="col-sm-1" style={style1}>
															<a className="facebook-card-user-image" href="#/">
																<ReactImageFallback className="facebook-card-user-image" src={this.props.project_image ? this.props.project_image : avatar} alt={this.props.project_title ? this.props.project_title : 'listenyzen'} title="listenyzen" fallbackImage={nophoto} initialImage={spinner} />
															</a>
														</div>
														<div className="col-sm-11" style={style2}>
															<div className="facebook-comment">
																<div>
																	<a className="facebook-card-content-user" href="#/">{this.props.project_title ? this.props.project_title : 'listenyzen'}</a>
																	{comment.text_content}
																</div>
																{/* <div className="comment-tools" id={comment._id}>
																	<span className="tool comment-delete" title="Delete Comment" onClick={(event) => this.deletePageComment(comment.comment_id, event)}>
																		<i className="fa fa-trash-o"></i>
																	</span>
																</div> */}
															</div>
															{comment.hasOwnProperty('image') && comment.image !== '' ? <div className="commentImage" style={{backgroundImage: `url(${encodeURI(comment.image)})`}} onClick={() => this.openImagePopup(comment.image)}></div> : null}
															{comment.hasOwnProperty('video') && comment.video !== '' ? <div className="commentImage" style={{backgroundImage: `url(${video})`}} onClick={() => window.open(comment.video, '_blank')}></div> : null}
															<div className="reaction-buttons">
																<a className="facebook-card-comment-date" href="#/"><Moment fromNow tz="Asia/Dhaka" date={comment.time_stamp} /></a> {comment.answered_by ? '- Replied by ' + comment.answered_by.username : null} {comment.private_reply_receiver && comment.private_reply_receiver !== '' ? <>- Privately Replied</> : null}
															</div>
														</div>
													</div> : (comment.sender_is === 'user') ? 
														<div key={comment._id} className="reply-comment">
														<div className="col-sm-1" style={style1}>
															<a className="facebook-card-user-image" href="#/">
																<ReactImageFallback className="facebook-card-user-image" src={comment.customer.profile_pic} alt={comment.customer.name} fallbackImage={nophoto} initialImage={spinner} />
															</a>
														</div>
														<div className="col-sm-11" style={style2}>
															{this.props.currentComment._id === comment._id ? <div className={current_comment_class}>
																<div className="comment-tools" id={comment._id}>
																	{ comment.deleted === 'false' ? 
																	comment.hidden === 'true' ? <><span className="tool comment-hide" title="Unhide Comment" onClick={(event) => this.hideComment(comment.comment_id, false, event)}>
																		<i className="fa fa-eye"></i>
																	</span> <span className="tool comment-delete" title="Delete Comment" onClick={(event) => this.deleteComment(comment.comment_id, event)}>
																		<i className="fa fa-trash-o"></i>
																	</span></> : <><span className="tool comment-hide" title="Hide Comment" onClick={(event) => this.hideComment(comment.comment_id, true, event)}>
																		<i className="fa fa-eye"></i>
																	</span> <span className="tool comment-delete" title="Delete Comment" onClick={(event) => this.deleteComment(comment.comment_id, event)}>
																		<i className="fa fa-trash-o"></i>
																	</span></> : null }
																	<span className="tool comment-tag" title="Add Tag" onClick={this.props.selectComment.bind(this, comment._id)}>
																		<i className="fa fa-tags"></i>
																	</span>
																</div>
																{ comment.hidden === 'true' && comment.deleted === 'true' ? <div id={comment._id} className="hiddenComment deleted">
																	<a className="facebook-card-content-user" href="#/">{comment.customer.name}</a>
																	{comment.text_content}
																	{((!comment.text_content || comment.text_content === '') && (!comment.image || comment.image === '') && (!comment.video || comment.video === '')) ? 'Could not fetch comment. Please use the direct comment link to see the comment content at Facebook' : null }
																</div> : comment.hidden === 'true' ? <div id={comment._id} className="hiddenComment">
																	<a className="facebook-card-content-user" href="#/">{comment.customer.name}</a>
																	{comment.text_content}
																	{((!comment.text_content || comment.text_content === '') && (!comment.image || comment.image === '') && (!comment.video || comment.video === '')) ? 'Could not fetch comment. Please use the direct comment link to see the comment content at Facebook' : null }
																</div> : comment.deleted === 'true' ? <div id={comment._id} className="deleted">
																	<a className="facebook-card-content-user" href="#/">{comment.customer.name}</a>
																	{comment.text_content}
																	{((!comment.text_content || comment.text_content === '') && (!comment.image || comment.image === '') && (!comment.video || comment.video === '')) ? 'Could not fetch comment. Please use the direct comment link to see the comment content at Facebook' : null }
																</div> : <div id={comment._id}>
																	<a className="facebook-card-content-user" href="#/">{comment.customer.name}</a>
																	{comment.text_content}
																	{((!comment.text_content || comment.text_content === '') && (!comment.image || comment.image === '') && (!comment.video || comment.video === '')) ? 'Could not fetch comment. Please use the direct comment link to see the comment content at Facebook' : null }
																</div> }
															</div> : <div className="facebook-comment">
																{/* <div className="comment-tools" id={comment._id}>
																	{ comment.deleted === 'false' ? 
																	comment.hidden === 'true' ? <><span className="tool comment-hide" title="Unhide Comment" onClick={(event) => this.hideComment(comment.comment_id, false, event)}>
																		<i className="fa fa-eye"></i>
																	</span> <span className="tool comment-delete" title="Delete Comment" onClick={(event) => this.deleteComment(comment.comment_id, event)}>
																		<i className="fa fa-trash-o"></i>
																	</span></> : <><span className="tool comment-hide" title="Hide Comment" onClick={(event) => this.hideComment(comment.comment_id, true, event)}>
																		<i className="fa fa-eye"></i>
																	</span> <span className="tool comment-delete" title="Delete Comment" onClick={(event) => this.deleteComment(comment.comment_id, event)}>
																		<i className="fa fa-trash-o"></i>
																	</span></> : null }
																	<span className="tool comment-tag" title="Add Tag" onClick={this.props.selectComment.bind(this, comment._id)}>
																		<i className="fa fa-tags"></i>
																	</span>
																</div> */}
																{ comment.hidden === 'true' && comment.deleted === 'true' ? <div id={comment._id} className="hiddenComment deleted">
																	<a className="facebook-card-content-user" href="#/">{comment.customer.name}</a>
																	{comment.text_content}
																	{((!comment.text_content || comment.text_content === '') && (!comment.image || comment.image === '') && (!comment.video || comment.video === '')) ? 'Could not fetch comment. Please use the direct comment link to see the comment content at Facebook' : null }
																</div> : comment.hidden === 'true' ? <div id={comment._id} className="hiddenComment">
																	<a className="facebook-card-content-user" href="#/">{comment.customer.name}</a>
																	{comment.text_content}
																	{((!comment.text_content || comment.text_content === '') && (!comment.image || comment.image === '') && (!comment.video || comment.video === '')) ? 'Could not fetch comment. Please use the direct comment link to see the comment content at Facebook' : null }
																</div> : comment.deleted === 'true' ? <div id={comment._id} className="deleted">
																	<a className="facebook-card-content-user" href="#/">{comment.customer.name}</a>
																	{comment.text_content}
																	{((!comment.text_content || comment.text_content === '') && (!comment.image || comment.image === '') && (!comment.video || comment.video === '')) ? 'Could not fetch comment. Please use the direct comment link to see the comment content at Facebook' : null }
																</div> : <div id={comment._id}>
																	<a className="facebook-card-content-user" href="#/">{comment.customer.name}</a>
																	{comment.text_content}
																	{((!comment.text_content || comment.text_content === '') && (!comment.image || comment.image === '') && (!comment.video || comment.video === '')) ? 'Could not fetch comment. Please use the direct comment link to see the comment content at Facebook' : null }
																</div> }
															</div>}
															{comment.hasOwnProperty('image') && comment.image !== '' ? <div className="commentImage" style={{backgroundImage: `url(${encodeURI(comment.image)})`}} onClick={() => this.openImagePopup(comment.image)}></div> : null}
															{comment.hasOwnProperty('video') && comment.video !== '' ? <div className="commentImage" style={{backgroundImage: `url(${video})`}} onClick={() => window.open(comment.video, '_blank')}></div> : null}
															<div className="reaction-buttons">
																{this.props.currentComment._id === comment._id ? comment.deleted === 'false' && comment.hidden === 'false' ? comment.likes === 'false' ? <a className="facebook-card-comment-option" href="#/" onClick={(event) => this.likeComment(comment.comment_id, true, event)}>Like</a> : <a className="facebook-card-comment-option" href="#/" onClick={(event) => this.likeComment(comment.comment_id, false, event)} title={comment.liked_by && comment.liked_by.username ? comment.liked_by.username : null}>Unlike</a> : null : null }
																<a className="facebook-card-comment-date" href="#/"><Moment fromNow tz="Asia/Dhaka" date={comment.time_stamp} /></a> {comment.hidden === 'true' && comment.hidden_by && comment.hidden_by.username ? <>- Hidden by {comment.hidden_by.username}</> : null} {comment.resolved_status === 'resolved' && comment.resolved_by && comment.resolved_by.username ? <>- Resolved by {comment.resolved_by.username}</> : null} {comment.private_reply_receiver && comment.private_reply_receiver !== '' ? <>- Privately Replied</> : null}
															</div>
															{comment.tags ? 
															<div className="comment-tags">
																{comment.tags.map((tag, index) => <span key={index} onClick={this.removeTag.bind(this, comment._id, tag.title)}>{tag.title} <i className="fa fa-times"></i></span>)}
															</div> : null}
														</div>
													</div> : 
													((comment.reply_of && (this.props.post_id === comment.reply_of)) || (comment.reply_of && this.props.currentComment.comment_id && (this.props.currentComment.comment_id === comment.reply_of))) ? 
													<div key={comment._id} className="page reply-comment replyComment">
														<div className="col-sm-1" style={style1}>
															<a className="facebook-card-user-image" href="#/">
															<ReactImageFallback className="facebook-card-user-image" src={this.props.project_image ? this.props.project_image : avatar} alt={this.props.project_title ? this.props.project_title : 'listenyzen'} title="listenyzen" fallbackImage={nophoto} initialImage={spinner} />
															</a>
														</div>
														<div className="col-sm-11" style={style2}>
															<div className="facebook-comment">
																<div>
																	<a className="facebook-card-content-user" href="#/">{this.props.project_title ? this.props.project_title : 'listenyzen'}</a>
																	{comment.text_content}
																</div>
																<div className="comment-tools" id={comment._id}>
																	<span className="tool comment-delete" title="Delete Comment" onClick={(event) => this.deletePageComment(comment.comment_id, event)}>
																		<i className="fa fa-trash-o"></i>
																	</span>
																</div>
															</div>
															{comment.hasOwnProperty('image') && comment.image !== '' ? <div className="commentImage" style={{backgroundImage: `url(${encodeURI(comment.image)})`}} onClick={() => this.openImagePopup(comment.image)}></div> : null}
															{comment.hasOwnProperty('video') && comment.video !== '' ? <div className="commentImage" style={{backgroundImage: `url(${video})`}} onClick={() => window.open(comment.video, '_blank')}></div> : null}
															<div className="reaction-buttons">
																<a className="facebook-card-comment-date" href="#/"><Moment fromNow tz="Asia/Dhaka" date={comment.time_stamp} /></a> {comment.answered_by ? '- Replied by ' + comment.answered_by.username : null} {comment.private_reply_receiver && comment.private_reply_receiver !== '' ? <>- Privately Replied</> : null}
															</div>
														</div>
													</div> : <div key={comment._id} className="page reply-comment">
														<div className="col-sm-1" style={style1}>
															<a className="facebook-card-user-image" href="#/">
															<ReactImageFallback className="facebook-card-user-image" src={this.props.project_image ? this.props.project_image : avatar} alt={this.props.project_title ? this.props.project_title : 'listenyzen'} title="listenyzen" fallbackImage={nophoto} initialImage={spinner} />
															</a>
														</div>
														<div className="col-sm-11" style={style2}>
															<div className="facebook-comment">
																<div>
																	<a className="facebook-card-content-user" href="#/">{this.props.project_title ? this.props.project_title : 'listenyzen'}</a>
																	{comment.text_content}
																</div>
																{/* <div className="comment-tools" id={comment._id}>
																	<span className="tool comment-delete" title="Delete Comment" onClick={(event) => this.deletePageComment(comment.comment_id, event)}>
																		<i className="fa fa-trash-o"></i>
																	</span>
																</div> */}
															</div>
															{comment.hasOwnProperty('image') && comment.image !== '' ? <div className="commentImage" style={{backgroundImage: `url(${encodeURI(comment.image)})`}} onClick={() => this.openImagePopup(comment.image)}></div> : null}
															{comment.hasOwnProperty('video') && comment.video !== '' ? <div className="commentImage" style={{backgroundImage: `url(${video})`}} onClick={() => window.open(comment.video, '_blank')}></div> : null}
															<div className="reaction-buttons">
																<a className="facebook-card-comment-date" href="#/"><Moment fromNow tz="Asia/Dhaka" date={comment.time_stamp} /></a> {comment.answered_by ? '- Replied by ' + comment.answered_by.username : null} {comment.private_reply_receiver && comment.private_reply_receiver !== '' ? <>- Privately Replied</> : null}
															</div>
														</div>
													</div>) }
											</div>
										</div>
									</div>
								</div>
							</div> : null }
						</div>
					</Scrollbars> : <div className="instruction-container">
							<div className="instruction-comment text-center">
									<h1>Welcome to Inbox</h1>
									{/* <img className="logo img-responsive" src={logo} alt="listenyzen Logo" /> */}
									<p>
											Click any user from left bar to view the conversation.
									</p>
									<img className="instruction-image img-responsive" src={instruction} alt="Instruction" />
							</div>
					</div>}
					{this.state.isOpen && (
						<Lightbox
							mainSrc={this.state.imagePopup}
							onCloseRequest={() => this.setState({ isOpen: false })}
						/>
					)}
				</div>
				{this.props.currentUser.hasOwnProperty('name') && !this.props.conversationLoading  ?
					<div className="row reply">
						<div className="col-sm-8 reply-main">
							<form className="text-bar-field">
								<textarea id="reply-conversation-comment" disabled={this.state.replyTextDisabled} placeholder="Write your comment here..." onChange={(event) => this.getReplyInput(event)} onBlur={this.removeTyping} value={this.state.replyValue !== null ? this.state.replyValue : localStorage.getItem(this.props.match.params.project_id + '_fb_comment_' + getUserData() + '_' + this.props.currentComment._id) ? localStorage.getItem(this.props.match.params.project_id + '_fb_comment_' + getUserData() + '_' + this.props.currentComment._id) : this.props.messageSuggestions && this.props.messageSuggestions.length > 0 ? this.props.messageSuggestions[0].replace('{user}', this.props.currentUser.name) : ''}></textarea>
							</form>
						</div>
						<div className="col-sm-4 reply-tools text-right">
							<img className="img-uploaded" id="file-uploaded-comment" width="32px" alt="" onClick={this.clearImage} />
							{!this.state.messagesend ? <span className="attach-file">
								<label className="upload-attachment">
									<i className="fa fa-paperclip" title="Attach Image"></i>
									<input type="file" id="upload_comment_image" accept=".jpg,.jpeg,.png" disabled={this.state.fileDisabled} onChange={(event) => {
														if(event.currentTarget.files && event.currentTarget.files[0]) {
															if(event.currentTarget.files[0].size <= 1048576) {
																this.setState({
																	replyTextDisabled: true,
																	replyImage: 'has',
																	messagesendAllowed: false
																});
																var reader = new FileReader();
																reader.onload = function (e) {
																	if(document.getElementById("file-uploaded-comment")) document.getElementById("file-uploaded-comment").src = e.target.result;
																}
																reader.readAsDataURL(event.currentTarget.files[0]);
															} else {
																if(document.getElementById("file-uploaded-comment")) document.getElementById("file-uploaded-comment").src = '';
																this.setState({
																	replyTextDisabled: false,
																	replyImage: '',
																	messagesendAllowed: true
																});
																toast.error("😪 Image size must not exceed 1MB!", {
																	autoClose: 3000,
																	position: 'bottom-right',
																	className: 'notification-message'
																});
															}
														}
													}} />
								</label>
							</span> : null}
								{ this.props.fixed_replies.length > 0 ? <span className="quick-chat">
										<i className="fa fa-list-alt" title="Fixed Replies" onClick={this.openFixedReplies}></i>
									</span> : null }
								
								<span className="emoji_open">
									<i className="fa fa-smile-o" title="Insert Emoji" onClick={this.shEmoji}></i>		
								</span>

								{this.state.messagesendAllowed ? <span className="messagesend" title="Send as Private Message" onClick={this.setAsMessage}>
									<i className={envelope_class}></i>
								</span> : null}

								{this.state.replyValue !== '' && this.state.replyValue !== null ? <div className="reply-draft">
									<i className="fa fa-floppy-o" title="Save as Draft" onClick={this.saveDraft}></i>
								</div> : null}

								<div className="reply-send">
									{((this.state.replyValue !== '' && this.state.replyValue !== null) || this.state.replyImage === 'has') && !this.state.submitting ? !this.state.messagesend ? <i title="Send Reply" className="fa fa-send" onClick={this.submitReply}></i> : <i className="fa fa-send" onClick={this.confirm}></i> : null }
									{this.state.submitting ? <img src={loader} alt="Loader" /> : null}
								</div>
								<Scrollbars>
									<div className={quick_chat_show_status}>
											<div>
												<div class="form-group has-search">
													<div class="glyphicon glyphicon-search form-control-feedback"></div>
													<input type="text" id="fixed_search" class="form-control" placeholder="Search" onChange={this.searchFixedReplies} />
												</div>
											</div>

											<ul className="list-group">
												{ this.state.fixed_replies.map((reply, index) => <li key={index} className="list-group-item">
													<div className="row">
														<div className="col-sm-10" style={{padding: '0 0 0 15px'}} onClick={this.fillUpReplyWithReplies.bind(this, reply.replace("{user}", this.props.currentUser.name))}>{reply.replace("{user}", this.props.currentUser.name)}</div>
														<div className="col-sm-2 text-right" style={{padding: '0 13px 0 0'}}><i className="fa fa-pencil-square-o" style={{cursor: 'pointer'}} onClick={this.props.modal.bind(this, 'add_efr_modal', reply)}></i><i className="fa fa-times" style={{marginLeft: '10px', cursor: 'pointer'}} onClick={this.deleteReply.bind(this, this.props.match.params.project_id, reply)}></i></div>
													</div>
													</li>)}
											</ul>
									</div>
								</Scrollbars>
						</div>
					</div> : <div className="row reply-placeholder"></div>}
					{this.props.currentUser.hasOwnProperty('name') && !this.props.conversationLoading && this.state.emojiShown  ? <Picker set='messenger' showPreview={false} title='Pick your emoji…' emoji='point_up' style={{ position: 'absolute', bottom: '90px', right: '20px' }} onSelect={this.addEmoji} /> : null}
			</>
		);
	}
};

const mapStateToProps = state => {
	return {
		conversations: state.comment_state.currentMessages,
		conversationLoading: state.inbox_state.conversationLoading,
		currentUser: state.inbox_state.currentUser,
		messageSuggestions: state.comment_state.messageSuggestions,
		fbPostShown: state.comment_state.fbPostShown,
		post_id: state.comment_state.currentPostId,
		project_title: state.inbox_state.project_title,
		project_image: state.inbox_state.project_image,
		fixed_replies: state.inbox_state.fixed_replies,
		currentComment: state.comment_state.currentComment,
		csrf: state.site_state.csrf
  };
}

const mapActionToProps = dispatch => {
    return {
			selectComment: (id) => dispatch(actions.selectComment(id)),
			removeTags: (comment_id, tag, csrf) => dispatch(actions.removeCommentTag(comment_id, tag, csrf)),
			fixedReplies: (project_id) => dispatch(actions.fixedReplies(project_id))
		};
};

export default withRouter(connect(mapStateToProps, mapActionToProps)(Conversation));