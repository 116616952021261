import React from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { insertTag } from '../../../services/ProjectService';

const AddTag = (props) => {
	let schema = yup.object({
		_csrf: yup.string(),
		tag: yup.string().required('You must mention tag!')
	});

	let initialValues = { tag: '', project_id: props.project_id };
	return (
		<Modal className="add-tag" show={props.show} onHide={props.manage.bind(this, 'add_tag_modal')}>
			<Formik
				initialValues={initialValues}
				validationSchema={schema} 
				onSubmit={(values, { setSubmitting, resetForm }) => {
					values._csrf = props.csrf;
					insertTag(values)
						.then(response => {
							if(response.data) {
								toast.success("😀 Tag was added successfully!", {
									autoClose: 2000,
									position: 'bottom-right',
									className: 'notification-message'
								});
								setSubmitting(false);
								resetForm();
							}
						})
						.catch(err => {
							setSubmitting(false);
						});
				}}
			>
				{({
						values,
						touched,
						errors,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit
					}) => (
						<form onSubmit={handleSubmit}>
							<Modal.Header closeButton>
								<Modal.Title>Add Tag</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div className="form-group">
									<label className="control-label">Tag Name</label>
									<input name="tag" type="text" value={values.tag} onChange={handleChange} onBlur={handleBlur} className={
											errors.tag && touched.tag ? 'form-control error' : 'form-control'
										} />
									{errors.tag && touched.tag && <div className="input-feedback">{errors.tag}</div>}
								</div>
							</Modal.Body>
							<Modal.Footer className="text-right">
								<button type="submit" disabled={isSubmitting} className="btn btn-primary">Submit</button>
							</Modal.Footer>
						</form>
			)}
			</Formik>
		</Modal>
	);
};

const mapStateToProps = state => {
	return {
		csrf: state.site_state.csrf
  };
}

export default connect(mapStateToProps)(AddTag);