import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import queryString from 'query-string'
import FbLoginButton from '../../components/FbLoginButton/FbLoginButton';
import LoginForm from '../../components/LoginForm/LoginForm';
import VerifyForm from '../../components/VerifyForm/VerifyForm';
import * as classService from '../../services/ClassService';
import analyzenLogo from '../../assets/img/analyzen-logo.png';
import '../../assets/css/login.css';
import logo from '../../assets/img/logo.png';

class Login extends Component {
	state = {
		signup: false,
		login: 'show',
		ssignup: 'hide'
	};
	constructor(props) {
		super(props);
		this.varticalCenterStuff = this.varticalCenterStuff.bind(this);
	}

	varticalCenterStuff = () => {
		var loginBoxHeight = document.querySelectorAll('.login-block')[0].clientHeight;
		var mathLogin = (window.innerHeight / 2) - (loginBoxHeight / 2);
		document.querySelectorAll('.login-block')[0].style.marginTop = mathLogin + "px";
	}
	componentDidMount() {
		document.title = 'Login / Signup - listenyzen';
		document.body.classList.add('login-body');
		setTimeout(() => {
			this.varticalCenterStuff();
		}, 100);
		window.addEventListener("resize", this.varticalCenterStuff);

		const pvalues = queryString.parse(this.props.location.search);
		if(pvalues.with_verification) {
			toast.success("😀 Congrats! You have successfully verified your email address. Now you can login using your email and password!", {
				autoClose: 8000,
				position: 'bottom-right',
				className: 'notification-message'
			});
		}
		if(pvalues.reset_success) {
			toast.success("😀 Your password reset is successful! You can now login with your email and new password.", {
				autoClose: 8000,
				position: 'bottom-right',
				className: 'notification-message'
			});
		}
	}

	componentWillUnmount() {
		document.body.classList.remove('login-body');
		window.removeEventListener("resize", this.varticalCenterStuff);
	}

	changeSignupMode = (mode) => {
		if(mode === true) {
			this.setState({
				signup: mode,
				ssignup: 'show',
				login: 'hide'
			});
		}
	}

	goBack = () => {
		this.setState({
			ssignup: 'hide',
			login: 'show',
			signup: false
		});
	}

	goToVerify = () => {
		this.setState({
			ssignup: 'hide',
			signup: true
		});
		classService.addClass(document.getElementById('gb'), 'hide');
	}

	goToFP = () => {
		window.location.replace(process.env.REACT_APP_CLIENT_URL + '/forget_password');
	}

	render() {
		return (
			<div className="Login">
				<div id="login-wrapper">
					<section className="login-block">
						<div className="container">
							<div className="row">
								<div className="col-sm-4 login-sec pt">
									<div className="login-heading text-center">
										<Link to="/"><img src={logo} alt="listenyzen Logo" /></Link>
										{/* <h2 className="title text-center">Login</h2> */}
										<p></p>
										<p>Smart Social Media Customer Query Management system<br/>
										Powered by Artificial Intelligence Technology</p>
									</div>
									{!this.props.match.params.email ? <LoginForm modeHandler={this.changeSignupMode} login={this.state.login} signup={this.state.ssignup} goBack={this.goBack} goToVerify={this.goToVerify} /> : <VerifyForm  /> }
									{(!this.state.signup && !this.props.match.params.email) ? <><div className="orholder">or</div>
									<FbLoginButton />
									<div className="sfp" onClick={this.goToFP}>Forget Password</div></> : !this.props.match.params.email ? <div className="sfp" id="gb" onClick={this.goBack}>Go back</div> : null}
									{/* <Copyright /> */}
								</div>
								<div className="col-sm-8 banner-sec"></div>
							</div>
						</div>
						<div className="container footer">
							<div className="col-sm-6">
								&copy; {new Date().getFullYear()} <a href="https://www.analyzenbd.com" target="_blank" rel="noopener noreferrer"><img src={analyzenLogo} alt="Analyzen" style={{verticalAlign: 'sub', width: '10%'}} /></a>. All rights reserved.
							</div>
							<div className="col-sm-6 text-right">
								<Link to="/terms">Terms & Privacy</Link>
							</div>
						</div>
					</section>
				</div>
			</div>
		);
	}
};

export default withRouter(Login);