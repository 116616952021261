import axios from '../api/private';

export const genCsrf = () => {
	return axios.get('/genCsrf');
};

export const extendToken = (access_token, csrf) => {
	return axios.post('/project/extendToken', {
		access_token: access_token,
		_csrf: csrf
	});
};

export const getProject = (project_id) => {
	return axios.get('/project?project_id=' + project_id);
};

export const getProjects = () => {
	return axios.get('/projects');
};

export const getQueryManagers = (project_id) => {
	return axios.get('/project/getUsers/?project_id=' + project_id);
};

export const getProjectManagers = (project_id) => {
	return axios.get('/project/getUsers/?type=pm&project_id=' + project_id);
};

export const unassign = (project_id, user) => {
	return axios.post('/project/unassign', {
		project_id: project_id,
		user: user
	});
};

export const assign = (project_id, user) => {
	return axios.post('/project/assign', {
		project_id: project_id,
		user: user
	});
};

export const unassignp = (project_id, user) => {
	return axios.post('/project/unassignp', {
		project_id: project_id,
		user: user
	});
};

export const assignp = (project_id, user) => {
	return axios.post('/project/assignp', {
		project_id: project_id,
		user: user
	});
};

export const addProject = (projectInfo) => {
	return axios.post('/project/add', projectInfo);
};

export const addFixedReply = (project_id, reply) => {
	return axios.post('/project/addFixedReply', {
		project_id: project_id,
		message: reply
	});
};

export const editFixedReply = (project_id, preply, nreply) => {
	return axios.post('/project/editFixedReply', {
		project_id: project_id,
		reply: preply,
		ureply: nreply
	});
};

export const deleteFixedReply = (project_id, reply) => {
	return axios.post('/project/deleteFixedReply', {
		project_id: project_id,
		reply: reply
	});
};

export const connectPage = (page_id, project_id, page_name, access_token, user_access_token) => {
	return axios.post('/project/connectFb', {
		project_id: project_id,
		page_id: page_id,
		access_token: access_token,
		page_name: page_name,
		user_access_token: user_access_token
	});
};

export const disconnectPage = (page_id, project_id, access_token) => {
	return axios.post('/project/disconnectFb', {
		project_id: project_id,
		page_id: page_id,
		access_token: access_token
	});
};

export const connectInstagram = (project_id) => {
	return axios.post('/project/connectInstagram', {
		project_id: project_id
	});
};

export const getFixedReplies = (project_id) => {
	return axios.get('/project/getFixedReplies/' + project_id);
};

export const getPopularTags = (project_id) => {
	return axios.get('/popularTags?project_id=' + project_id);
};

export const insertTag = (formData) => {
	return axios.post('/tag/insert', formData);
};

export const addTag = (type, tags, ids, csrf) => {
	return axios.post('/tag/add/' + type, {
		tags: tags,
		ids: ids,
		_csrf: csrf
	});
};

export const removeTag = (type, tag, id, csrf) => {
	return axios.post('/tag/remove/' + type + '/' + id, {
		tag: tag,
		_csrf: csrf
	});
};

export const getTags = (keyword, project_id) => {
	return axios.get('/tags?keyword=' + keyword + '&project_id=' + project_id);
};

export const connectViber = (data) => {
	return axios.post('/project/connectViber', data);
};