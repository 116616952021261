import React, { Component } from 'react';
import TableSection from './Table/Table';

class TagManagerContent extends Component {

	render() {
		return (
			<div className="analytics-dashboard-content">
				<div className="row">
					<div className="col-sm-12">
						<TableSection />
					</div>
				</div>
			</div>
		);
	}
};

export default TagManagerContent;