import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import Wrapper from '../../components/Wrapper/Wrapper';
import FilterBar from '../../components/FilterBar/FilterBar';
import DashboardContent from '../../components/DashboardContent/DashboardContent';

class Dashboard extends Component {
	componentDidMount() {
		document.title = 'Dashboard - listenyzen';
		document.body.classList.add('main-body');
	}

	componentWillUnmount() {
		document.body.classList.remove('main-body');
	}

	render() {
		return (
			<div className="Dashboard">
				<Header />
				<Wrapper>
					<div className="analytics-dashboard">
						<div className="container-fluid">
							<FilterBar />
							<DashboardContent />
						</div>
					</div>
				</Wrapper>
			</div>
		);
	}
};

export default Dashboard;