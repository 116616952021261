import React, { Component } from 'react';
import Select from 'react-select';

const options_entities = [
	{ value: '', label: 'Select a Value' },
	{ value: 'treato_balance', label: 'treato_balance' },
	{ value: 'offer_query', label: 'offer_query' },
	{ value: 'thanks', label: 'thanks' }
];

class TrainerContent extends Component {
	render() {
		return (
			<div className="analytics-dashboard-content">
				<div className="row">
					<div className="col-sm-6 col-centered">
						<h3>Test how listenyze<sup>n</sup> understands your customer query</h3>
						<p>You can train by adding more examples</p>
						<p><br/></p>
						<p><input type="text" className="form-control greyed" placeholder="User says..." /></p>
						<div className="row" style={{width: '90%', padding: '25px'}}>
							<div className="col-sm-6" style={{paddingTop: '10px'}}>
								<span className="entity">Intent</span>
							</div>
							<div className="col-sm-6">
								<Select id="entities" defaultValue={options_entities[0]} options={options_entities} placeholder="Select value" />
							</div>
						</div>
						<p><i className="fa fa-plus-circle"></i> Add a new entity</p>
						<p>
							<button type="button" class="btn trainbtn">
								<span><i class="fa fa-check"></i> Validate</span>
							</button>
						</p>
						<hr class="bhr" />
						<h3>You have 2 entities</h3>

						<table className="table table-hover" style={{width: '100%', margin: '30px auto'}}>
							<thead>
								<tr>
									<th className="text-left" width="33%">Entity</th>
									<th className="text-left" width="33%">Description</th>
									<th className="text-left" width="34%">Values</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="text-left">
										<p><b>keyword <i class="fa fa-arrow-right" style={{color: '#d0d0d0'}}></i></b></p>
										<p>LOOKUP STRATEGIES <span className="lookup_strategy">free text & keywords</span></p>
									</td>
									<td className="text-left">User-defined entity</td>
									<td className="text-left">ফার্নিচারের, Child, sports product, ব্যাগ, কসমেটিক্স, watch, shopping, laptop, infinity, Facebook</td>
								</tr>
								<tr>
									<td className="text-left">
										<p><b>intent <i class="fa fa-arrow-right" style={{color: '#d0d0d0'}}></i></b></p>
										<p>LOOKUP STRATEGIES</p>
									</td>
									<td className="text-left">User-defined entity</td>
									<td className="text-left">treato_balance, thanks, welcome, show_wallet, partner_query, offer_redemption, offer_query</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
};

export default TrainerContent;