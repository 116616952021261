import * as actions from '../actions/site_actions';
import _ from 'lodash';

const initialState = {
	csrf: ''
};

const reducer = (state = initialState, action) => {
	switch(action.type) {
		case actions.SET_CSRF:
			const setCsrf = _.cloneDeep(state);
			setCsrf.csrf = action.csrf;
			return setCsrf
		default:
			break;
	}

	return state;
}
export default reducer;