import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as yup from 'yup';
import * as UserService from '../../services/UserService';

class VerifyForm extends Component {

	componentDidMount() {
		setTimeout(() => {
			document.getElementById("submitbuttton").click();
		}, 100);
	}

	render() {
		let schema = yup.object({
			verification_code: yup.number().required('Verification code is required')
		});

		let initialValues;
		if(this.props.match.params.verification_code) {
			initialValues = { verification_code: this.props.match.params.verification_code };
		} else {
			initialValues = { verification_code: '' };
		}

		return (
			<Formik
				initialValues={initialValues}
				validationSchema={schema} 
				onSubmit={(values, { setSubmitting, resetForm }) => {
					values.email = this.props.match.params.email;
					UserService.verify_email(values)
						.then(response => {
							if(response.data.success) {
								setSubmitting(false);
								resetForm();
								localStorage.setItem('loginEmail', this.props.match.params.email);
								setTimeout(() => {
									window.location.replace(process.env.REACT_APP_CLIENT_URL + '/?with_verification=true');
								}, 1000);
							} else {
								setSubmitting(false);
								toast.error("😪 Sorry! We could not verify your email address. Please check your email again & enter valid verification code again.", {
									autoClose: 5000,
									position: 'bottom-right',
									className: 'notification-message'
								});
							}
						})
						.catch(err => {
							setSubmitting(false);
						});
				}}
			>
				{({
						values,
						touched,
						errors,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit
					}) => (
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label className="control-label">Verification Code</label>
								<input name="verification_code" placeholder="Enter verification code" type="number" value={values.verification_code} onChange={handleChange} onBlur={handleBlur} className={
										errors.verification_code && touched.verification_code ? 'form-control error' : 'form-control'
									} />
								{errors.verification_code && touched.verification_code && <div className="input-feedback">{errors.verification_code}</div>}
							</div>
							<button id="submitbuttton" type="submit" disabled={isSubmitting} className="btn btn-success btn-block" style={{fontSize: '16px'}}>Verify Account</button>
						</form>
			)}
			</Formik>
		);
	}
}

export default withRouter(VerifyForm);