import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Form, FormControl, FormGroup, ControlLabel } from 'react-bootstrap';
import { toast } from 'react-toastify';
import userIcon from '../../../assets/img/avatars/avatar1.png';
import messengerIcon from '../../../assets/img/social-icons/messenger.svg';
import facebookIcon from '../../../assets/img/social-icons/facebook.svg';
import viberIcon from '../../../assets/img/social-icons/vibermain.svg';
import instagramIcon from '../../../assets/img/social-icons/instagram.png';
import ReactImageFallback from "react-image-fallback";
import spinner from '../../../assets/img/login_spinner.gif';
import nophoto from '../../../assets/img/nophoto.png';
import * as CustomerService from '../../../services/CustomerService';
import * as actions from '../../../store/actions/inbox_actions';
import * as messageService from '../../../services/MessageService';

const copyPsid = () => {
	if(document.getElementById("psid_user")) {
		var copyText = document.getElementById("psid_user");
		copyText.select();
		document.execCommand("copy");
		if(document.getElementById('reply-conversation-comment')) {
			if(document.getElementById('reply-conversation-comment').value === '') {
				document.getElementById('reply-conversation-comment').value += '@[' + copyText.value + '] ';
			} else {
				document.getElementById('reply-conversation-comment').value += ' @[' + copyText.value + '] ';
			}
			document.getElementById('reply-conversation-comment').focus();
		}
	}
}

const transferThread = (project_id, psid, csrf) => {
	messageService.transferThread(project_id, psid, csrf)
		.then(response => {
			toast.success("😀 This user was handed over to Bot!", {
				autoClose: 2000,
				position: 'bottom-right',
				className: 'notification-message'
			});
		})
		.catch(err => {
			toast.error("😪 There was a problem while handing this user over to Bot", {
				autoClose: 3000,
				position: 'bottom-right',
				className: 'notification-message'
			});
		});
}

class Profile extends Component {
	constructor(props) {
		super(props);
		this.schema = yup.object({
			// mobile: yup.string().matches(/^(01)(3|4|5|6|7|8|9)\d{8}$/, 'Mobile number is invalid'),
			mobile: yup.string(),
			email: yup.string().email('Email is invalid'),
			address: yup.string(),
			gender: yup.string()
		});

		this.state = {
			initialValues: { psid: props.currentUser.psid, platform: (props.match.params.platform === 'messenger' || props.match.params.platform === 'facebook') ? 'facebook' : '', mobile: props.currentUser.mobile ? props.currentUser.mobile : '', email: props.currentUser.email ? props.currentUser.email : '', address: props.currentUser.address ? props.currentUser.address : '', gender: props.currentUser.gender ? props.currentUser.gender : '' }
		};
	}

	componentWillReceiveProps() {
		this.setState({
			initialValues: { psid: this.props.currentUser.psid, platform: (this.props.match.params.platform === 'messenger' || this.props.match.params.platform === 'facebook') ? 'facebook' : '', mobile: this.props.currentUser.mobile ? this.props.currentUser.mobile : '', email: this.props.currentUser.email ? this.props.currentUser.email : '', address: this.props.currentUser.address ? this.props.currentUser.address : '', gender: this.props.currentUser.gender ? this.props.currentUser.gender : '' }
		});
	}

	render() {
		var comment_link = '#/';
		if(this.props.currentComment.hasOwnProperty('comment_id') && this.props.currentComment.comment_id !== '' && this.props.currentComment.deleted !== 'true') {
			comment_link = 'https://www.facebook.com/' + this.props.currentComment.comment_id;
		} else if(this.props.currentComment.post_id !== '' && this.props.currentComment.deleted !== 'true') {
			comment_link = 'https://www.facebook.com/' + this.props.currentComment.post_id;
		}
		return (
			<>
				<div className="profile-id text-center">
					<div className="avatar">
						{this.props.currentUser.profile_pic ? <ReactImageFallback src={this.props.currentUser.profile_pic} alt={this.props.currentUser.name || this.props.currentUser.username} fallbackImage={nophoto} initialImage={spinner} /> : this.props.currentUser.avatar ? <ReactImageFallback src={this.props.currentUser.avatar} alt={this.props.currentUser.name} fallbackImage={nophoto} initialImage={spinner} /> : <ReactImageFallback src={userIcon} alt="No User" fallbackImage={nophoto} initialImage={spinner} />}
						<span className="app-icon">
							{this.props.match.params.platform === 'messenger' ? <a href={'https://business.facebook.com' + this.props.inbox_link} target="_blank" rel="noopener noreferrer" title="Go to Messenger Inbox"><img src={messengerIcon} alt="Messenger" /></a> : null }
							{this.props.match.params.platform === 'facebook' ? <a href={comment_link} target="_blank" rel="noopener noreferrer" title="Go to Facebook Feed"><img src={facebookIcon} alt="Facebook" /></a> : null }
							{this.props.match.params.platform === 'viber' ? <img src={viberIcon} alt="Viber" /> : null }
							{this.props.match.params.platform === 'instagram' ? <a href={this.props.currentiComment.media_url} target="_blank" rel="noopener noreferrer" title="Go to Instagram Media"><img src={instagramIcon} alt="Instagram" /></a> : null }
						</span>

						<span className="app-icon-connected">
							{this.props.match.params.platform === 'messenger' ? <a href={ process.env.REACT_APP_CLIENT_URL + '/inbox/' + this.props.match.params.project_id + '/facebook/' + this.props.currentUser.psid} target="_blank" rel="noopener noreferrer" title="Facebook Comments from this user"><img src={facebookIcon} alt="Facebook Comments from this user" /></a> : null }
							{this.props.match.params.platform === 'facebook' ? <a href={process.env.REACT_APP_CLIENT_URL + '/inbox/' + this.props.match.params.project_id + '/messenger/' + this.props.currentUser.psid} target="_blank" rel="noopener noreferrer" title="Messenger messages from this user"><img src={messengerIcon} alt="Messenger messages from this user" /></a> : null }
						</span>
					</div>
					<h3>{this.props.currentUser.name || '@' + this.props.currentUser.username}</h3>
					{this.props.match.params.platform === 'facebook' ? <p onClick={copyPsid} style={{cursor: 'pointer'}}>{this.props.currentUser.psid}</p> : <p>{this.props.currentUser.psid}</p>}
					{this.props.match.params.platform === 'facebook' ? <input type="text" readOnly id="psid_user" value={this.props.currentUser.psid} style={{position: 'absolute', left: '-9999px'}} /> : null }

					{this.props.match.params.platform === 'messenger' && this.props.currentUser && this.props.currentUser.current_thread && this.props.currentUser.current_thread === 'listenyzen' && this.props.bot_enabled ? <p><button className="btn float-right" onClick={transferThread.bind(this, this.props.match.params.project_id, this.props.currentUser.psid, this.props.csrf)}>Handover to Bot</button></p> : null}
					{this.props.match.params.platform === 'messenger' && this.props.currentUser && this.props.currentUser.current_thread && this.props.currentUser.current_thread === 'inbox' && this.props.bot_enabled ? <p>Manual handover to Bot required</p> : null}
				</div>
				{this.props.match.params.platform === 'messenger' || this.props.match.params.platform === 'facebook' ? <div className="basic-information">
					<div className="information-details">
						<h3>Basic Information</h3>
						{/* <ul>
							<li>
								<i className="fa fa-user"></i>{props.currentUser.gender}
							</li>
						</ul> */}
						<Formik
							enableReinitialize={true}
							initialValues={this.state.initialValues}
							validationSchema={this.schema} 
							onSubmit={(values, { setSubmitting }) => {
								CustomerService.updateCustomer(values)
									.then(response => {
										if(response.data) {
											const index = this.props.userList.findIndex(item => item.customer.psid === values.psid);
											if(index > -1) {
												var newUser = this.props.userList[index].customer;
												newUser.email = values.email;
												newUser.mobile = values.mobile;
												newUser.address = values.address;
												newUser.gender = values.gender;
												this.props.updateUserList(newUser, index);
											}
											const index_comment = this.props.userListComment.reduce(function(a, e, i) {
												if (e.customer.psid === values.psid)
													a.push(i);
												return a;
											}, []);

											if(index_comment.length > 1) {
												index_comment.forEach(index => {
													var newUserComment = this.props.userListComment[index].customer;
													newUserComment.email = values.email;
													newUserComment.mobile = values.mobile;
													newUserComment.address = values.address;
													newUserComment.gender = values.gender;
													this.props.updateUserListComment(newUserComment, index);
												});
											}
											toast.success("😀 Customer information was updated!", {
												autoClose: 2000,
												position: 'bottom-right',
												className: 'notification-message'
											});
											setSubmitting(false);
										}
									})
									.catch(err => {
										setSubmitting(false);
									});
							}}
						>
							{({
								values,
								touched,
								errors,
								isSubmitting,
								handleChange,
								handleBlur,
								handleSubmit
							}) => (
								<Form onSubmit={handleSubmit} className="login-form">
									<FormControl type="hidden" name="psid" value={values.psid} onChange={handleChange} onBlur={handleBlur} />
									<FormControl type="hidden" name="platform" value={values.platform} onChange={handleChange} onBlur={handleBlur} />
									<FormGroup>
										<FormControl type="text" name="mobile" placeholder="11 digit mobile number" value={values.mobile} onChange={handleChange} onBlur={handleBlur} className={
											errors.mobile && touched.mobile ? 'error' : ''
										} />
										{errors.mobile && touched.mobile && <div className="input-feedback">{errors.mobile}</div>}
									</FormGroup>
									<FormGroup>
										<FormControl type="text" name="email" placeholder="Email address" value={values.email} onChange={handleChange} onBlur={handleBlur} className={
											errors.email && touched.email ? 'error' : ''
										} />
										{errors.email && touched.email && <div className="input-feedback">{errors.email}</div>}
									</FormGroup>
									<FormGroup>
										<FormControl type="text" name="address" placeholder="Address" value={values.address} onChange={handleChange} className={
											errors.address && touched.address ? 'error' : ''
										} />
										{errors.address && touched.address && <div className="input-feedback">{errors.address}</div>}
									</FormGroup>
									<FormGroup>
										<ControlLabel className="radio">Male <FormControl type="radio" name="gender" value='male' checked={values.gender === 'male' ? true: false} onChange={handleChange} className={
											errors.gender && touched.gender ? 'error' : ''
										} /><span className="checkround" /></ControlLabel>
										<ControlLabel className="radio">Female <FormControl type="radio" name="gender" value='female' checked={values.gender === 'female' ? true: false} onChange={handleChange} className={
											errors.gender && touched.gender ? 'error' : ''
										} /><span className="checkround" /></ControlLabel>
										{errors.gender && touched.gender && <div className="input-feedback">{errors.gender}</div>}
									</FormGroup>
									<div className="form-check text-center">
										<button type="submit" disabled={isSubmitting} className="btn btn-login float-right">Update Customer Info</button>
									</div>
								</Form>
							)}
							</Formik>
					</div>
				</div> : null}
			</>
		);
	}
};

const mapStateToProps = state => {
	return {
		userList: state.message_state.userList,
		userListComment: state.comment_state.userList,
		currentUser: state.inbox_state.currentUser,
		currentComment: state.comment_state.currentComment,
		currentiComment: state.instagram_state.currentComment,
		inbox_link: state.message_state.inbox_link,
		csrf: state.site_state.csrf,
		bot_enabled: state.inbox_state.bot_enabled
  };
}

const mapActionToProps = dispatch => {
    return {
		updateUserList: (user, index) => dispatch(actions.updateUserList(user, index)),
		updateUserListComment: (user, index) => dispatch(actions.updateUserListComment(user, index))
	};
};

export default withRouter(connect(mapStateToProps, mapActionToProps)(Profile));