import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import loader from '../../../../assets/img/loader.svg';
import 'moment-timezone';
import 'react-image-lightbox/style.css';
import { toast } from 'react-toastify';
import io from "socket.io-client";
import avatar from '../../../../assets/img/avatars/avatar1.png';
// import logo from '../../../../assets/img/logo.png';
import instruction from '../../../../assets/img/instruction.png';
import { replyInstagram, hidesComment, deletesComment } from '../../../../services/InstagramService';
import * as actions from '../../../../store/actions/inbox_actions';
import { deleteFixedReply } from '../../../../services/ProjectService';
import { getUserData } from '../../../../services/UserService';
import ReactImageFallback from "react-image-fallback";
import spinner from '../../../../assets/img/login_spinner.gif';
import nophoto from '../../../../assets/img/nophoto.png';

class Conversation extends Component {
	constructor(props) {
		super(props);
		this.adjustScreen = this.adjustScreen.bind(this);
		this.state = {
			fixedReplyShown: false,
			replyTextDisabled: false,
			replyValue: null,
			submitting: false,
			canPublish: true,
			fixed_replies: []
		};
		this.socket = io(process.env.REACT_APP_SERVER_URL, {
			transports: ['websocket']
		});
	}

	offset = (el) => {
		var rect = el.getBoundingClientRect(),
			scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
			scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		return {
			top: rect.top + scrollTop,
			left: rect.left + scrollLeft
		}
	}

	adjustScreen = () => {
		if (document.querySelectorAll('.facebook-post-content')[0]) document.querySelectorAll('.facebook-post-content')[0].style.height = (window.innerHeight - 90 - this.offset(document.getElementsByClassName("facebook-post-content")[0]).top) + "px";
	}

	verticalCenterInstruction = () => {
		if(document.querySelectorAll('.instruction-comment')[0]) {
			var windowHeight = document.querySelectorAll('.facebook-post-content')[0].clientHeight;
			var instructionBoxHeight = document.querySelectorAll('.instruction-comment')[0].offsetHeight;
			var mathLogin = (windowHeight / 2) - (instructionBoxHeight / 2);
			document.querySelectorAll('.instruction-comment')[0].style.marginTop = mathLogin + "px";
		}
	}

	sendTyping = () => {
		if(this.state.canPublish) {
			this.socket.emit('is_typing', {
				status: true,
				type: 'instagram',
				id: this.props.currentiComment._id,
				userid: getUserData().userId,
				username: getUserData().username
			});
			this.setState({
				canPublish: false
			});
			setTimeout(function() {
				this.setState({
					canPublish: true
				});
			}.bind(this), 2000);
		}
	}

	removeTyping = () => {
		this.setState({
			canPublish: true
		});
		this.socket.emit('is_typing', {
			status: false,
			type: 'instagram',
			id: this.props.currentiComment._id,
			userid: getUserData().userId,
			username: getUserData().username
		});
	}

	searchFixedReplies = (event) => {
		const self = this;
		self.setState({
		   fixed_replies: this.props.fixed_replies.filter(r => r.toLowerCase().includes(event.target.value.toLowerCase()))
		});
	}

	componentDidMount() {
		this.verticalCenterInstruction();
		this.adjustScreen();
		window.addEventListener("resize", this.adjustScreen);
		window.addEventListener("resize", this.verticalCenterInstruction);
		
		this.socket.on("is_typing", data => {
			if(data.status) { // typing
				if(data.type === 'instagram' && data.userid !== getUserData().userId && this.props.currentiComment._id === data.id) {
					if(document.getElementById('reply-conversation-icomment')) document.getElementById('reply-conversation-icomment').value = data.username + ' is typing...';
					this.setState({
						replyTextDisabled: true
					});
				}
			} else { // typing close
				if(data.type === 'instagram' && data.userid !== getUserData().userId && this.props.currentiComment._id === data.id) {
					if(document.getElementById('reply-conversation-icomment')) document.getElementById('reply-conversation-icomment').value = '';
					this.setState({
						replyTextDisabled: false
					});
				}
			}
		});
	}

	componentDidUpdate() {
		this.verticalCenterInstruction();
		this.adjustScreen();
	}

	componentWillUnmount() {
		window.removeEventListener("resize",  this.adjustScreen);
		window.removeEventListener("resize",  this.verticalCenterInstruction);
		this.socket.close();
	}

	openFixedReplies = () => {
		this.setState({
			fixed_replies: this.props.fixed_replies,

		});
		document.getElementById("fixed_search").value = '';

		if(this.state.replyImage !== 'has') {
			this.setState({
				fixedReplyShown: !this.state.fixedReplyShown
			});
		}
	}

	fillUpReplyWithReplies = (reply) => {
		if(document.getElementById('reply-conversation-icomment')) document.getElementById('reply-conversation-icomment').value = document.getElementById('reply-conversation-icomment').value + ' ' + reply;
		this.setState({
			fixedReplyShown: !this.state.fixedReplyShown,
			replyValue: reply
		});
		if(document.getElementById('reply-conversation-icomment')) document.getElementById('reply-conversation-icomment').focus();
	}

	getReplyInput = (event) => {
		this.sendTyping();
		if(event.target.value) {
			this.setState({
				replyValue: event.target.value
			});
		} else {
			this.setState({
				replyValue: ''
			});
		}
	}

	submitReply = () => {
			this.setState({
				submitting: true
			});

			let formData = new FormData();
			formData.append('project_id', this.props.match.params.project_id);
			formData.append('_csrf', this.props.csrf);
			if(document.getElementById("reply-conversation-icomment")) formData.append('message', document.getElementById("reply-conversation-icomment").value);

			formData.append('comment_id', this.props.currentiComment.comment_id);

			replyInstagram(formData)
				.then(response => {
					if(response.data) {
						this.setState({
							replyTextDisabled: false,
							replyValue: '',
							submitting: false
						});
						if(document.getElementById('reply-conversation-icomment')) document.getElementById('reply-conversation-icomment').value = "";
						localStorage.removeItem(this.props.match.params.project_id + '_instagram_' + getUserData().userId + '_' + this.props.currentCommentId);
					}
				})
				.catch(err => {
					this.setState({
						submitting: false
					});
				});
	};

	hideComment = (comment_id, status, event) => {
		event.target.style.pointerEvents = 'none';
		hidesComment(this.props.match.params.project_id, comment_id, status, this.props.csrf)
				.then(response => {
					event.target.style.pointerEvents = 'auto';
					if(response.data) {
						if(status) {
							toast.success("😀 Comment was hidden successfully!", {
								autoClose: 5000,
								position: 'bottom-right',
								className: 'notification-message'
							});
						} else {
							toast.success("😀 Comment was marked as shown successfully!", {
								autoClose: 5000,
								position: 'bottom-right',
								className: 'notification-message'
							});
						}
					}
				})
				.catch(err => {
					//event.target.style.pointerEvents = 'auto';
				});
	}

	deleteComment = (comment_id, event) => {
		event.target.style.pointerEvents = 'none';
		deletesComment(this.props.match.params.project_id, comment_id, this.props.csrf)
				.then(response => {
					if(response.data) {
						toast.success("😀 Comment was deleted successfully!", {
							autoClose: 5000,
							position: 'bottom-right',
							className: 'notification-message'
						});
					}
				})
				.catch(err => {
					event.target.style.pointerEvents = 'auto';
				});
	}

	removeTag = (comment_id, tag) => {
		this.props.removeTags(comment_id, tag, this.props.csrf);
	}

	deleteReply = (project_id, reply) => {
		deleteFixedReply(project_id, reply)
		.then(response => {
			if(response.data) {
				toast.success("😀 Fixed reply was deleted successfully!", {
					autoClose: 2000,
					position: 'bottom-right',
					className: 'notification-message'
				});
				this.props.fixedReplies(this.props.match.params.project_id);
			}
		})
		.catch(err => {})
	}

	saveDraft = () => {
		localStorage.setItem(this.props.match.params.project_id + '_instagram_' + getUserData().userId + '_' + this.props.currentCommentId, document.getElementById('reply-conversation-icomment') ? document.getElementById('reply-conversation-icomment').value: null);
		toast.success("😀 Draft saved successfully!", {
			autoClose: 2000,
			position: 'bottom-right',
			className: 'notification-message'
		});
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.conversationLoading) {
			this.setState({
				replyValue: null
			});
		}
	}

	render() {
		const style1 = {
			paddingRight: '0px'
		};
		const style2 = {
			paddingLeft: '0px',
			paddingRight: '20px'
		};
		let quick_chat_show_status;
		if(this.state.fixedReplyShown && this.props.fixed_replies.length > 0) quick_chat_show_status= ['quick-chat-item'];
		else quick_chat_show_status= ['quick-chat-item hide'];

		return (
			<>
				<div className="facebook-post-content">
				{this.props.currentUser.hasOwnProperty('username') ? 
				this.props.conversationLoading ? <div style={{width: '100%', paddingTop: '50px', textAlign: 'center'}}><img src={loader} alt="loading..." /></div> : 
					<Scrollbars style={{ height: '100%' }}>
						<div className="facebook-post-content-inner" id="conversation_icomment">
							{ this.props.conversations.length > 0 ? <div className="facebook-post-comment">
								<div className="facebook-card-content">
									<div className="facebook-card-comments">
										<div className="facebook-card-users-comments">
											<div className="row">
												{this.props.conversations.sort(function(a,b){
													return new Date(a.time_stamp) - new Date(b.time_stamp);
												}).map((comment, index) => (comment.sender_is === 'user') ? 
													<div key={comment._id} className="main-comment">
														<div className="col-sm-1" style={style1}>
															<a className="facebook-card-user-image" href="#/">
																<ReactImageFallback className="facebook-card-user-image" src={comment.customer.profile_pic} alt={comment.customer.username} fallbackImage={nophoto} initialImage={spinner} />
															</a>
														</div>
														<div className="col-sm-11" style={style2}>
														<div className="facebook-comment">
																<div className="comment-tools" id={comment._id}>
																	{ comment.deleted === 'false' ? 
																	comment.hidden === 'true' ? <><span className="tool comment-hide" title="Unhide Comment" onClick={(event) => this.hideComment(comment.comment_id, false, event)}>
																		<i className="fa fa-eye"></i>
																	</span> <span className="tool comment-delete" title="Delete Comment" onClick={(event) => this.deleteComment(comment.comment_id, event)}>
																		<i className="fa fa-trash-o"></i>
																	</span></> : <><span className="tool comment-hide" title="Hide Comment" onClick={(event) => this.hideComment(comment.comment_id, true, event)}>
																		<i className="fa fa-eye"></i>
																	</span> <span className="tool comment-delete" title="Delete Comment" onClick={(event) => this.deleteComment(comment.comment_id, event)}>
																		<i className="fa fa-trash-o"></i>
																	</span></> : null }
																	<span className="tool comment-tag" title="Add Tag" onClick={this.props.selectiComment.bind(this, comment._id)}>
																		<i className="fa fa-tags"></i>
																	</span>
																</div>
																{ comment.hidden === 'true' && comment.deleted === 'true' ? <div id={comment._id} className="hiddenComment deleted">
																	<a className="facebook-card-content-user" href="#/">{comment.customer.name}</a>
																	{comment.text_content}
																</div> : comment.hidden === 'true' ? <div id={comment._id} className="hiddenComment">
																	<a className="facebook-card-content-user" href="#/">{comment.customer.name}</a>
																	{comment.text_content}
																</div> : comment.deleted === 'true' ? <div id={comment._id} className="deleted">
																	<a className="facebook-card-content-user" href="#/">{comment.customer.name}</a>
																	{comment.text_content}
																</div> : <div id={comment._id}>
																	<a className="facebook-card-content-user" href="#/">{comment.customer.name}</a>
																	{comment.text_content}
																</div> }
															</div>
															<div className="reaction-buttons">
																<a className="facebook-card-comment-date" href="#/"><Moment fromNow tz="Asia/Dhaka" date={comment.time_stamp} /></a> {comment.hidden === 'true' && comment.hidden_by && comment.hidden_by.username ? <>- Hidden by {comment.hidden_by.username}</> : null} {comment.resolved_status === 'resolved' && comment.resolved_by && comment.resolved_by.username ? <>- Resolved by {comment.resolved_by.username}</> : null}
															</div>
															{comment.tags ? 
															<div className="comment-tags">
																{comment.tags.map((tag, index) => <span key={index} onClick={this.removeTag.bind(this, comment._id, tag.title)}>{tag.title} <i className="fa fa-times"></i></span>)}
															</div> : null}
														</div>
													</div> : <div key={comment._id} className="page reply-comment">
														<div className="col-sm-1" style={style1}>
															<a className="facebook-card-user-image" href="#/">
																<ReactImageFallback className="facebook-card-user-image" src={this.props.project_image ? this.props.project_image : avatar} alt={this.props.project_title ? this.props.project_title : 'listenyzen'} title="listenyzen" fallbackImage={nophoto} initialImage={spinner} />
															</a>
														</div>
														<div className="col-sm-11" style={style2}>
															<div className="facebook-comment">
																<div>
																	<a className="facebook-card-content-user" href="#/">{this.props.project_title ? this.props.project_title : 'listenyzen'}</a>
																	{comment.text_content}
																</div>
															</div>
															<div className="reaction-buttons">
																<a className="facebook-card-comment-date" href="#/"><Moment fromNow tz="Asia/Dhaka" date={comment.time_stamp} /></a> {comment.answered_by ? '- Replied by ' + comment.answered_by.username : null}
															</div>
														</div>
													</div>) }
											</div>
										</div>
									</div>
								</div>
							</div> : null }
						</div>
					</Scrollbars> : <div className="instruction-container">
							<div className="instruction-comment text-center">
									<h1>Welcome to Inbox</h1>
									{/* <img className="logo img-responsive" src={logo} alt="listenyzen Logo" /> */}
									<p>
											Click any user from left bar to view the conversation.
									</p>
									<img className="instruction-image img-responsive" src={instruction} alt="Instruction" />
							</div>
					</div>}
				</div>
				{this.props.currentUser.hasOwnProperty('username') && !this.props.conversationLoading  ?
					<div className="row reply">
						<div className="col-sm-9 reply-main">
							<form className="text-bar-field">
								<textarea id="reply-conversation-icomment" disabled={this.state.replyTextDisabled} placeholder="Write your comment here..." onChange={(event) => this.getReplyInput(event)} onBlur={this.removeTyping} value={this.state.replyValue !== null ? this.state.replyValue : localStorage.getItem(this.props.match.params.project_id + '_instagram_' + getUserData().userId + '_' + this.props.currentCommentId) ? localStorage.getItem(this.props.match.params.project_id + '_instagram_' + getUserData().userId + '_' + this.props.currentCommentId) : this.props.messageSuggestions && this.props.messageSuggestions.length > 0 ? this.props.messageSuggestions[0].replace('{user}', this.props.currentUser.username) : ''}></textarea>
							</form>
						</div>
						<div className="col-sm-3 reply-tools text-right">
								{ this.props.fixed_replies.length > 0 ? <span className="quick-chat">
										<i className="fa fa-list-alt" title="Fixed Replies" onClick={this.openFixedReplies}></i>
									</span> : null }

								{this.state.replyValue !== '' && this.state.replyValue !== null ? <div className="reply-draft">
									<i className="fa fa-floppy-o" title="Save as Draft" onClick={this.saveDraft}></i>
								</div> : null}

								<div className="reply-send">
									{this.state.replyValue !== '' && this.state.replyValue !== null && !this.state.submitting ? <i title="Send Reply" className="fa fa-send" onClick={this.submitReply}></i> : null }
									{this.state.submitting ? <img src={loader} alt="Loader" /> : null}
								</div>
								<Scrollbars>
									<div className={quick_chat_show_status}>
											<div>
												<div class="form-group has-search">
													<div class="glyphicon glyphicon-search form-control-feedback"></div>
													<input type="text" id="fixed_search" class="form-control" placeholder="Search" onChange={this.searchFixedReplies} />
												</div>
											</div>

											<ul className="list-group">
												{ this.state.fixed_replies.map((reply, index) => <li key={index} className="list-group-item">
													<div className="row">
														<div className="col-sm-10" style={{padding: '0 0 0 15px'}} onClick={this.fillUpReplyWithReplies.bind(this, reply.replace("{user}", this.props.currentUser.username))}>{reply.replace("{user}", this.props.currentUser.username)}</div>
														<div className="col-sm-2 text-right" style={{padding: '0 13px 0 0'}}><i className="fa fa-pencil-square-o" style={{cursor: 'pointer'}} onClick={this.props.modal.bind(this, 'add_efr_modal', reply)}></i><i className="fa fa-times" style={{marginLeft: '10px', cursor: 'pointer'}} onClick={this.deleteReply.bind(this, this.props.match.params.project_id, reply)}></i></div>
													</div>
													</li>)}
											</ul>
									</div>
								</Scrollbars>
						</div>
					</div> : <div className="row reply-placeholder"></div>}
			</>
		);
	}
};

const mapStateToProps = state => {
	return {
		conversations: state.instagram_state.currentMessages,
		conversationLoading: state.inbox_state.conversationLoading,
		currentUser: state.inbox_state.currentUser,
		messageSuggestions: state.instagram_state.messageSuggestions,
		project_title: state.inbox_state.project_title,
		project_image: state.inbox_state.project_image,
		fixed_replies: state.inbox_state.fixed_replies,
		currentiComment: state.instagram_state.currentComment,
		currentCommentId: state.instagram_state.currentCommentId,
		csrf: state.site_state.csrf
  };
}

const mapActionToProps = dispatch => {
    return {
			selectiComment: (id) => dispatch(actions.selectiComment(id)),
			removeTags: (comment_id, tag, csrf) => dispatch(actions.removeiCommentTag(comment_id, tag, csrf)),
			fixedReplies: (project_id) => dispatch(actions.fixedReplies(project_id))
		};
};

export default withRouter(connect(mapStateToProps, mapActionToProps)(Conversation));