import React, { Component } from 'react';
import { FacebookProvider, Login } from 'react-facebook';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as UserService from '../../services/UserService';

class FbLoginButton extends Component {
	state = {
		loading: false
	};

	handleResponse = (data) => {
		this.setState({
			loading: true
		});
		if(document.getElementById('fb-connect')) document.getElementById('fb-connect').style.pointerEvents = 'none';

		UserService.checkLogin(data.tokenDetail.userID, data.tokenDetail.accessToken)
		.then(response => {
			this.setState({
				loading: false
			});
			
			if(response.data.existing_user) {
				if(response.data.existing_user === 'yes') { // existing users
					if(response.data.user.status === 'active') {
						localStorage.setItem('userData', JSON.stringify({
							role: response.data.user.role
						}));
						window.location.replace(process.env.REACT_APP_CLIENT_URL + '/projects');
					} else {
						toast.warning("😒 Your account is currently pending approval. Once it gets approved, you will get an email confirmation and then you can login.", {
							autoClose: false,
							position: 'bottom-right',
							className: 'notification-message'
						});
					}
				} else if(response.data.existing_user === 'no') { // new user
					this.props.history.push({
						pathname: '/signup/' + response.data.facebook_user_id,
						state: { user: response.data }
					});
				} else {
					toast.error("😪 Sorry! We could not log you in. Please try again later.", {
						autoClose: 5000,
						position: 'bottom-right',
						className: 'notification-message'
					});
				}
			}
		})
		.catch(err => {});
	}
	
	handleError = (error) => {
		toast.error("😪 Sorry that you could not login!", {
			autoClose: 5000,
			position: 'bottom-right',
			className: 'notification-message'
		});
	}

	componentDidMount() {
		if(this.props.location.state) {
			if(this.props.location.state.signup) {
				if(this.props.location.state.signup === 'successful') {
					toast.success("😀 You have signed up successfully! But your account is pending approval. Once it gets approved, you will get an email confirmation and then you can login.", {
						autoClose: false,
						position: 'bottom-right',
						className: 'notification-message'
					});
					// this.handleResponse(this.props.location.state.fb_data);
					// toast.success("😀 You have signed up successfully! Now we are logging you in...", {
					// 	autoClose: false,
					// 	position: 'bottom-right',
					// 	className: 'notification-message'
					// });
				}
			}
		}
	}

	render() {
		return (
			<FacebookProvider appId={process.env.REACT_APP_FB_APP_ID} version={process.env.REACT_APP_FB_VERSION}>
				<Login
					scope="public_profile,email"
					onCompleted={this.handleResponse}
					onError={this.handleError}
				>
				{({ loading, handleClick, error, data }) => (
					<div id="fb-connect" className="facebook-connect-btn" onClick={handleClick}>
						<button type="text" disabled={this.state.loading || loading}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 216" className="fb_svg" color="#FFFFFF">
								<path fill="#FFFFFF" d="
								M204.1 0H11.9C5.3 0 0 5.3 0 11.9v192.2c0 6.6 5.3 11.9 11.9
								11.9h103.5v-83.6H87.2V99.8h28.1v-24c0-27.9 17-43.1 41.9-43.1
								11.9 0 22.2.9 25.2 1.3v29.2h-17.3c-13.5 0-16.2 6.4-16.2
								15.9v20.8h32.3l-4.2 32.6h-28V216h55c6.6 0 11.9-5.3
								11.9-11.9V11.9C216 5.3 210.7 0 204.1 0z"></path>
							</svg>
							{(this.state.loading || loading) ? <span>Logging in...</span> : <span>Continue with Facebook</span>}
						</button>
					</div>
				)}
				</Login>
			</FacebookProvider>
		);
	}
}

export default withRouter(FbLoginButton);