import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import Wrapper from '../../components/Wrapper/Wrapper';
import AddProject from '../../components/AddProject/AddProject';
import Project from '../../components/Project/Project';
import AddProjectModal from '../../components/Modals/AddProject/AddProject';
import FbPageListModal from '../../components/Modals/FbPageList/FbPageList';
import InstaListModal from '../../components/Modals/InstaList/InstaList';
import QmListModal from '../../components/Modals/QmList/QmList';
import PmListModal from '../../components/Modals/PmList/PmList';
import FixedReplyModal from '../../components/Modals/FixedReply/FixedReply';
import ViberModal from '../../components/Modals/ViberModal/ViberModal';
import TrialAlert from '../../components/TrialAlert/TrialAlert';
import { getProjects, connectPage, disconnectPage } from '../../services/ProjectService';
import { getUserData, checkTrial } from '../../services/UserService';

class Projects extends Component {
	state = {
		add_project_modal_show: false,
		fb_page_list_modal_show: false,
		insta_list_modal_show: false,
		fr_modal_show: false,
		qm_list_modal_show: false,
		pm_list_modal_show: false,
		viber_modal_show: false,
		project_id: '',
		projects: [],
		pages: [],
		user_access_token: '',
		assigned_query_managers: [],
		assigned_project_managers: [],
		insta_username: '',
		total_unresolved_count: 0,
		trial: null,
		ends: '',
		billing: null
	};

	showHideModal = (modal, project_id = null, pages = [], viber_subscribed = {}, user_access_token = null, insta_username = null) => {
		if(modal === 'add_project_modal') {
			if(project_id && project_id.hasOwnProperty('name')) {
				project_id.unresolved_count = 0;
				project_id.id = project_id._id;
				project_id.facebook = 'disconnected';
				// project_id.instagram = 'disconnected';
				project_id.viber = 'disconnected';
				this.setState({
					...this.state,
					projects: [project_id, ...this.state.projects]
				});
			}
			this.setState((prevState) => ({ ...prevState, add_project_modal_show: !prevState.add_project_modal_show }));
		}
		if(modal === 'fb_page_list_modal') {
			if(this.state.fb_page_list_modal_show) {
				this.setState((prevState) => ({ 
					...prevState, 
					fb_page_list_modal_show: !prevState.fb_page_list_modal_show,
					project_id: project_id,
					pages: [],
					user_access_token: ''
				}));
			} else {
				if(pages.length > 0){
					let projects = [...this.state.projects];
					projects = projects.filter(project => project.facebook);
					let subscribed_projects = [];
					let page_id_with_project_id = [];
					projects.forEach(project => {
						subscribed_projects.push(project.facebook);
						page_id_with_project_id[project.facebook] = project.id;
					});
					pages.forEach(page => {
						if(subscribed_projects.includes(page.id)) {
							page.subscribed = true;
						} else {
							page.subscribed = false;
						}
						page.project_id = page_id_with_project_id[page.id];
					});
				} else {
					pages.forEach(page => {
						page.subscribed = false;
					});
				}
				this.setState((prevState) => ({ 
					...prevState, 
					fb_page_list_modal_show: !prevState.fb_page_list_modal_show,
					project_id: project_id,
					pages: prevState.pages.concat(pages),
					user_access_token: user_access_token
				}));
			}
		}
		if(modal === 'insta_list_modal') {
			this.setState((prevState) => {
				return {
					...prevState,
					insta_list_modal_show: !prevState.insta_list_modal_show,
					insta_username: insta_username
				}
			});
		}
		if(modal === 'fr_modal') {
			this.setState((prevState) => {
				return {
					...prevState,
					fr_modal_show: !prevState.fr_modal_show,
					project_id: project_id 
				}
			});
		}
		if(modal === 'qm_list_modal') {
			this.setState((prevState) => {
				return {
					...prevState,
					qm_list_modal_show: !prevState.qm_list_modal_show,
					project_id: project_id 
				}
			});
		}
		if(modal === 'pm_list_modal') {
			this.setState((prevState) => {
				return {
					...prevState,
					pm_list_modal_show: !prevState.pm_list_modal_show,
					project_id: project_id 
				}
			});
		}
		if(modal === 'viber_modal') {
			if(viber_subscribed.hasOwnProperty('access_token')) {
				let projects = [...this.state.projects];
				let projectIndex = projects.findIndex((project) => project.id === project_id);
				projects[projectIndex].viber = viber_subscribed;
				this.setState((prevState) => {
					return {
						...prevState,
						viber_modal_show: !prevState.viber_modal_show,
						projects: projects,
						project_id: project_id 
					}
				});
			} else {
				this.setState((prevState) => {
					return {
						...prevState,
						viber_modal_show: !prevState.viber_modal_show,
						project_id: project_id 
					}
				});
			}
		}
	}

	subscribe = (page_id, project_id, user_access_token) => {
		let pages = [...this.state.pages];
		let pageIndex = pages.findIndex((page) => page.id === page_id);

		connectPage(page_id, project_id, pages[pageIndex].name, pages[pageIndex].access_token, user_access_token)
		.then(response => {
			pages[pageIndex].subscribed = true;
			let projects = [...this.state.projects];
			let projectIndex = projects.findIndex((project) => project.id === project_id);
			projects[projectIndex].facebook = 'connected';
			this.setState({
				...this.state,
				pages: pages,
				projects: projects
			});
		})
		.catch(err => {});
	}

	unsubscribe = (page_id, project_id) => {
		let pages = [...this.state.pages];
		let pageIndex = pages.findIndex((page) => page.id === page_id);

		disconnectPage(page_id, project_id, pages[pageIndex].access_token)
		.then(response => {
			pages[pageIndex].subscribed = false;
			let projects = [...this.state.projects];
			let projectIndex = projects.findIndex((project) => project.id === project_id);
			projects[projectIndex].facebook = 'disconnected';
			this.setState({
				...this.state,
				pages: pages,
				projects: projects
			});
		})
		.catch(err => {});
	}

	subscribe_insta = (project_id) => {
		let projects = [...this.state.projects];
		let projectIndex = projects.findIndex((project) => project.id === project_id);
		projects[projectIndex].instagram = 'connected';
		this.setState({
			...this.state,
			projects: projects
		});
	}

	urlB64ToUint8Array = (base64String) => {
		const padding = '='.repeat((4 - base64String.length % 4) % 4);
		const base64 = (base64String + padding)
			.replace(/-/g, '+')
			.replace(/_/g, '/');
	
		const rawData = window.atob(base64);
		const outputArray = new Uint8Array(rawData.length);
	
		for (let i = 0; i < rawData.length; ++i) {
			outputArray[i] = rawData.charCodeAt(i);
		}
		return outputArray;
	}

	componentDidMount() {
		document.title = 'Projects - listenyzen';
		document.body.classList.add('main-body');

		if(getUserData().role === 'query_manager' || getUserData().role === 'admin') {
			getProjects()
				.then(response => {
					this.setState({
						...this.state,
						projects: this.state.projects.concat(response.data.data),
						total_unresolved_count: response.data.total_unresolved_count,
						trial: null,
						ends: null
					});
				})
				.catch(err => {});
		} else {
			checkTrial()
				.then(trial => {
					getProjects()
						.then(response => {
							this.setState({
								...this.state,
								projects: this.state.projects.concat(response.data.data),
								total_unresolved_count: response.data.total_unresolved_count,
								trial: trial.data.trial === true ? true : trial.data.trial === false ? false : null,
								ends: trial.data.ends ? trial.data.ends : null,
								billing: trial.data.billing ? trial.data.billing : null
							});
						})
						.catch(err => {});
				})
				.catch(err => {});
		}
	}

	componentWillUnmount() {
		document.body.classList.remove('main-body');
		if(this.state.trial === false) {
			localStorage.setItem('showTrialAlert', false);
		}
	}

	render() {
		return (
			<div className="Projects">
				<Header />
				<Wrapper>
					<div className="company-dashboard-content">
						<div className="container-fluid">
							<div className="row">
								{ getUserData().role === 'company' ? 
								<TrialAlert trial={this.state.trial} ends={this.state.ends} billing={this.state.billing} /> : null }
								{/* only admin */}
								{ getUserData().role === 'admin' || getUserData().role === 'company' ? 
								<><AddProject trial={this.state.trial} billing={this.state.billing} modal={this.showHideModal} total_unresolved_count={this.state.total_unresolved_count} total_projects={this.state.projects.length} />
								<AddProjectModal show={this.state.add_project_modal_show} manage={this.showHideModal} />
								<FbPageListModal pages={this.state.pages} user_access_token={this.state.user_access_token} project_id={this.state.project_id} show={this.state.fb_page_list_modal_show} manage={this.showHideModal} subscribe={this.subscribe} unsubscribe={this.unsubscribe} />
								<InstaListModal insta_username={this.state.insta_username} show={this.state.insta_list_modal_show} manage={this.showHideModal} />
								<ViberModal project_id={this.state.project_id} show={this.state.viber_modal_show} manage={this.showHideModal} />
								<PmListModal show={this.state.pm_list_modal_show} project_id={this.state.project_id} manage={this.showHideModal} />
								<QmListModal show={this.state.qm_list_modal_show} project_id={this.state.project_id} manage={this.showHideModal} />
								</> : null }
								
								
								{/* all */}
								{ this.state.projects.map(project => <Project key={project.id} modal={this.showHideModal} fb_connected={project.facebook} viber_connected={project.viber} instagram_connected={project.instagram} project_id={project.id} name={project.name} photo={project.photo} unresolved={project.unresolved_count} auto_count={project.messenger_auto_count} email={project.email} insta_subscribe={this.subscribe_insta} intents={project.intents} entities={project.entities} messenger_unresolved_count={project.messenger_unresolved_count} instagram_unresolved_count={project.instagram_unresolved_count} fb_comment_unresolved_count={project.fb_comment_unresolved_count} viber_unresolved_count={project.viber_unresolved_count} />) }
								<FixedReplyModal show={this.state.fr_modal_show} project_id={this.state.project_id} manage={this.showHideModal} />
							</div>
						</div>
					</div>
				</Wrapper>
			</div>
		);
	}
};

export default Projects;