import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import Waypoint from 'react-waypoint';
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';
import 'moment-timezone';
import io from "socket.io-client";
import { toast } from 'react-toastify';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import loader from '../../../../assets/img/loader.svg';
// import logo from '../../../../assets/img/logo.png';
import instruction from '../../../../assets/img/instruction.png';
import * as actions from '../../../../store/actions/inbox_actions';
import { replyViber } from '../../../../services/ViberService';
import { deleteFixedReply } from '../../../../services/ProjectService';
import { getUserData } from '../../../../services/UserService';

class Conversation extends Component {
	constructor(props) {
		super(props);
		this.adjustScreen = this.adjustScreen.bind(this);
		this.state = {
			imagePopup: '',
			isOpen: false,
			fixedReplyShown: false,
			fileDisabled: false,
			replyTextDisabled: false,
			replyValue: '',
			replyImage: '',
			submitting: false,
			canPublish: true,
			emojiShown: false,
			fixed_replies: []
		};
		this.socket = io(process.env.REACT_APP_SERVER_URL, {
			transports: ['websocket']
		});
	}

	offset = (el) => {
		var rect = el.getBoundingClientRect(),
			scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
			scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		return {
			top: rect.top + scrollTop,
			left: rect.left + scrollLeft
		}
	}

	adjustScreen = () => {
		if (document.querySelectorAll('.screen')[0]) document.querySelectorAll('.screen')[0].style.height = (window.innerHeight - 90 - this.offset(document.getElementsByClassName("screen")[0]).top) + "px";
	}

	verticalCenterInstruction = () => {
		if(document.querySelectorAll('.instruction')[0]) {
			var windowHeight = document.querySelectorAll('.screen')[0].clientHeight;
			var instructionBoxHeight = document.querySelectorAll('.instruction')[0].offsetHeight;
			var mathLogin = (windowHeight / 2) - (instructionBoxHeight / 2);
			document.querySelectorAll('.instruction')[0].style.marginTop = mathLogin + "px";
		}
	}

	sendTyping = () => {
		if(this.state.canPublish) {
			this.socket.emit('is_typing', {
				status: true,
				type: 'viber',
				id: this.props.currentMessage._id,
				userid: getUserData().userId,
				username: getUserData().username
			});
			this.setState({
				canPublish: false
			});
			setTimeout(function() {
				this.setState({
					canPublish: true
				});
			}.bind(this), 2000);
		}
	}

	removeTyping = () => {
		this.setState({
			canPublish: true
		});
		this.socket.emit('is_typing', {
			status: false,
			type: 'viber',
			id: this.props.currentMessage._id,
			userid: getUserData().userId,
			username: getUserData().username
		});
	}

	searchFixedReplies = (event) => {
		const self = this;
		self.setState({
		   fixed_replies: this.props.fixed_replies.filter(r => r.toLowerCase().includes(event.target.value.toLowerCase()))
		});
	}


	componentDidMount() {
		this.verticalCenterInstruction();
		this.adjustScreen();
		window.addEventListener("resize", this.adjustScreen);
		window.addEventListener("resize", this.verticalCenterInstruction);

		this.socket.on("is_typing", data => {
			if(data.status) { // typing
				if(data.type === 'viber' && data.userid !== getUserData().userId && this.props.currentMessage._id === data.id) {
					if(document.getElementById('reply-conversation-viber')) document.getElementById('reply-conversation-viber').value = data.username + ' is typing...';
					this.setState({
						replyTextDisabled: true
					});
				}
			} else { // typing close
				if(data.type === 'viber' && data.userid !== getUserData().userId && this.props.currentMessage._id === data.id) {
					if(document.getElementById('reply-conversation-viber')) document.getElementById('reply-conversation-viber').value = '';
					this.setState({
						replyTextDisabled: false
					});
				}
			}
		});
	}

	componentDidUpdate() {
		this.verticalCenterInstruction();
		this.adjustScreen();
	}

	componentWillUnmount() {
		window.removeEventListener("resize",  this.adjustScreen);
		window.removeEventListener("resize",  this.verticalCenterInstruction);
		this.socket.close();
	}

	openImagePopup = (image_url) => {
		this.setState({
			isOpen: true,
			imagePopup: image_url 
		});
	}

	parseAttachment = (attachments, text_content) => {
		let output = '';
		let images = [];

		for(let attachment of attachments) {
			if(attachment.type === 'sticker' || attachment.type === 'picture') {
				images.push(attachment.url);
			} else if(attachment.type === 'video') {
				output = (
					<>
						{text_content ? <div className="attachment-text">{text_content}</div> : null }
						<a rel="noopener noreferrer" href={attachment.url} target="_blank">Sent a Video</a>
					</>
				);
			} else if(attachment.type === 'file') {
				output = (
					<>
						{text_content ? <div className="attachment-text">{text_content}</div> : null }
						<a rel="noopener noreferrer" href={attachment.url} target="_blank">Sent a File</a>
					</>
				);
			} else if(attachment.type === 'location') {
				if(attachment.location_data && attachment.location_data.lat && attachment.location_data.lon) {
					var url = 'https://www.google.com/maps/search/?api=1&query=' + attachment.location_data.lat + ',' + attachment.location_data.lon;
					output = (
						<>
							{text_content ? <div className="attachment-text">{text_content}</div> : null }
							<a rel="noopener noreferrer" href={url} target="_blank">Sent a Location</a>
						</>
					);
				} else {
					output = (
						<>{text_content ? <div className="attachment-text">{text_content}</div> : null }
							Sent an attachment(unknown format)</>
					);
				}
			} else if(attachment.type === 'contact') {
				if(attachment.contact) {
					var contact_name = attachment.contact.name ? attachment.contact.name : 'Unknown';
					var contact_no = attachment.contact.phone_number ? ', ' + attachment.contact.phone_number : '';
					output = (
						<>{text_content ? <div className="attachment-text">{text_content}</div> : null }
						Sent a Contact [{contact_name}{contact_no}]</>
					);
				} else {
					output = (
						<>{text_content ? <div className="attachment-text">{text_content}</div> : null }
						Sent an attachment(unknown format)</>
					);
				}
			} else {
				output = (
					<>{text_content ? <div className="attachment-text">{text_content}</div> : null }
					Sent an attachment(unknown format)</>
				);
			}
		};

		if(images.length > 1) {
			output = (
				<ul className="gallery-image-items list-inline">
					{	images.map((image, index) => <li key={index} style={{backgroundImage: `url(${encodeURI(image)})`}} onClick={() => this.openImagePopup(image)} /> )}
				</ul>
			);
		}
		if(images.length === 1) {
			output = (
				<div className="single-image" style={{backgroundImage: `url(${encodeURI(images[0])})`}} onClick={() => this.openImagePopup(images[0])}></div>
			);
		}

		return output;
	}

	loadMoreMessages = () => {
		if(this.props.match.params.platform === 'viber') {
			if(this.props.conversations.length !== this.props.messageCount) {
				this.props.conversationMoreLoadingF();
				setTimeout(() => {
					if(document.getElementById('conversation_viber')) {
						document.getElementById('conversation_viber').style.pointerEvents = 'none';
						this.props.loadConversation(this.props.match.params.project_id, this.props.currentUser.id, this.props.skip, process.env.REACT_APP_MSG_PER_PAGE, document.getElementById('conversation_viber').scrollHeight + 20);
					}
				}, 500);
			}
		}
	}

	openFixedReplies = () => {
		this.setState({
			fixed_replies: this.props.fixed_replies,

		});
		document.getElementById("fixed_search").value = '';

		if(this.state.replyImage !== 'has') {
			this.setState({
				fixedReplyShown: !this.state.fixedReplyShown
			});
		}
	}

	fillUpReplyWithReplies = (reply) => {
		if(document.getElementById('reply-conversation-viber')) document.getElementById('reply-conversation-viber').value = document.getElementById('reply-conversation-viber').value + ' ' + reply;
		this.setState({
			fixedReplyShown: !this.state.fixedReplyShown,
			fileDisabled: true,
			replyValue: reply
		});
		if(document.getElementById('reply-conversation-viber')) document.getElementById('reply-conversation-viber').focus();
	}

	getReplyInput = (event) => {
		this.sendTyping();
		if(event.target.value) {
			this.setState({
				fileDisabled: true,
				replyValue: event.target.value
			});
		} else {
			this.setState({
				fileDisabled: false,
				replyValue: ''
			});
		}
	}

	clearImage = () => {
		if(document.getElementById("upload_viber_image")) document.getElementById("upload_viber_image").value = "";
		if(document.getElementById("file-uploaded-viber")) document.getElementById("file-uploaded-viber").src = '';
		this.setState({
			replyTextDisabled: false,
			replyImage: ''
		});
	}

	submitReply = () => {
			this.setState({
				submitting: true
			});
			let formData = new FormData();
			formData.append('project_id', this.props.match.params.project_id);
			formData.append('recepient', this.props.currentUser.id);
			formData.append('_csrf', this.props.csrf);
			if(this.state.replyImage === 'has') {
				if(document.getElementById("upload_viber_image")) formData.append('messageImage', document.getElementById("upload_viber_image").files[0]);
			} else {
				if(document.getElementById("reply-conversation-viber")) formData.append('message', document.getElementById("reply-conversation-viber").value);
			}

			replyViber(formData)
				.then(response => {
					if(response.data) {
						this.setState({
							fileDisabled: false,
							replyTextDisabled: false,
							replyValue: '',
							replyImage: '',
							submitting: false
						});
						if(document.getElementById('reply-conversation-viber')) document.getElementById('reply-conversation-viber').value = "";
						if(document.getElementById("upload_viber_image")) document.getElementById("upload_viber_image").value = "";
						if(document.getElementById("file-uploaded-viber")) document.getElementById("file-uploaded-viber").src = '';
					}
				})
				.catch(err => {
					this.setState({
						submitting: false
					});
				});
	};

	removeTag = (message_id, tag) => {
		this.props.removeTags(message_id, tag, this.props.csrf);
	}

	addEmoji = (e) => {
		if (e.unified.length <= 5) {
			let emojiPic = String.fromCodePoint(`0x${e.unified}`);
			if (document.getElementById('reply-conversation-viber')) {
				document.getElementById('reply-conversation-viber').value = document.getElementById('reply-conversation-viber').value + emojiPic;
				document.getElementById('reply-conversation-viber').focus();
				this.shEmoji();

				this.setState({
					fileDisabled: true,
					replyValue: document.getElementById('reply-conversation-viber').value
				});
			}
		} else {
			let sym = e.unified.split('-');
			let codesArray = [];
			sym.forEach(el => codesArray.push('0x' + el));
			let emojiPic = String.fromCodePoint(...codesArray);
			if (document.getElementById('reply-conversation-viber')) {
				document.getElementById('reply-conversation-viber').value = document.getElementById('reply-conversation-viber').value + emojiPic;
				document.getElementById('reply-conversation-viber').focus();
				this.shEmoji();

				this.setState({
					fileDisabled: true,
					replyValue: document.getElementById('reply-conversation-viber').value
				});
			}
		}
	}

	shEmoji = () => {
		this.setState((prevState) => ({ ...prevState, emojiShown: !prevState.emojiShown }));
	}

	deleteReply = (project_id, reply) => {
		deleteFixedReply(project_id, reply)
		.then(response => {
			if(response.data) {
				toast.success("😀 Fixed reply was deleted successfully!", {
					autoClose: 2000,
					position: 'bottom-right',
					className: 'notification-message'
				});
				this.props.fixedReplies(this.props.match.params.project_id);
			}
		})
		.catch(err => {})
	}

	render() {
		let quick_chat_show_status;
		if(this.state.fixedReplyShown && this.props.fixed_replies.length > 0) quick_chat_show_status= ['quick-chat-item'];
		else quick_chat_show_status= ['quick-chat-item hide'];

		return (
			<div className="row message-container">
				<div className="row message-body">
					<div className="col-sm-12 ">
						<div className="screen">
							{this.props.currentUser.hasOwnProperty('name') ? 
							this.props.conversationLoading ? <div style={{width: '100%', paddingTop: '50px', textAlign: 'center'}}><img src={loader} alt="loading..." /></div> : 
							<Scrollbars style={{ height: '100%' }}>
								<div className="conversation" id="conversation_viber">
									{this.props.conversationMoreLoading ? <div style={{width: '100%', paddingTop: '10px', textAlign: 'center'}}><img src={loader} alt="loading..." /></div> : <Waypoint onEnter={this.loadMoreMessages} /> }
									{ this.props.conversations.sort(function(a,b){
										return new Date(a.time_stamp) - new Date(b.time_stamp);
									}).map((message, index) => message.sender_is === 'user' ? 
									<div key={message._id} className="messages messages-received text-left">
											{message.attachments.length > 0 ?
											(message.attachments[0].type === 'picture' || message.attachments[0].type === 'sticker') ? (message.text_content && message.text_content !== '') ? <><div className="message">{message.text_content}</div><div className="message message-attachment">
												{this.parseAttachment(message.attachments, message.text_content)}
												<div className="message-tools" id={message._id}>
													<span className="tool comment-tag" title="Add Tag" onClick={this.props.selectMessage.bind(this, message._id)}>
														<i className="fa fa-tags"></i>
													</span>
												</div>
											</div></> : <div className="message message-attachment">
												{this.parseAttachment(message.attachments, message.text_content)}
												<div className="message-tools" id={message._id}>
													<span className="tool comment-tag" title="Add Tag" onClick={this.props.selectMessage.bind(this, message._id)}>
														<i className="fa fa-tags"></i>
													</span>
												</div>
											</div> : <div className="message">
												{this.parseAttachment(message.attachments, message.text_content)}
												<div className="message-tools" id={message._id}>
													<span className="tool comment-tag" title="Add Tag" onClick={this.props.selectMessage.bind(this, message._id)}>
														<i className="fa fa-tags"></i>
													</span>
												</div>
											</div> : <div className="message">
												{message.text_content}
												<div className="message-tools" id={message._id}>
													<span className="tool comment-tag" title="Add Tag" onClick={this.props.selectMessage.bind(this, message._id)}>
														<i className="fa fa-tags"></i>
													</span>
												</div>
											</div>}

											<div className="message-time"><Moment fromNow tz="Asia/Dhaka" date={message.time_stamp} />{message.resolved_by ? message.resolved_by.username ? <>&nbsp;&nbsp;<i className="fa fa-check"></i> {message.resolved_by.username}</> : null : null}</div>
											{message.tags ? 
											<div className="message-tags">
												{message.tags.map((tag, index) => <span key={index} onClick={this.removeTag.bind(this, message._id, tag.title)}>{tag.title} <i className="fa fa-times"></i></span>)}
											</div> : null}
									</div> : 
									<div key={message._id} className="messages messages-sent text-right">
										{message.attachments.length > 0 ?
										message.attachments.length > 1 ? <div className="message message-attachments">
											{this.parseAttachment(message.attachments, message.text_content)}
										</div> : message.attachments[0].type === 'picture' ? <div className="message message-attachment">
											{this.parseAttachment(message.attachments, message.text_content)}
										</div> : <div className="message">
											{this.parseAttachment(message.attachments, message.text_content)}
										</div> :
										<div className="message">
											{message.text_content}
										</div>}

										<div className="message-time"><Moment fromNow tz="Asia/Dhaka" date={message.time_stamp} />{message.answered_by ? message.answered_by.username ? <> - {message.answered_by.username}</> : null : null}</div>
									</div>) }
								</div>
							</Scrollbars> : <div className="instruction-container">
									<div className="instruction text-center">
											<h1>Welcome to Inbox</h1>
											{/* <img className="logo img-responsive" src={logo} alt="listenyzen Logo" /> */}
											<p>
													Click any user from left bar to view the conversation.
											</p>
											<img className="instruction-image img-responsive" src={instruction} alt="Instruction" />
									</div>
							</div>}
							{this.state.isOpen && (
								<Lightbox
									mainSrc={this.state.imagePopup}
									onCloseRequest={() => this.setState({ isOpen: false })}
								/>
							)}
						</div>
						{this.props.currentUser.hasOwnProperty('name') && !this.props.conversationLoading  ?
						<div className="row reply">
							<div className="col-sm-8 reply-main">
								<form className="text-bar-field">
									<textarea id="reply-conversation-viber" disabled={this.state.replyTextDisabled} placeholder="Write your message here..." onKeyUp={(event) => this.getReplyInput(event)} onBlur={this.removeTyping}>{this.props.messageSuggestions && this.props.messageSuggestions.length > 0 ? this.props.messageSuggestions[0].replace('{user}', this.props.currentUser.name) : null}</textarea>
								</form>
							</div>
							<div className="col-sm-4 reply-tools text-right">
								<img className="img-uploaded" id="file-uploaded-viber" width="32px" alt="" onClick={this.clearImage} />
								<span className="attach-file">
									<label className="upload-attachment">
										<i className="fa fa-paperclip "></i>
										<input type="file" id="upload_viber_image" accept=".jpg,.jpeg,.png" disabled={this.state.fileDisabled} onChange={(event) => {
														if(event.currentTarget.files && event.currentTarget.files[0]) {
															if(event.currentTarget.files[0].size <= 1048576) {
																// setFieldValue("projectImage", event.currentTarget.files[0]);
																this.setState({
																	replyTextDisabled: true,
																	replyImage: 'has'
																});
																var reader = new FileReader();
																reader.onload = function (e) {
																	if(document.getElementById("file-uploaded-viber")) document.getElementById("file-uploaded-viber").src = e.target.result;
																}
																reader.readAsDataURL(event.currentTarget.files[0]);
															} else {
																if(document.getElementById("file-uploaded-viber")) document.getElementById("file-uploaded-viber").src = '';
																this.setState({
																	replyTextDisabled: false,
																	replyImage: ''
																});
																toast.error("😪 Image size must not exceed 1MB!", {
																	autoClose: 3000,
																	position: 'bottom-right',
																	className: 'notification-message'
																});
															}
														}
													}} />
									</label>
								</span>
								{ this.props.fixed_replies.length > 0 ? <span className="quick-chat">
									<i className="fa fa-list-alt" onClick={this.openFixedReplies}></i>
								</span> : null }

								<span className="emoji_open">
									<i className="fa fa-smile-o" onClick={this.shEmoji}></i>		
								</span>

								<div className="reply-send">
									{(this.state.replyValue !== '' || this.state.replyImage === 'has') && !this.state.submitting ? <i className="fa fa-send" onClick={this.submitReply}></i> : null }
									{this.state.submitting ? <img src={loader} alt="Loader" /> : null}
								</div>

								<Scrollbars>
									<div className={quick_chat_show_status}>
											<div>
												<div class="form-group has-search">
													<div class="glyphicon glyphicon-search form-control-feedback"></div>
													<input type="text" id="fixed_search" class="form-control" placeholder="Search" onChange={this.searchFixedReplies} />
												</div>
											</div>

											<ul className="list-group">
												{ this.state.fixed_replies.map((reply, index) => <li key={index} className="list-group-item">
													<div className="row">
														<div className="col-sm-10" style={{padding: '0 0 0 15px'}} onClick={this.fillUpReplyWithReplies.bind(this, reply.replace("{user}", this.props.currentUser.name))}>{reply.replace("{user}", this.props.currentUser.name)}</div>
														<div className="col-sm-2 text-right" style={{padding: '0 13px 0 0'}}><i className="fa fa-pencil-square-o" style={{cursor: 'pointer'}} onClick={this.props.modal.bind(this, 'add_efr_modal', reply)}></i><i className="fa fa-times" style={{marginLeft: '10px', cursor: 'pointer'}} onClick={this.deleteReply.bind(this, this.props.match.params.project_id, reply)}></i></div>
													</div>
													</li>)}
											</ul>
									</div>
								</Scrollbars>
							</div>
						</div> : <div className="row reply-placeholder"></div>}

						{this.props.currentUser.hasOwnProperty('name') && !this.props.conversationLoading && this.state.emojiShown  ? <Picker set='messenger' showPreview={false} title='Pick your emoji…' emoji='point_up' style={{ position: 'absolute', bottom: '90px', right: '20px' }} onSelect={this.addEmoji} /> : null}

					</div>
				</div>
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		conversations: state.viber_state.currentMessages,
		messageCount: state.viber_state.messageCount,
		skip: state.viber_state.skip_message,
		conversationLoading: state.inbox_state.conversationLoading,
		conversationMoreLoading: state.inbox_state.conversationMoreLoading,
		currentUser: state.inbox_state.currentUser,
		messageSuggestions: state.viber_state.messageSuggestions,
		fixed_replies: state.inbox_state.fixed_replies,
		currentMessage: state.viber_state.currentMessage,
		csrf: state.site_state.csrf
  };
}

const mapActionToProps = dispatch => {
    return {
			conversationMoreLoadingF: () => dispatch(actions.conversationMoreLoading()),
			loadConversation: (project_id, user_id, skip, limit, scroll) => dispatch(actions.loadvConversation(project_id, user_id, skip, limit, scroll)),
			selectMessage: (id) => dispatch(actions.selectvMessage(id)),
			removeTags: (message_id, tag, csrf) => dispatch(actions.removevMessageTag(message_id, tag, csrf)),
			fixedReplies: (project_id) => dispatch(actions.fixedReplies(project_id))
    };
};

export default withRouter(connect(mapStateToProps, mapActionToProps)(Conversation));