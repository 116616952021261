import React, { Component } from 'react';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CustomToggle from '../../UI/CustomToggle';
import { toast } from 'react-toastify';
import * as actions from '../../../store/actions/inbox_actions';
import { replyComment, followupComment, fcomComment } from '../../../services/CommentService';
import { replyMessage, followupMessage, fcomMessage } from '../../../services/MessageService';
import { replyViber } from '../../../services/ViberService';
import { replyInstagram } from '../../../services/InstagramService';
import ReactImageFallback from "react-image-fallback";
import spinner from '../../../assets/img/login_spinner.gif';
import nophoto from '../../../assets/img/nophoto.png';

class Header extends Component {
	resolve = () => {
		if(this.props.match.params.platform === 'facebook') {
			let formData = new FormData();
			formData.append('project_id', this.props.match.params.project_id);
			formData.append('_csrf', this.props.csrf);
			if(this.props.currentComment.comment_level === 'post') {
				// we are replying to post
				formData.append('id', this.props.currentComment.post_id);
				formData.append('reply_of', this.props.currentComment.post_id);
				formData.append('reply_to', 'post');
			} else {
				// we are replying to comment
				formData.append('reply_of', this.props.currentComment.comment_id);
				formData.append('reply_to', 'comment');
				if(this.props.currentComment.comment_level === 'first') {
					formData.append('id', this.props.currentComment.comment_id);
				} else {
					formData.append('id', this.props.currentComment.parent_id);
				}
			}
		
			replyComment(formData)
				.then(response => {
					if(response.data) {
						this.props.changeCurrentComment('resolved');
						toast.success("😀 Comment was resolved successfully!", {
							autoClose: 5000,
							position: 'bottom-right',
							className: 'notification-message'
						});
					}
				})
				.catch(err => {});
		}

		if(this.props.match.params.platform === 'messenger') {
			let formData = new FormData();
			formData.append('project_id', this.props.match.params.project_id);
			formData.append('recepient', this.props.user.psid);
			formData.append('_csrf', this.props.csrf);

			replyMessage(formData)
				.then(response => {
					if(response.data) {
						this.props.changeCurrentMessage('resolved');
						toast.success("😀 Conversation was resolved successfully!", {
							autoClose: 5000,
							position: 'bottom-right',
							className: 'notification-message'
						});
					}
				})
				.catch(err => {});
		}

		if(this.props.match.params.platform === 'viber') {
			let formData = new FormData();
			formData.append('project_id', this.props.match.params.project_id);
			formData.append('recepient', this.props.user.id);
			formData.append('_csrf', this.props.csrf);

			replyViber(formData)
				.then(response => {
					if(response.data) {
						this.props.changeCurrentvMessage('resolved');
						toast.success("😀 Conversation was resolved successfully!", {
							autoClose: 5000,
							position: 'bottom-right',
							className: 'notification-message'
						});
					}
				})
				.catch(err => {});
		}

		if(this.props.match.params.platform === 'instagram') {
			let formData = new FormData();
			formData.append('project_id', this.props.match.params.project_id);
			formData.append('comment_id', this.props.currentiComment.comment_id);
			formData.append('_csrf', this.props.csrf);

			replyInstagram(formData)
				.then(response => {
					if(response.data) {
						this.props.changeCurrentiComment('resolved');
						toast.success("😀 Comment was resolved successfully!", {
							autoClose: 5000,
							position: 'bottom-right',
							className: 'notification-message'
						});
					}
				})
				.catch(err => {});
		}
	}

	followup = (status, id) => {
		if(this.props.match.params.platform === 'messenger') {
			followupMessage(this.props.match.params.project_id, status, id, this.props.csrf)
					.then(response => {
						if(response.data) {
							this.props.updateMessageFollowup(status);
							toast.success("😀 " + response.data.message, {
								autoClose: 5000,
								position: 'bottom-right',
								className: 'notification-message'
							});
						}
					})
					.catch(err => {});
		} else if(this.props.match.params.platform === 'facebook') {
				followupComment(this.props.match.params.project_id, status, id, this.props.csrf)
						.then(response => {
							if(response.data) {
								this.props.updateCommentFollowup(status);
								toast.success("😀 " + response.data.message, {
									autoClose: 5000,
									position: 'bottom-right',
									className: 'notification-message'
								});
							}
						})
						.catch(err => {});
		}
	}

	fcom = (status, id) => {
		if(this.props.match.params.platform === 'messenger') {
			fcomMessage(this.props.match.params.project_id, status, id, this.props.csrf)
					.then(response => {
						if(response.data) {
							this.props.updateMessageFollowupFcom(status);
							toast.success("😀 " + response.data.message, {
								autoClose: 5000,
								position: 'bottom-right',
								className: 'notification-message'
							});
						}
					})
					.catch(err => {});
		} else if(this.props.match.params.platform === 'facebook') {
				fcomComment(this.props.match.params.project_id, status, id, this.props.csrf)
						.then(response => {
							if(response.data) {
								this.props.updateCommentFollowupFcom(status);
								toast.success("😀 " + response.data.message, {
									autoClose: 5000,
									position: 'bottom-right',
									className: 'notification-message'
								});
							}
						})
						.catch(err => {});
		}
	}

	render() {
		return (
			<div className="row heading">
				{ this.props.user.name || this.props.user.username ? <><div className="col-sm-2 col-md-1 col-xs-3 heading-avatar">
					<div className="heading-avatar-icon">
						{this.props.match.params.platform === 'messenger' || this.props.match.params.platform === 'facebook' || this.props.match.params.platform === 'instagram' ? <ReactImageFallback src={this.props.user.profile_pic} alt={this.props.user.name || this.props.user.username} fallbackImage={nophoto} initialImage={spinner} /> : <ReactImageFallback src={this.props.user.avatar} alt={this.props.user.name} fallbackImage={nophoto} initialImage={spinner} />}
					</div>
				</div>
				<div className="col-sm-8 col-xs-7 heading-name">
					<a href="#/" className="heading-name-meta">{this.props.user.name || '@' + this.props.user.username}</a>
				</div></> : null }
				
				{ this.props.user.name && this.props.match.params.platform === 'messenger'
					? 	<div className="col-sm-1 col-xs-1 heading-dot pull-right">
								<Dropdown id="dropdown-custom-menu">
									<CustomToggle bsRole="toggle">
										<i className="fa fa-ellipsis-v fa-2x dropdown-toggle pull-right"></i>
									</CustomToggle>
									<Dropdown.Menu bsRole="menu" className="dropdown-menu-right">
										{ ((this.props.currentMessage.resolved_status === 'unresolved') || (this.props.currentMessage.resolved_status === 'resolved' && !this.props.currentMessage.resolved_by) || (this.props.currentMessage.resolved_status === 'resolved' && this.props.currentMessage.resolved_by && !this.props.currentMessage.resolved_by.userid)) ? <MenuItem onClick={this.resolve}>Resolve Conversation</MenuItem> : null}
										{ this.props.currentMessage.followup_status === 'yes' ? <MenuItem onClick={this.followup.bind(this, 'no', this.props.currentMessage.user_id)}>Done Followup</MenuItem> : <MenuItem onClick={this.followup.bind(this, 'yes', this.props.currentMessage.user_id)}>Followup Later</MenuItem>}
										{ this.props.currentMessage.fcom_status === 'yes' ? <MenuItem onClick={this.fcom.bind(this, 'no', this.props.currentMessage.user_id)}>Unmark as F-commerce Lead</MenuItem> : <MenuItem onClick={this.fcom.bind(this, 'yes', this.props.currentMessage.user_id)}>Mark as F-commerce Lead</MenuItem>}
									</Dropdown.Menu>
								</Dropdown>
							</div>
					:	null
				}

				{ this.props.user.name && this.props.match.params.platform === 'facebook'
					? 	<div className="col-sm-1 col-xs-1 heading-dot pull-right">
								<Dropdown id="dropdown-custom-menu">
									<CustomToggle bsRole="toggle">
										<i className="fa fa-ellipsis-v fa-2x dropdown-toggle pull-right"></i>
									</CustomToggle>
									<Dropdown.Menu bsRole="menu" className="dropdown-menu-right">
										<MenuItem onClick={ this.props.changeFbPostShow }>{ this.props.fbPostShown ? 'Hide' : 'Show' } Post</MenuItem>
										{this.props.currentComment.resolved_status === 'unresolved' ? <MenuItem onClick={this.resolve}>Resolve Comment</MenuItem> : null }
										{ this.props.currentComment.followup_status === 'yes' ? <MenuItem onClick={this.followup.bind(this, 'no', this.props.currentComment._id)}>Done Followup</MenuItem> : <MenuItem onClick={this.followup.bind(this, 'yes', this.props.currentComment._id)}>Followup Later</MenuItem>}
										{ this.props.currentComment.fcom_status === 'yes' ? <MenuItem onClick={this.fcom.bind(this, 'no', this.props.currentComment._id)}>Unmark as F-commerce Lead</MenuItem> : <MenuItem onClick={this.fcom.bind(this, 'yes', this.props.currentComment._id)}>Mark as F-commerce Lead</MenuItem>}
									</Dropdown.Menu>
								</Dropdown>
							</div>
					:	null
				}

				{ this.props.user.name && this.props.match.params.platform === 'viber' && this.props.currentViber.resolved_status === 'unresolved'
					? 	<div className="col-sm-1 col-xs-1 heading-dot pull-right">
								<Dropdown id="dropdown-custom-menu">
									<CustomToggle bsRole="toggle">
										<i className="fa fa-ellipsis-v fa-2x dropdown-toggle pull-right"></i>
									</CustomToggle>
									<Dropdown.Menu bsRole="menu" className="dropdown-menu-right">
										<MenuItem onClick={this.resolve}>Resolve Conversation</MenuItem>
									</Dropdown.Menu>
								</Dropdown>
							</div>
					:	null
				}

				{ this.props.user.username && this.props.match.params.platform === 'instagram' && this.props.currentiComment.resolved_status === 'unresolved'
					? 	<div className="col-sm-1 col-xs-1 heading-dot pull-right">
								<Dropdown id="dropdown-custom-menu">
									<CustomToggle bsRole="toggle">
										<i className="fa fa-ellipsis-v fa-2x dropdown-toggle pull-right"></i>
									</CustomToggle>
									<Dropdown.Menu bsRole="menu" className="dropdown-menu-right">
										<MenuItem onClick={this.resolve}>Resolve Comment</MenuItem>
									</Dropdown.Menu>
								</Dropdown>
							</div>
					:	null
				}
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		user: state.inbox_state.currentUser,
		fbPostShown: state.comment_state.fbPostShown,
		currentComment: state.comment_state.currentComment,
		currentiComment: state.instagram_state.currentComment,
		currentMessage: state.message_state.currentMessage,
		currentViber: state.viber_state.currentMessage,
		csrf: state.site_state.csrf
  };
}

const mapActionToProps = dispatch => {
    return {
			changeFbPostShow: () => dispatch(actions.changeFbPostShow()),
			changeCurrentComment: (status) => dispatch(actions.changeCurrentComment(status)),
			changeCurrentMessage: (status) => dispatch(actions.changeCurrentMessage(status)),
			changeCurrentvMessage: (status) => dispatch(actions.changeCurrentvMessage(status)),
			changeCurrentiComment: (status) => dispatch(actions.changeCurrentiComment(status)),
			updateCommentFollowup: (status) => dispatch(actions.updateCommentFollowup(status)),
			updateMessageFollowup: (status) => dispatch(actions.updateMessageFollowup(status)),
			updateMessageFollowupFcom: (status) => dispatch(actions.updateMessageFollowupFcom(status)),
			updateCommentFollowupFcom: (status) => dispatch(actions.updateCommentFollowupFcom(status)),
    };
};

export default withRouter(connect(mapStateToProps, mapActionToProps)(Header));