import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import AsyncPaginate from "react-select-async-paginate";
import * as actions from '../../../store/actions/inbox_actions';
import "react-datepicker/dist/react-datepicker.css";
import { getPosts } from '../../../services/CommentService';

class Toolbar extends Component {

	state = {
		start_date: this.props.start_date_message,
		end_date: this.props.end_date_message,
		start_date_comment: this.props.start_date_comment,
		end_date_comment: this.props.start_date_comment,
		start_date_viber: this.props.start_date_viber,
		end_date_viber: this.props.end_date_viber,
		start_date_instagram: this.props.start_date_instagram,
		end_date_instagram: this.props.end_date_instagram,
		value: this.props.postid,
		followup_clicked: false,
		fcom_clicked: false
	};

	options = [];
	
	onChange = value => {
		this.setState({
			value
		});
	};

	handleStartDate = (date) => {
		if(this.props.match.params.platform === 'messenger') {
			this.setState({
				start_date: date
			});
		}
		if(this.props.match.params.platform === 'facebook') {
			this.setState({
				start_date_comment: date
			});
		}
		if(this.props.match.params.platform === 'viber') {
			this.setState({
				start_date_viber: date
			});
		}
		if(this.props.match.params.platform === 'instagram') {
			this.setState({
				start_date_instagram: date
			});
		}
	}

	handleEndDate = (date) => {
		if(this.props.match.params.platform === 'messenger') {
			this.setState({
				end_date: date
			});
		}
		if(this.props.match.params.platform === 'facebook') {
			this.setState({
				end_date_comment: date
			});
		}
		if(this.props.match.params.platform === 'viber') {
			this.setState({
				end_date_viber: date
			});
		}
		if(this.props.match.params.platform === 'instagram') {
			this.setState({
				end_date_instagram: date
			});
		}
	}

	searchUser = (followup_status = '', go_clicked = false) => {
		if(!go_clicked) {
			this.setState({
				followup_clicked: !this.state.followup_clicked
			});
		}
		if(this.props.match.params.platform === 'messenger') {
			this.props.usersLoading();
			this.props.searchMessageUsers(this.props.match.params.project_id, document.getElementById('search_message').value, this.state.start_date, this.state.end_date, followup_status, '');
		}
		if(this.props.match.params.platform === 'facebook') {
			this.props.usersLoading();
			this.props.searchCommentUsers(this.props.match.params.project_id, document.getElementById('search_comment').value, this.state.start_date_comment, this.state.end_date_comment, this.state.value, followup_status, '');
		}
		if(this.props.match.params.platform === 'viber') {
			this.props.usersLoading();
			this.props.searchvMessageUsers(this.props.match.params.project_id, document.getElementById('search_viber').value, this.state.start_date_viber, this.state.end_date_viber);
		}
		if(this.props.match.params.platform === 'instagram') {
			this.props.usersLoading();
			this.props.searchiCommentUsers(this.props.match.params.project_id, document.getElementById('search_icomment').value, this.state.start_date_instagram, this.state.end_date_instagram);
		}
	}

	searchUserFcom = (fcom_status = '', go_clicked = false) => {
		if(!go_clicked) {
			this.setState({
				fcom_clicked: !this.state.fcom_clicked
			});
		}
		if(this.props.match.params.platform === 'messenger') {
			this.props.usersLoading();
			this.props.searchMessageUsers(this.props.match.params.project_id, document.getElementById('search_message').value, this.state.start_date, this.state.end_date, '', fcom_status);
		}
		if(this.props.match.params.platform === 'facebook') {
			this.props.usersLoading();
			this.props.searchCommentUsers(this.props.match.params.project_id, document.getElementById('search_comment').value, this.state.start_date_comment, this.state.end_date_comment, this.state.value, '', fcom_status);
		}
		if(this.props.match.params.platform === 'viber') {
			this.props.usersLoading();
			this.props.searchvMessageUsers(this.props.match.params.project_id, document.getElementById('search_viber').value, this.state.start_date_viber, this.state.end_date_viber);
		}
		if(this.props.match.params.platform === 'instagram') {
			this.props.usersLoading();
			this.props.searchiCommentUsers(this.props.match.params.project_id, document.getElementById('search_icomment').value, this.state.start_date_instagram, this.state.end_date_instagram);
		}
	}

	clearInput = (id) => {
		if(document.getElementById(id)) document.getElementById(id).value = '';
	}

	getOptions = () => {
		return getPosts(this.props.match.params.project_id)
			.then(response => {
				return response.data.data;
			})
			.catch(err => {
				return [];
			})
	}

	text_truncate = (str, length, ending) => {
		if (length == null) {
			length = 100;
		}
		if (ending == null) {
			ending = '...';
		}
		if (str.length > length) {
			return str.substring(0, length - ending.length) + ending;
		} else {
			return str;
		}
	};

	loadOptions = async (search, prevOptions) => {
		if(!search) {
			var options = await this.getOptions();
			options.forEach(element => {
				this.options.push({
					value: element.id,
					label: element.message ? '[ ' + new Date(element.created_time.replace('+0000', 'Z')).toLocaleString() + ' ] ' + this.text_truncate(element.message) : element.story ? '[ ' + new Date(element.created_time).toLocaleString() + ' ] ' + element.story : '[ ' + new Date(element.created_time).toLocaleString() + ' ] Page published a post'
				});
			});
			return {
				options: this.options,
				hasMore: false
			};
		} else {
			const searchLower = search.toLowerCase();
			var filteredOptions = this.options.filter(({ label }) =>
				label.toLowerCase().includes(searchLower)
			);

			return {
				options: filteredOptions,
				hasMore: false
			};
		}
	};

	componentDidMount() {
		if(this.props.match.params.psid && this.props.match.params.psid !== '') {
			if(this.props.match.params.platform === 'messenger') {
				this.props.setKeywordMessenger(this.props.match.params.psid);
			} else if(this.props.match.params.platform === 'facebook') {
				this.props.setKeywordComment(this.props.match.params.psid);
			}
		}
	}

	render() {
		const { value } = this.state;
		const { followup_clicked, fcom_clicked } = this.state;
		var fclass = '';
		var fcomclass = '';
		if(followup_clicked === false) {
			fclass = 'run-query followup';
		}
		if(followup_clicked === true) {
			fclass = 'run-query freset';
		}
		if(fcom_clicked === false) {
			fcomclass = 'run-query fcom';
		}
		if(fcom_clicked === true) {
			fcomclass = 'run-query fcomreset';
		}
		return (
			<>
				<div className="row">
					<div className="searchBox">
						<div className="col-sm-8 searchBox-inner">
							<div className="form-group has-feedback">
								<form autoComplete="off">
									{ this.props.match.params.platform === 'messenger' ? <input id="search_message" type="text" defaultValue={this.props.keyword_message} className="form-control" name="searchText" placeholder="Search users or messages" disabled={this.props.userLoading || this.props.userMoreLoading || this.props.conversationLoading || this.props.conversationMoreLoading} />: null }
									{ this.props.match.params.platform === 'facebook' ? <input id="search_comment" type="text" defaultValue={this.props.keyword_comment} className="form-control" name="searchText" placeholder="Search users or comments" disabled={this.props.userLoading || this.props.userMoreLoading || this.props.conversationLoading || this.props.conversationMoreLoading} /> : null }
									{ this.props.match.params.platform === 'viber' ? <input id="search_viber" type="text" defaultValue={this.props.keyword_viber} className="form-control" name="searchText" placeholder="Search users or messages" disabled={this.props.userLoading || this.props.userMoreLoading || this.props.conversationLoading || this.props.conversationMoreLoading} />: null }
									{ this.props.match.params.platform === 'instagram' ? <input id="search_icomment" type="text" defaultValue={this.props.keyword_instagram} className="form-control" name="searchText" placeholder="Search users or messages" disabled={this.props.userLoading || this.props.userMoreLoading || this.props.conversationLoading || this.props.conversationMoreLoading} />: null }
								</form>
								{this.props.match.params.platform === 'messenger' ? !this.props.keyword_message ? <span className="glyphicon glyphicon-search form-control-feedback"></span> : <span className="glyphicon glyphicon-remove form-control-feedback" onClick={this.clearInput.bind(this, 'search_message')}></span> : null}
								{this.props.match.params.platform === 'facebook' ? !this.props.keyword_comment ? <span className="glyphicon glyphicon-search form-control-feedback"></span> : <span className="glyphicon glyphicon-remove form-control-feedback" onClick={this.clearInput.bind(this, 'search_comment')}></span> : null}
								{this.props.match.params.platform === 'viber' ? !this.props.keyword_viber ? <span className="glyphicon glyphicon-search form-control-feedback"></span> : <span className="glyphicon glyphicon-remove form-control-feedback" onClick={this.clearInput.bind(this, 'search_viber')}></span> : null}
								{this.props.match.params.platform === 'instagram' ? !this.props.keyword_instagram ? <span className="glyphicon glyphicon-search form-control-feedback"></span> : <span className="glyphicon glyphicon-remove form-control-feedback" onClick={this.clearInput.bind(this, 'search_icomment')}></span> : null}
							</div>
						</div>
						<div className="col-sm-2 text-right" style={{padding: '10px 10px 0 0'}}>
							<div id="followupbuttton" className={fclass}>
								{this.state.followup_clicked ? <button type="button" className="btn" onClick={this.searchUser.bind(this, '', false)} title="Load all messages" >
									All
								</button> : <button type="button" className="btn" onClick={this.searchUser.bind(this, 'yes', false)} title="Click to load messages that need followup" >
									F
								</button>}
							</div>
						</div>
						<div className="col-sm-2 text-right" style={{padding: '10px 10px 0 0'}}>
							<div id="fcombutton" className={fcomclass}>
								{this.state.fcom_clicked ? <button type="button" className="btn" onClick={this.searchUserFcom.bind(this, '', false)} title="Load all messages" >
									All
								</button> : <button type="button" className="btn" onClick={this.searchUserFcom.bind(this, 'yes', false)} title="Click to load f-commerce related messages" >
									<i class="fa fa-shopping-cart" aria-hidden="true"></i>
								</button>}
							</div>
						</div>
					</div>
				</div>
				{this.props.match.params.platform === 'facebook' ? <div className="row">
					<div className="searchBox">
						<div className="col-sm-12 projectselect-inner">
							<div className="form-group">
							<AsyncPaginate
								debounceTimeout={1000}
								value={value}
								loadOptions={this.loadOptions}
								onChange={this.onChange}
								isClearable={true}
								placeholder="Select post"
							/>
							</div>
						</div>
					</div>
				</div> : null }
				<div className="row">
					<div className="searchBox">
						<div className="col-sm-5 datefilter-inner">
							<div className="form-group">
								{this.props.match.params.platform === 'messenger' ? <DatePicker placeholderText="Start time" className="form-control" isClearable={true} showTimeSelect dateFormat="YYYY-MM-dd h:mm aa" selected={this.state.start_date} onChange={this.handleStartDate} /> : null }
								{this.props.match.params.platform === 'facebook' ? <DatePicker placeholderText="Start time" className="form-control" isClearable={true} showTimeSelect dateFormat="YYYY-MM-dd h:mm aa" selected={this.state.start_date_comment} onChange={this.handleStartDate} /> : null }
								{this.props.match.params.platform === 'viber' ? <DatePicker placeholderText="Start time" className="form-control" isClearable={true} showTimeSelect dateFormat="YYYY-MM-dd h:mm aa" selected={this.state.start_date_viber} onChange={this.handleStartDate} /> : null }
								{this.props.match.params.platform === 'instagram' ? <DatePicker placeholderText="Start time" className="form-control" isClearable={true} showTimeSelect dateFormat="YYYY-MM-dd h:mm aa" selected={this.state.start_date_instagram} onChange={this.handleStartDate} /> : null }
							</div>
						</div>
						<div className="col-sm-5 datefilter-inner">
							<div className="form-group">
								{this.props.match.params.platform === 'messenger' ? <DatePicker placeholderText="End time" className="form-control" isClearable={true} showTimeSelect dateFormat="YYYY-MM-dd h:mm aa" selected={this.state.end_date} onChange={this.handleEndDate}/> : null }
								{this.props.match.params.platform === 'facebook' ? <DatePicker placeholderText="End time" className="form-control" isClearable={true} showTimeSelect dateFormat="YYYY-MM-dd h:mm aa" selected={this.state.end_date_comment} onChange={this.handleEndDate}/> : null }
								{this.props.match.params.platform === 'viber' ? <DatePicker placeholderText="End time" className="form-control" isClearable={true} showTimeSelect dateFormat="YYYY-MM-dd h:mm aa" selected={this.state.end_date_viber} onChange={this.handleEndDate}/> : null }
								{this.props.match.params.platform === 'instagram' ? <DatePicker placeholderText="End time" className="form-control" isClearable={true} showTimeSelect dateFormat="YYYY-MM-dd h:mm aa" selected={this.state.end_date_instagram} onChange={this.handleEndDate}/> : null }
							</div>
						</div>
						<div className="col-sm-2 text-right" style={{padding: '0 10px 0 0'}}>
							<div className="run-query">
								<button type="button" className="btn" onClick={this.searchUser.bind(this, '', true)} >
									Go
								</button>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		userLoading: state.inbox_state.userLoading,
		userMoreLoading: state.inbox_state.userMoreLoading,
		conversationLoading: state.inbox_state.conversationLoading,
		conversationMoreLoading: state.inbox_state.conversationMoreLoading,
		keyword_message: state.message_state.keyword,
		start_date_message: state.message_state.start_date,
		end_date_message: state.message_state.end_date,
		keyword_comment: state.comment_state.keyword,
		start_date_comment: state.comment_state.start_date,
		end_date_comment: state.comment_state.end_date,
		keyword_viber: state.viber_state.keyword,
		start_date_viber: state.viber_state.start_date,
		end_date_viber: state.viber_state.end_date,
		keyword_instagram: state.viber_state.keyword,
		start_date_instagram: state.viber_state.start_date,
		end_date_instagram: state.viber_state.end_date,
		postid: state.comment_state.postid
    };
}

const mapActionToProps = dispatch => {
    return {
			searchMessageUsers: (project_id, keyword, start_date, end_date, followup_status, fcom_status) => dispatch(actions.searchMessageUsers(project_id, keyword, start_date, end_date, followup_status, fcom_status)),
			searchCommentUsers: (project_id, keyword, start_date, end_date, postid, followup_status, fcom_status) => dispatch(actions.searchCommentUsers(project_id, keyword, start_date, end_date, postid, followup_status, fcom_status)),
			searchiCommentUsers: (project_id, keyword, start_date, end_date) => dispatch(actions.searchiCommentUsers(project_id, keyword, start_date, end_date)),
			searchvMessageUsers: (project_id, keyword, start_date, end_date) => dispatch(actions.searchvMessageUsers(project_id, keyword, start_date, end_date)),
			usersLoading: () => dispatch(actions.userLoading()),
			setKeywordMessenger: (keyword) => dispatch(actions.setKeywordMessenger(keyword)),
			setKeywordComment: (keyword) => dispatch(actions.setKeywordComment(keyword))
    };
};

export default withRouter(connect(mapStateToProps, mapActionToProps)(Toolbar));