import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import Wrapper from '../../components/Wrapper/Wrapper';
import DfilterBar from '../../components/Dfilterbar/Dfilterbar';
import DdashboardContent from '../../components/DdashboardContent/DdashboardContent';

class Databank extends Component {
	componentDidMount() {
		document.title = 'Databank - listenyzen';
		document.body.classList.add('main-body');
	}

	componentWillUnmount() {
		document.body.classList.remove('main-body');
	}

	render() {
		return (
			<div className="Dashboard">
				<Header />
				<Wrapper>
					<div className="analytics-dashboard">
						<div className="container-fluid">
							<DfilterBar />
							<DdashboardContent />
						</div>
					</div>
				</Wrapper>
			</div>
		);
	}
};

export default Databank;