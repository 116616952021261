import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import inboxReducer from './store/reducers/inbox_reducer';
import siteReducer from './store/reducers/site_reducer';
import messageReducer from './store/reducers/message_reducer';
import commentReducer from './store/reducers/comment_reducer';
import viberReducer from './store/reducers/viber_reducer';
import instagramReducer from './store/reducers/instagram_reducer';
import reportReducer from './store/reducers/report_reducer';
import App from './App';
import * as serviceWorker from './serviceWorker';
require('dotenv').config();

const rootReducer = combineReducers({
	site_state: siteReducer,
	inbox_state: inboxReducer,
	message_state: messageReducer,
	comment_state: commentReducer,
	viber_state: viberReducer,
	instagram_state: instagramReducer,
	report_state: reportReducer
});
const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();