import React from 'react';
import Header from '../Header/Header';
import Conversation from '../MessengerSection/Conversation/Conversation';

const MessengerSection = (props) => {
	return (
		<>
			<Header />
			<Conversation modal={props.modal} />
		</>
	);
};

export default MessengerSection;