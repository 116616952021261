import React, { Component } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import * as actions from '../../store/actions/report_actions';

const options_platform = [
	{ value: '', label: 'Any' },
	{ value: 'messenger', label: 'Messenger' },
	{ value: 'fb_comment', label: 'Facebook Comment' },
	{ value: 'viber', label: 'Viber' },
	{ value: 'instagram', label: 'Instagram' }
];

class DfilterBar extends Component {

	handlePlatformChange = (selectedPlatform) => {
		this.props.setPlatform(selectedPlatform);
	}

	handleKeywordChange = (event) => {
		this.props.setKeyword(event.target.value);
	}

	handleStartDate = (date) => {
		this.props.setStartDate(date);
	}

	handleEndDate = (date) => {
		this.props.setEndDate(date);
	}

	changeData = (project_id, platform, skip, keyword, start_date, end_date) => {
		this.props.getDatabank(project_id, platform, skip, keyword, start_date, end_date);
	}

	render() {
		return (
			<div className="analytics-control-bar">
				<div className="control-bar-inner">
					<div className="left-side-control">
						<div className="row">
							<div className="col-sm-2 col-sm-offset-1">
								<div style={{marginTop: '10px'}}>
									<Select value={this.props.platform} onChange={this.handlePlatformChange} options={options_platform} placeholder="Select platform" />
								</div>
							</div>
							<div className="col-sm-3 text-center">
								<div className="project-duration" style={{marginTop: '10px'}}>
									<div className="form-group">
										<div className="input-group">
											<div className="input-group-addon">
												<i className="fa fa-calendar"></i>
											</div>
											{/* <input type="text" className="form-control" id="projDuration" /> */}
											<DatePicker
											showTimeSelect
											selected={this.props.start_date}
											onChange={this.handleStartDate}
											className="form-control"
											dateFormat="YYYY-MM-dd h:mm aa"
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="col-sm-3 text-center">
								<div className="project-duration" style={{marginTop: '10px'}}>
									<div className="form-group">
										<div className="input-group">
											<div className="input-group-addon">
												<i className="fa fa-calendar"></i>
											</div>
											<DatePicker
											showTimeSelect
											selected={this.props.end_date}
											onChange={this.handleEndDate}
											className="form-control"
											dateFormat="YYYY-MM-dd h:mm aa"
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="col-sm-3">
								<div className="project-duration" style={{marginTop: '10px'}}>
									<div className="form-group">
										<div className="input-group">
											<div className="input-group-addon">
												<i className="fa fa-search"></i>
											</div>
											<input type="text" className="form-control" onChange={(event) => this.handleKeywordChange(event)} placeholder="Search by tag intent entity or keyword" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="right-side-control">
						<div className="run-query text-right">
							<button type="button" className="btn" onClick={this.changeData.bind(this, this.props.match.params.project_id, this.props.platform, 0, this.props.keyword, this.props.start_date, this.props.end_date)} style={{marginRight: '10px'}}>Go</button>
							<button type="button" className="btn">{this.props.count}</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		platform: state.report_state.platform,
		keyword: state.report_state.keyword_databank,
		skip: state.report_state.skip_databank,
		count: state.report_state.databank_count,
		start_date: state.report_state.dstart_date,
		end_date: state.report_state.dend_date
	};
}

const mapActionToProps = dispatch => {
	return {
		setPlatform: (platform) => dispatch(actions.setPlatform(platform)),
		setKeyword: (keyword) => dispatch(actions.setKeyword(keyword)),
		getDatabank: (project_id, platform, skip, keyword, start_date, end_date) => dispatch(actions.getDatabank(project_id, platform, skip, keyword, start_date, end_date)),
		setStartDate: (start_date) => dispatch(actions.setdStartDate(start_date)),
		setEndDate: (end_date) => dispatch(actions.setdEndDate(end_date)),
	};
};

export default withRouter(connect(mapStateToProps, mapActionToProps)(DfilterBar));