import React, { Component } from 'react';
import { connect } from 'react-redux';
import loader from '../../../assets/img/loader.svg';
import TagCloud from 'react-tag-cloud';
import randomColor from 'randomcolor';

class TopicSection extends Component {
	typingTimer;
	componentDidMount() {
		this.typingTimer = setInterval(() => {
		  this.forceUpdate();
		}, 4000);
	}

	componentWillUnmount() {
		clearTimeout(this.typingTimer);
	}

	render() {
		var final_topics = this.props.entities.sort((a, b) => (a.count > b.count) ? -1 : 1);
		return (
			<div className="tag-cloud-graph">
				<div className="panel panel-default">
					<div className="panel-heading">
						<h3 className="panel-title">Topic (entity) Analysis</h3>
					</div>
					<div className="panel-body" style={{height: '400px', textAlign: 'center'}}>
						{this.props.entityloading ? <img src={loader} alt="loader" className="aligncenter" /> : this.props.entities && this.props.entities.length > 0 ? <><button className="btn cwz" onClick={this.props.manage.bind(this, 'entity', this.props.entities)}>Detail Report</button><TagCloud 
							className='tag-cloud'
							style={{
								fontFamily: 'SF Display',
								color: () => randomColor(),
								padding: 5,
								width: '100%',
								height: '100%'
						}}>
							{final_topics.slice(0, 50).map((entity, index) => <div key={index} style={{fontSize: ((50 * entity.count) / final_topics[0].count) > 10 ? ((50 * entity.count) / final_topics[0].count) : 12}}>{entity._id}</div>)}
						</TagCloud></> : <p className="aligncenter">No data available</p>}
					</div>
				</div>
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		entityloading: state.report_state.entityloading,
		entities: state.report_state.entities
	};
}

export default connect(mapStateToProps)(TopicSection);