import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AsyncPaginate from "react-select-async-paginate";
import * as actions from '../../../store/actions/inbox_actions';
import { getTags } from '../../../services/ProjectService';

class Tag extends Component {
	state = {
		keyword: '',
		value: [],
		tag_column: 'col-sm-10'
	};
	
	onChange = value => {
		this.setState({
			value
		});
	};

	setTagsValue = value => {
		if(this.props.selectedComments.length > 0 || this.props.selectedMessages.length >0 || this.props.selectedvMessages.length >0 || this.props.selectediComments.length > 0) {
			this.setState({
				value: this.state.value.concat(value)
			});
		}
	}

	addTag = () => {
		if(this.props.match.params.platform === 'messenger') {
			if(this.props.selectedMessages.length > 0) {
				this.props.addMessageTag('message', this.state.value, this.props.selectedMessages, this.props.csrf);
			}
		}

		if(this.props.match.params.platform === 'facebook') {
			if(this.props.selectedComments.length > 0) {
				this.props.addCommentTag('comment', this.state.value, this.props.selectedComments, this.props.csrf);
			}
		}

		if(this.props.match.params.platform === 'viber') {
			if(this.props.selectedvMessages.length > 0) {
				this.props.addvMessageTag('viber', this.state.value, this.props.selectedvMessages, this.props.csrf);
			}
		}

		if(this.props.match.params.platform === 'instagram') {
			if(this.props.selectediComments.length > 0) {
				this.props.addiCommentTag('instagram', this.state.value, this.props.selectediComments, this.props.csrf);
			}
		}
		this.setState({
			value: []
		});
	}

	getOptions = (keyword, project_id) => {
		return getTags(keyword, project_id)
			.then(response => {
				return response.data;
			})
			.catch(err => {
				return [];
			})
	}

	loadOptions = async (search, prevOptions) => {
		if(search) {
			const searchLower = search.toLowerCase();
			var options = await this.getOptions(searchLower, this.props.match.params.project_id);
			var modified_options = [];
			options.forEach(element => {
				modified_options.push({
					value: element.tag_title,
					label: element.tag_title
				});
			});
			return {
				options: modified_options,
				hasMore: false
			};
		} else {
			return {
				options: prevOptions,
				hasMore: false
			};
		}
	};

	componentWillMount = () => {
		if(this.props.match.params.project_id === '5d3451b9b387aa4c9c231d5e' && localStorage.getItem('userData') && JSON.parse(localStorage.getItem('userData')).role === 'query_manager') {
			this.setState({
				tag_column: 'col-sm-12'
			});
		}
	};

	render() {
		const { value } = this.state;
		return (
			<div className="profile-tags">
				<div className="profile-tag-details">
					<h3>Tags</h3>
					<div className="row">
						<div className={this.state.tag_column}>
							<div className="tag-search">
								<div className="form-group has-feedback">
									{/* <form autoComplete="off">
										<input type="text" id="tag_text" className="form-control" placeholder="Search" onKeyUp={this.changeUserList} />
									</form>
									<span className="glyphicon glyphicon-search form-control-feedback"></span> */}
									<AsyncPaginate
										debounceTimeout={1000}
										value={value}
										loadOptions={this.loadOptions}
										onChange={this.onChange}
										isClearable={true}
										isMulti
										closeMenuOnSelect={false}
										placeholder="Select tag"
										isDisabled={this.props.selectedComments.length > 0 || this.props.selectedMessages.length >0 || this.props.selectedvMessages.length >0 || this.props.selectediComments.length > 0 ? false : true}
									/>
								</div>
							</div>
						</div>
						{this.props.match.params.project_id === '5d3451b9b387aa4c9c231d5e' && localStorage.getItem('userData') && JSON.parse(localStorage.getItem('userData')).role === 'query_manager' ? null : <div className="col-sm-2" style={ {'padding': '0px'} }>
							<a href="#/" onClick={this.props.modal.bind(this, 'add_tag_modal')} type="text" className="btn add-tag" data-toggle="modal" title="Add a new tag"><i className="fa fa-plus"></i></a>
						</div> }
					</div>

					{this.state.value.length > 0 ? <div className="row">
						<div className="col-sm-12 text-right">
							<a href="#/" onClick={this.addTag} className="btn add-tag" title="Confirm">Confirm Tag</a>
						</div>
					</div> : null}

					<h4>Popular Tags</h4>
					{this.props.popularTags.map((tag, index) => (
						<span className="tags" key={index} onClick={this.setTagsValue.bind(this, [{label: tag._id, value: tag._id}])}>
							{tag._id}
						</span>
					))}
				</div>
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		popularTags: state.inbox_state.popularTags,
		selectedMessages: state.message_state.selectedMessages,
		selectedComments: state.comment_state.selectedComments,
		selectediComments: state.instagram_state.selectedComments,
		selectedvMessages: state.viber_state.selectedMessages,
		csrf: state.site_state.csrf
  };
}

const mapActionToProps = dispatch => {
	return {
		addMessageTag: (type, tags, ids, csrf) => dispatch(actions.addMessageTag(type, tags, ids, csrf)),
		addCommentTag: (type, tags, ids, csrf) => dispatch(actions.addCommentTag(type, tags, ids, csrf)),
		addiCommentTag: (type, tags, ids, csrf) => dispatch(actions.addiCommentTag(type, tags, ids, csrf)),
		addvMessageTag: (type, tags, ids, csrf) => dispatch(actions.addvMessageTag(type, tags, ids, csrf)),
	};
};


export default withRouter(connect(mapStateToProps, mapActionToProps)(Tag));