import React from 'react';
import { connect } from 'react-redux';
import loader from '../../../assets/img/loader.svg';

const msToHMS = ( ms ) => {
    var seconds = ms / 1000;
	var hours = parseInt( seconds / 3600 );
    seconds = seconds % 3600;
    var minutes = parseInt( seconds / 60 );
    seconds = parseInt(seconds % 60);
    return ("0" + hours).slice(-2)+":"+("0" + minutes).slice(-2)+":"+("0" + seconds).slice(-2);
}

const numberWithCommas = (x) => {
	var parts = x.toString().split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return parts.join(".");
}

const PerformanceSection = (props) => {
	return (
		<div className="qm-performence-table">
			<div className="panel panel-default">
				<div className="panel-heading">
					<h3 className="panel-title">Query Manager Performance</h3>
				</div>
				<div className="panel-body" style={{textAlign: 'center'}}>
					{props.performanceloading ? <img src={loader} alt="loader" /> : props.performance && props.performance.length > 0 ? <div className="table-responsive">
						<table className="table table-bordered table-hover">
							<thead>
								<tr>
									<th>Name</th>
									<th>Avg. Response Time</th>
									<th>No. Of Queries Resolved</th>
									<th>No. Of Replies Made</th>
									<th>No. Of Comments Hidden</th>
									<th>No. Of Comments Deleted</th>
									<th>No. Of Comments Liked</th>
									<th>No. of Resolved without Reply</th>
									<th>Number of Tags made</th>
								</tr>
							</thead>
							<tbody>
								{props.performance.map((perf, index) => (
									perf.name !== '' && perf.name ? <tr key={index}>
										<td>{perf.name}</td>
										<td>{perf.resolved_count !== 0 ? msToHMS(perf.total_response_time/perf.resolved_count) : 0}</td>
										<td>{numberWithCommas(perf.resolved_count)}</td>
										<td>{numberWithCommas(perf.no_of_messages_replied)}</td>
										<td>{numberWithCommas(perf.hide_count)}</td>
										<td>{numberWithCommas(perf.delete_count)}</td>
										<td>{numberWithCommas(perf.like_count)}</td>
										<td>{numberWithCommas(perf.resolved_without_reply)}</td>
										<td>{numberWithCommas(perf.tagged_count)}</td>
									</tr> : null
								))}
							</tbody>
						</table>
					</div> : <p>No data available</p>}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		performanceloading: state.report_state.performanceloading,
		performance: state.report_state.performance
	};
}

export default connect(mapStateToProps)(PerformanceSection);