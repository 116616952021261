import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { getUserData } from '../../services/UserService';

const AddProject = (props) => {
	let showHide;
	if(getUserData().role === 'company') {
		//nhide = props.total_projects < 2 ? 'hide_add_project_notice hide' : props.trial ? 'hide_add_project_notice' : 'hide_add_project_notice hide';
		if(props.total_projects < 2) {
			if(props.trial) {
				showHide = 'col-sm-3 add-project-card-container';
			} else {
				showHide = 'col-sm-3 add-project-card-container disablePoint';
			}
		} else {
			if(props.billing && (props.billing === 'no_billing' || props.billing === 'billing_running')) {
				showHide = 'col-sm-3 add-project-card-container';
			} else {
				showHide = 'col-sm-3 add-project-card-container disablePoint';
			}
		}
	} else {
		//nhide = 'hide_add_project_notice hide';
		showHide = 'col-sm-3 add-project-card-container';
	}
	return (
		<>
			<div className={showHide}>
				<div className="add-project text-center">
					<div className="add-project-icon">
						<a href="#/" onClick={props.modal.bind(this, 'add_project_modal')} data-toggle="modal" title="Add Project">
							<OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Add Project</Tooltip>}>
								<span>+</span>
							</OverlayTrigger>
						</a>
					</div>
					<OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Total Unresolved Query Count</Tooltip>}>
						<div className="total-ur">
							<a href="#/"><span className="count">{props.total_unresolved_count}</span></a>
						</div>
					</OverlayTrigger>
				</div>
			</div>
		</>
	);
};

export default AddProject;