import React, { Component } from 'react';

class Tbar extends Component {
	render() {
		return (
			<div className="trainer-control-bar">
				<div className="left-side">
					<img src={this.props.project_image} alt={this.props.project_title} title={this.props.project_title} className="projectLogo" />
				</div>
				<div className="right-side">
					<button id="samples" type="button" className="btn">Samples</button>
					<button id="reply_maker" type="button" className="btn">Reply Maker</button>
				</div>
			</div>
		);
	}
}

export default Tbar;