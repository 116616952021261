import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import * as userService from '../../services/UserService';
import * as cookieService from '../../services/CookieService';

class Logout extends Component {
	componentWillMount() {
		userService.logout().then(response => {
			cookieService.deleteCookie('__sign_h_p_l');
			localStorage.clear();
			this.props.history.push('/');
		}).catch(err => {});
	}

	render() {
		return (
			<div className="Logout"></div>
		);
	}
}

export default withRouter(Logout);