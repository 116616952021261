import React, { Component } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import * as actions from '../../store/actions/report_actions';

class RfilterBar extends Component {

	state = {
		intentOptions: [{
			value: '',
			label: 'Any'
		}],
		selectedIntent: ''
	};

	handleIntentChange = (selectedIntent) => {
		var formattedIntent = '';
		if(selectedIntent.hasOwnProperty('value') && selectedIntent.hasOwnProperty('label')) {
			formattedIntent = selectedIntent.value;
		} else {
			formattedIntent = '';
		}

		this.setState({
			selectedIntent: formattedIntent
		});
	}

	changeData = (project_id, platform, skip, keyword, start_date, end_date) => {
		this.props.getDatabank(project_id, platform, skip, keyword, start_date, end_date);
	}

	componentDidMount() {
		this.props.intents.forEach(intent => {
			this.setState(prevstate => ({
				intentOptions: prevstate.intentOptions.concat({ value: intent.title, label: intent.title })
			}));
		});
	}

	manage = () => {
		this.props.manage();
	}

	render() {
		return (
			<div className="analytics-control-bar">
				<div className="control-bar-inner">
					<div className="left-side-control">
						<div className="row">
							<div className="col-sm-2 col-sm-offset-10">
								<div style={{marginTop: '10px'}}>
									<Select onChange={this.handleIntentChange} options={this.state.intentOptions} placeholder="Select intent" />
								</div>
							</div>
						</div>
					</div>
					<div className="right-side-control">
						<div className="run-query text-right">
							<button type="button" className="btn" onClick={this.props.getSuggestions.bind(this, this.props.match.params.project_id, this.state.selectedIntent)} style={{marginRight: '10px'}}>Go</button>
							<button type="button" className="btn" onClick={this.manage}>+</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapActionToProps = dispatch => {
	return {
		getSuggestions: (project_id, intent) => dispatch(actions.getSuggestions(project_id, intent))
	};
};

export default withRouter(connect(null, mapActionToProps)(RfilterBar));