import React from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as yup from 'yup';
import { addFixedReply } from '../../../services/ProjectService';

const FixedReply = (props) => {
	let schema = yup.object({
		message: yup.string().required('You must mention fixed reply text!')
	});

	let initialValues = { message: '' };

	return (
		<Modal className="fixed-reply" show={props.show} onHide={props.manage.bind(this, 'fr_modal')}>
			<Formik
				initialValues={initialValues}
				validationSchema={schema} 
				onSubmit={(values, { setSubmitting, resetForm }) => {
					addFixedReply(props.project_id, values.message)
						.then(response => {
							if(response.data) {
								toast.success("😀 Fixed reply was added successfully!", {
									autoClose: 2000,
									position: 'bottom-right',
									className: 'notification-message'
								});
								setSubmitting(false);
								resetForm();
							}
						})
						.catch(err => {
							setSubmitting(false);
						});
				}}
			>
				{({
						values,
						touched,
						errors,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit
					}) => (
							<form onSubmit={handleSubmit}>
								<Modal.Header closeButton>
									<Modal.Title>Fixed Reply</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<div className="form-group">
										<textarea name="message" id="message_text" value={values.message} onChange={handleChange} onBlur={handleBlur} placeholder="Write fixed reply here. Use '{user}' to populate customer name" className={
											errors.message && touched.message ? 'form-control error' : 'form-control'
										}></textarea>
										{errors.message && touched.message && <div className="input-feedback">{errors.message}</div>}
									</div>
								</Modal.Body>
								<Modal.Footer>
									<button type="submit" disabled={isSubmitting} className="btn btn-primary">Submit</button>
								</Modal.Footer>
							</form>
				)}
			</Formik>
		</Modal>
	);
};

export default FixedReply;