import React from 'react';

const Wrapper = (props) => {
	return (
		<div id="wrapper" className="toggled-2">
			<div id="page-content-wrapper">
				<div className="page-content">
					{props.children}
				</div>
			</div>
		</div>
	);
};

export default Wrapper;