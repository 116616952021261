import React, { Component } from 'react';
import { connect } from 'react-redux';
import Profile from './Profile/Profile';
import Tag from './Tag/Tag';
import { Scrollbars } from 'react-custom-scrollbars';

class RightSection extends Component {
	constructor(props) {
		super(props);
		this.adjustScreen = this.adjustScreen.bind(this);
	}

	offset = (el) => {
		var rect = el.getBoundingClientRect(),
			scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
			scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		return {
			top: rect.top + scrollTop,
			left: rect.left + scrollLeft
		}
	}

	adjustScreen = () => {
		document.querySelectorAll('.right-section')[0].style.height = (window.innerHeight - 52 - this.offset(document.getElementsByClassName("right-section")[0]).top) + "px";
	}

	componentDidMount() {
		this.adjustScreen();
		window.addEventListener("resize", this.adjustScreen);
	}

	componentWillUnmount() {
		window.removeEventListener("resize",  this.adjustScreen);
	}

	render() {
		return (
			<div className="col-sm-3 right-section">
				<Scrollbars style={{ height: '100%' }}>
					<div className="profile-details">
						{ this.props.currentUser.hasOwnProperty('psid') || this.props.currentUser.hasOwnProperty('id') || this.props.currentUser.hasOwnProperty('username') ? 
						<>
							<Profile />
							<Tag modal={this.props.modal} />
						</> : null }
					</div>
				</Scrollbars>
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		currentUser: state.inbox_state.currentUser
  };
}

export default connect(mapStateToProps)(RightSection);