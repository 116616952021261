import React from 'react';
import { withRouter } from 'react-router-dom';

import MessengerSection from './MessengerSection/MessengerSection';
import FbCommentSection from './FbCommentSection/FbCommentSection';
import ViberSection from './ViberSection/ViberSection';
import InstagramSection from './InstagramSection/InstagramSection';

const MiddleSection = (props) => {
	let section;

	if(props.match.params.platform === 'messenger') {
		section = (
			<div className="conversation-section">
				<MessengerSection modal={props.modal} />
			</div>
		);
	} else if(props.match.params.platform === 'facebook') {
		section = (
			<div className="facebook-post-section">
				<FbCommentSection modal={props.modal} />
			</div>
		);
	} else if(props.match.params.platform === 'viber') {
		section = (
			<div className="conversation-section">
				<ViberSection modal={props.modal} />
			</div>
		);
	} else if(props.match.params.platform === 'instagram') {
		section = (
			<div className="conversation-section">
				<InstagramSection modal={props.modal} />
			</div>
		);
	} else {
		section = null;
	}
	
	return (
		<div className="col-sm-6 middle-section">
			{ section }
		</div>
	);
};

export default withRouter(MiddleSection);