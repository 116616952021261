import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../store/actions/report_actions';
import { updateSuggestion } from '../../../services/ReportService';

class ReplySuggestion extends Component {
	schema = yup.object({
		intent: yup.string().required('Intent is required'),
		entities: yup.array().of(
			yup.object().shape({
				label: yup.string().required(),
				value: yup.string().required(),
			})
		),
		suggestion: yup.string().required('Suggestion is required')
	});

	manage = () => {
		this.props.manage();
	}

	render() {
		return (
			<Modal className="add-project-modal" show={this.props.show} onHide={this.manage}>
				<Formik
					initialValues={{ intent: this.props.default_intent, entities: this.props.default_entities, suggestion: this.props.default_suggestion }}
					validationSchema={this.schema} 
					onSubmit={(values, { setSubmitting }) => {
						// process
						let formData = {};
						if(values.intent && values.intent.value && values.intent.value !== '') {
							formData.intent = values.intent.value;
						} else if(values.intent && values.intent.length > 0) {
							formData.intent = values.intent[0].value;
						}
						if(values.entities && values.entities.length > 0) {
							formData.entities = values.entities.map(entity => entity.value);
						}
						formData.suggestion = values.suggestion;
						formData.project_id = this.props.project_id;
						updateSuggestion(formData)
							.then(response => {
								if(response.data) {
									this.manage();
									setSubmitting(false);
									this.props.updateReplies(response.data);
									toast.success("😀 Reply suggestion was updated successfully", {
										autoClose: 5000,
										position: 'bottom-right',
										className: 'notification-message'
									});
								}
							})
							.catch(err => {
								setSubmitting(false);
							});
					}}
				>
					{({
						values,
						touched,
						errors,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit,
						setFieldValue,
						setFieldTouched
					}) => (
						<form onSubmit={handleSubmit}>
							<Modal.Header closeButton>
								<Modal.Title>Add/Edit Reply Suggestion</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div className="form-group">
									<label className="control-label">Intent</label>
									<Select isDisabled={this.props.id && this.props.id !== '' ? true : false} value={values.intent} onChange={(value) => setFieldValue('intent', value)} onBlur={(value) => setFieldTouched('intent', value)} options={this.props.intents.map(intent => ({value: intent, label: intent}))} name="intent" placeholder="Select intent" className={
										errors.intent && touched.intent ? 'form-controls error' : 'form-controls'
									} />
									{errors.intent && touched.intent && <div className="input-feedback">{errors.intent}</div>}
								</div>
								<div className="form-group">
									<label className="control-label">Entities</label>
									<Select isDisabled={this.props.id && this.props.id !== '' ? true : false} value={values.entities} isMulti={true} onChange={(value) => setFieldValue('entities', value)} onBlur={(value) => setFieldTouched('entities', value)} options={this.props.entities.map(entity => ({value: entity, label: entity}))}  name="entities" placeholder="Select entities" className={
										errors.entities && touched.entities ? 'form-controls error' : 'form-controls'
									} />
									{errors.entities && touched.entities && <div className="input-feedback">{errors.entities}</div>}
								</div>
								<div className="form-group">
									<label className="control-label">Reply Suggestion</label>
									<textarea name="suggestion" value={values.suggestion} onChange={handleChange} onBlur={handleBlur} placeholder="Write reply suggestion here." className={
										errors.suggestion && touched.suggestion ? 'form-control error' : 'form-control'
									}></textarea>
									{errors.suggestion && touched.suggestion && <div className="input-feedback">{errors.suggestion}</div>}
								</div>
							</Modal.Body>
							<Modal.Footer className="text-right">
								<button type="submit" disabled={isSubmitting} className="btn btn-primary">Submit </button>
							</Modal.Footer>
						</form>
					)}
				</Formik>
			</Modal>
		);
	}
}

const mapActionToProps = dispatch => {
	return {
		updateReplies: (data) => dispatch(actions.updateReplies(data))
	};
};

export default withRouter(connect(null, mapActionToProps)(ReplySuggestion));