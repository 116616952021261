import axios from '../api/private';

export const getUsers = (project_id, skip = 0, limit = process.env.REACT_APP_MSG_USER_PER_PAGE, keyword = '', start_date = null, end_date = null, followup_status = '', fcom_status = '') => {
	return axios.get('/messages/' + project_id, {
		params: {
			skip: skip,
			limit: limit,
			keyword: keyword,
			start_date: start_date,
			end_date: end_date,
			followup: followup_status,
			fcom: fcom_status
		}
	});
};

export const getConversation = (project_id, user_id, skip = 0, limit = process.env.REACT_APP_MSG_PER_PAGE) => {
	return axios.get('/messages/' + project_id + '/' + user_id, {
		params: {
			skip: skip,
			limit: limit
		}
	});
};

export const getThread = (project_id, psid) => {
	return axios.get('/thread_control/' + project_id, {
		params: {
			psid: psid
		}
	});
};

export const updateThread = (project_id, status, user_id, csrf) => {
	return axios.post('/thread_update/' + project_id, {
		status: status,
		userid: user_id,
		_csrf: csrf
	});
};

export const transferThread = (project_id, user_id, csrf) => {
	return axios.post('/thread_transfer/' + project_id, {
		psid: user_id,
		_csrf: csrf
	});
};

export const replyMessage = (formData) => {
	return axios.post('/messages/reply', formData);
}

export const followupMessage = (project_id, status, user_id, csrf) => {
	return axios.post('/message_followup/' + project_id, {
		status: status,
		userid: user_id,
		_csrf: csrf
	});
};

export const fcomMessage = (project_id, status, user_id, csrf) => {
	return axios.post('/message_fcom/' + project_id, {
		status: status,
		userid: user_id,
		_csrf: csrf
	});
};