import React from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { editFixedReply } from '../../../services/ProjectService';
import * as actions from '../../../store/actions/inbox_actions';

const EditFixedReply = (props) => {
	let schema = yup.object({
		message: yup.string().required('You must mention fixed reply text!')
	});

	let initialValues = { message: props.replies };

	return (
		<Modal className="fixed-reply" show={props.show} onHide={props.manage.bind(this, 'add_efr_modal')}>
			<Formik
				initialValues={initialValues}
				validationSchema={schema} 
				onSubmit={(values, { setSubmitting, resetForm }) => {
					editFixedReply(props.project_id, props.replies, values.message)
						.then(response => {
							if(response.data) {
								toast.success("😀 Fixed reply was updated successfully!", {
									autoClose: 2000,
									position: 'bottom-right',
									className: 'notification-message'
								});
								setSubmitting(false);
								resetForm();
								props.manage('add_efr_modal');
								props.fixedReplies(props.project_id);
							}
						})
						.catch(err => {
							setSubmitting(false);
						});
				}}
			>
				{({
						values,
						touched,
						errors,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit
					}) => (
							<form onSubmit={handleSubmit}>
								<Modal.Header closeButton>
									<Modal.Title>Edit Fixed Reply</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<div className="form-group">
										<textarea name="message" id="message_text" value={values.message} onChange={handleChange} onBlur={handleBlur} placeholder="Write fixed reply here. Use '{user}' to populate customer name" className={
											errors.message && touched.message ? 'form-control error' : 'form-control'
										}></textarea>
										{errors.message && touched.message && <div className="input-feedback">{errors.message}</div>}
									</div>
								</Modal.Body>
								<Modal.Footer>
									<button type="submit" disabled={isSubmitting} className="btn btn-primary">Submit</button>
								</Modal.Footer>
							</form>
				)}
			</Formik>
		</Modal>
	);
};

const mapActionToProps = dispatch => {
    return {
			fixedReplies: (project_id) => dispatch(actions.fixedReplies(project_id))
    };
};

export default connect(null, mapActionToProps)(EditFixedReply);