import * as projectService from '../../services/ProjectService';

// set csrf
export const SET_CSRF = 'SET_CSRF';
export const setaCsrf = (csrf) => {
	return {
		type: SET_CSRF,
		csrf: csrf
	}
};

export const setCsrf = () => {
	return dispatch => {
		projectService.genCsrf()
		.then(response => {
			dispatch(setaCsrf(response.data));
		})
		.catch(err => {});
	}
};