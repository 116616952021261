import React from 'react';
import Header from '../Header/Header';
import Conversation from './Conversation/Conversation';

const ViberSection = (props) => {
	return (
		<>
			<Header />
			<Conversation modal={props.modal} />
		</>
	);
};

export default ViberSection;