import React from 'react';
import { Modal } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { toast } from 'react-toastify';

const FbPageList = (props) => {
	const handleError = () => {
		toast.error("😪 Couldn't subscribe/unsubscribe due to en error!", {
			autoClose: 5000,
			position: 'bottom-right',
			className: 'notification-message'
		});
	};
	
	const subscribe = (page_id, access_token, user_access_token) => {
		window.FB.api('/' + page_id + '/subscribed_apps', 'POST', {
			access_token: access_token,
			subscribed_fields: 'feed,messages,message_echoes,messaging_postbacks,messaging_optins,messaging_account_linking,messaging_referrals,standby,messaging_handovers'
		}, function(response) {
			if (!response || response.error) {
				handleError();
			} else {
				props.subscribe(page_id, props.project_id, user_access_token);
			}
		});
	}
	
	const unsubscribe = (page_id, access_token) => {
		window.FB.api('/' + page_id + '/subscribed_apps', 'DELETE', {
			access_token: access_token
		}, function(response) {
			if (!response || response.error) {
				handleError();
			} else {
				props.unsubscribe(page_id, props.project_id);
			}
		});
	}

	return (
		<Modal className="fb-page-list" show={props.show} onHide={props.manage.bind(this, 'fb_page_list_modal')}>
			<Modal.Header closeButton>
				<Modal.Title>List of your Pages</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="col-sm-12">
						<Scrollbars style={{ height: '400px' }}>
						{props.pages.map(page => (
							<div className="page-list-item" key={page.id}>
								<div className="row">
									<div className="col-sm-9 text-left">
										<h2 className="page-title">
											{page.name}
										</h2>
									</div>
									<div className="col-sm-3 text-center">
										<div className="page-subscribe-btn">
											{!page.subscribed ? <span className="btn btn-primary" onClick={() => subscribe(page.id, page.access_token, props.user_access_token)}>Subscribe</span> : null}
											{page.subscribed && (page.project_id === props.project_id) ? <span className="btn btn-primary subscribed" onClick={() => unsubscribe(page.id, page.access_token)}>Unsubscribe</span> : null}
										</div>
									</div>
								</div>
							</div>
						))}
						</Scrollbars>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default FbPageList;