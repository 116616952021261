import * as actions from '../actions/inbox_actions';
import _ from 'lodash';

const initialState = {
	userList: [],
	userCount: 0,
	currentMessages: [],
	currentComment: {},
	currentCommentId: '',
	currentMediaId: '',
	messageSuggestions: [],
	skip_user: 0,
	keyword: '',
	start_date: null,
	end_date: null,
	selectedComments: []
};

const searchArray = (elementKey, myArray, element) => {
    for (var i=0; i < myArray.length; i++) {
		if(myArray[i][element]) {
			if (myArray[i][element] === elementKey) {
				return i;
			}
		}
	}
	return false;
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.CLEAR_ICOMMENTS:
			const clearedComments = _.cloneDeep(state);
			clearedComments.userList = [];
			clearedComments.userCount = 0;
			clearedComments.currentMessages = [];
			clearedComments.currentComment = {};
			clearedComments.currentMediaId = '';
			clearedComments.currentCommentId = '';
			clearedComments.messageSuggestions = [];
			clearedComments.skip_user = 0;
			clearedComments.keyword = '';
			clearedComments.start_date = null;
			clearedComments.end_date = null;
			clearedComments.selectedComments = [];
			return clearedComments
		case actions.CLEAR_CURRENT_ICOMMENTS:
			const clearedCurrentComments = _.cloneDeep(state);
			clearedCurrentComments.currentMessages = [];
			clearedCurrentComments.currentComment = {};
			clearedCurrentComments.currentMediaId = '';
			clearedCurrentComments.currentCommentId = '';
			clearedCurrentComments.messageSuggestions = [];
			clearedCurrentComments.selectedComments = [];
			return clearedCurrentComments
		case actions.SEARCH_INSTAGRAM_USERS:
			const searchedState = _.cloneDeep(state);
			searchedState.userList = action.users.comments;
			if(action.users.hasOwnProperty('count')) {
				searchedState.userCount = action.users.count;
			}
			searchedState.currentMessages = [];
			searchedState.currentComment = {};
			searchedState.messageSuggestions = [];
			searchedState.skip_user = action.users.comments.length;
			searchedState.keyword = action.keyword;
			searchedState.start_date = action.start_date;
			searchedState.end_date = action.end_date;
			return searchedState
		case actions.GET_INSTAGRAM_USERS:
			const getInstagramUsers = _.cloneDeep(state);
			if(action.users.hasOwnProperty('count')) {
				getInstagramUsers.userCount = action.users.count;
			}
			getInstagramUsers.skip_user = state.skip_user + action.users.comments.length;
			getInstagramUsers.keyword = action.keyword;
			getInstagramUsers.start_date = action.start_date;
			getInstagramUsers.end_date = action.end_date;
			getInstagramUsers.userList = getInstagramUsers.userList.concat(action.users.comments);
			return getInstagramUsers;
		case actions.GET_ICOMMENT_CONVERSATION:
			const conversations = _.cloneDeep(state);
			conversations.currentMessages = action.conversation.comments;
			conversations.currentMediaId = action.conversation.media_id;
			conversations.currentCommentId = action.comment_id;
			conversations.messageSuggestions = action.conversation.suggestions;
			conversations.currentComment = action.comment
			return conversations
		case actions.NEW_ICOMMENT:
			const newComment = _.cloneDeep(state);
			if (action.comment.comment.sender_is && action.comment.comment.sender_is === 'user') {
				let found_id = searchArray(action.comment.comment._id, newComment.userList, '_id');
				if (found_id === false) {
					if ((!newComment.keyword || newComment.keyword === '') && !newComment.start_date && !newComment.end_date) {
						newComment.userList = newComment.userList.concat(action.comment.comment);
						newComment.userCount = newComment.userCount + 1;
						newComment.skip_user = newComment.skip_user + 1;
					}
				} else {
					// update comment
					newComment.userList[found_id] = action.comment.comment;
				}

				if (newComment.currentMessages.length > 0) {
					found_id = searchArray(action.comment.comment._id, newComment.currentMessages, '_id');
					if (found_id !== false) {
						newComment.currentMessages[found_id] = action.comment.comment;
						newComment.currentComment = action.comment.comment;
					}
				}
			} else {
				if (newComment.currentMessages.length > 0) {
					let found_id = searchArray(action.comment.comment.reply_of, newComment.currentMessages, 'comment_id');
					if (found_id !== false) {
						newComment.currentMessages = newComment.currentMessages.concat(action.comment.comment);
					}
				}
			}
			return newComment
		case actions.CHANGE_CURRENT_ICOMMENT_STATUS:
			const currentComment = _.cloneDeep(state);
			currentComment.currentComment.resolved_status = action.status;
			return currentComment
		case actions.SELECT_ICOMMENT:
			const selectedComment = _.cloneDeep(state);
			if (selectedComment.selectedComments.includes(action.id)) {
				selectedComment.selectedComments.splice(selectedComment.selectedComments.indexOf(action.id), 1);
			} else {
				selectedComment.selectedComments = selectedComment.selectedComments.concat(action.id);
			}
			return selectedComment
		case actions.ADD_ICOMMENT_TAG:
			const tagged = _.cloneDeep(state);
			action.ids.forEach(id => {
				let comment = tagged.currentMessages.find(obj => {
					return obj._id === id
				});
				if (!comment.tags) comment.tags = [];
				action.tags.forEach(tag => {
					let found = comment.tags.find(obj => {
						return obj.title === tag
					});
					if (!found) {
						comment.tags.push({
							title: tag
						});
					}
				});
			});
			tagged.selectedComments = [];
			return tagged
		case actions.REMOVE_ICOMMENT_TAGS:
			const rtagged = _.cloneDeep(state);
			let comment = rtagged.currentMessages.find(obj => {
				return obj._id === action.id
			});
			let found = comment.tags.find(obj => {
				return obj.title === action.tag
			});
			if (found) {
				let myArray = comment.tags.filter(function (obj) {
					return obj.title !== action.tag;
				});
				comment.tags = myArray;
			}
			rtagged.selectedComments = [];
			return rtagged
		default:
			break;
	}

	return state;
}
export default reducer;