import axios from 'axios';
import { toast } from 'react-toastify';
import * as cookieService from '../services/CookieService';

const instance = axios.create({
	baseURL: process.env.REACT_APP_SERVER_URL,
	withCredentials: true
});

//if(localStorage.getItem('userData')) instance.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(localStorage.getItem('userData')).access_token;
// error handling
instance.interceptors.response.use(function (response) {
	return response;
}, function (error) {
	if (error.response) {
		if(error.response.status === 401) {
			toast.error("😪 You are not authorized! We are logging you out.", {
				autoClose: 3000,
				position: 'bottom-right',
				className: 'notification-message'
			});
			setTimeout(function(){
				cookieService.deleteCookie('__sign_h_p_l');
				localStorage.clear();
				window.location.replace(process.env.REACT_APP_CLIENT_URL + '/logout');
			}, 2000);
		} else {
			if(error.response.data.message) {
				toast.error("😪 " + error.response.data.message, {
					autoClose: false,
					position: 'bottom-right',
					className: 'notification-message'
				});
				if(error.response.data.message === 'Authentication failed') {
					setTimeout(function(){
						cookieService.deleteCookie('__sign_h_p_l');
						localStorage.clear();
						window.location.replace(process.env.REACT_APP_CLIENT_URL + '/logout');
					}, 2000);
				}
			} else {
				toast.error("😪 There was a problem processing your request!", {
					autoClose: 5000,
					position: 'bottom-right',
					className: 'notification-message'
				});
			}
		}
	} else {
		toast.error("😪 There was an error processing your request!", {
			autoClose: 5000,
			position: 'bottom-right',
			className: 'notification-message'
		});
	}
	return Promise.reject(error);
});

export default instance;