import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import Wrapper from '../../components/Wrapper/Wrapper';
import Tbar from '../../components/Tbar/Tbar';
import TrainerContent from '../../components/TrainerContent/TrainerContent';

class Trainer extends Component {
	componentDidMount() {
		document.title = 'Trainer - listenyzen';
		document.body.classList.add('main-body');
	}

	componentWillUnmount() {
		document.body.classList.remove('main-body');
	}

	render() {
		return (
			<div className="Dashboard">
				<Header />
				<Wrapper>
					<div className="analytics-dashboard">
						<div className="container-fluid">
							<Tbar />
							<TrainerContent />
						</div>
					</div>
				</Wrapper>
			</div>
		);
	}
};

export default Trainer;