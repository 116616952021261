import axios from '../api/private';

export const getUsers = (project_id, skip = 0, limit = process.env.REACT_APP_CMT_USER_PER_PAGE, keyword='', start_date = null, end_date = null) => {
	return axios.get('/icomment/' + project_id, {
		params: {
		  skip: skip,
		  limit: limit,
		  keyword: keyword,
		  start_date: start_date,
		  end_date: end_date
		}
	});
};

export const getConversation = (project_id, comment_id) => {
	return axios.get('/icomment/' + project_id + '/' + comment_id);
};

export const replyInstagram = (formData) => {
	return axios.post('/icomment/reply', formData);
}

export const hidesComment = (project_id, comment_id, status, csrf) => {
	return axios.post('/icomment/hide/' + project_id + '/' + comment_id, {
		is_hidden: status,
		_csrf: csrf
	});
}

export const deletesComment = (project_id, comment_id, csrf) => {
	return axios.post('/icomment/delete/' + project_id + '/' + comment_id, {
		_csrf: csrf
	});
}