import React from 'react';
import { connect } from 'react-redux';
import loader from '../../../assets/img/loader.svg';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';

const RvrLineChart = (props) => {
	return (
		<div className="query-resolve-graph">
			<div className="panel panel-default">
				<div className="panel-heading">
					<h3 className="panel-title">Queries Received vs Resolved</h3>
				</div>
				<div className="panel-body" style={{height: '753px', textAlign: 'center'}}>
					{props.qrrloading ? <img src={loader} alt="loader" className="aligncenter" /> : props.dwrr && props.dwrr.length > 0 ? <ResponsiveContainer height="100%">
						<LineChart data={props.dwrr.sort(function(a, b){
							var aa = a._id.split('-').join(),
								bb = b._id.split('-').join();
							return aa < bb ? -1 : (aa > bb ? 1 : 0);
						})} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
							<Line type="monotone" dataKey="received_count" stroke="red" />
							<Line type="monotone" dataKey="resolved_count" stroke="#4ea752" />
							<CartesianGrid stroke="#ebf3f0" />
							<XAxis dataKey="_id" />
							<YAxis />
							<Tooltip />
						</LineChart>
					</ResponsiveContainer> : <p className="aligncenter">No data available</p>}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		qrrloading: state.report_state.qrrloading,
		dwrr: state.report_state.dwrr
	};
}

export default connect(mapStateToProps)(RvrLineChart);