import * as messageService from '../../services/MessageService';
import * as commentService from '../../services/CommentService';
import * as viberService from '../../services/ViberService';
import * as instagramService from '../../services/InstagramService';
import * as projectService from '../../services/ProjectService';
import * as classService from '../../services/ClassService';

///////////////////////// Inbox Actions //////////////////////
// update project info
export const PROJECT_INFO = 'PROJECT_INFO';
export const projectaInfo = (project_name, project_photo, messenger_unresolved_count, facebook_unresolved_count, viber_unresolved_count, instagram_unresolved_count, facebook_connected, instagram_connected, viber_connected, bot_enabled) => {
	return {
		type: PROJECT_INFO,
		project_image: project_photo,
		project_title: project_name,
		messenger_unresolved_count: messenger_unresolved_count,
		facebook_unresolved_count: facebook_unresolved_count,
		viber_unresolved_count: viber_unresolved_count,
		instagram_unresolved_count: instagram_unresolved_count,
		facebook_connected: facebook_connected,
		instagram_connected: instagram_connected,
		viber_connected: viber_connected,
		bot_enabled: bot_enabled
	}
}
export const projectInfo = (project_id) => {
	return dispatch => {
		projectService.getProject(project_id)
		.then(response => {
			dispatch(projectaInfo(response.data.name, response.data.photo, response.data.messenger_unresolved_count, response.data.facebook_unresolved_count, response.data.viber_unresolved_count, response.data.instagram_unresolved_count, response.data.facebook_connected, response.data.instagram_connected, response.data.viber_connected, response.data.bot_enabled));
		})
		.catch(err => {});
	}
};

// fixed replies
export const FIXED_REPLIES = 'FIXED_REPLIES';
export const fixedaReplies = (fixed_replies) => {
	return {
		type: FIXED_REPLIES,
		fixed_replies: fixed_replies
	}
}

export const fixedReplies = (project_id) => {
	return dispatch => {
		projectService.getFixedReplies(project_id)
		.then(response => {
			dispatch(fixedaReplies(response.data.fixed_replies));
		})
		.catch(err => {});
	}
};

// popular tags
export const POPULAR_TAGS = 'POPULAR_TAGS';
export const popularaTags = (tags) => {
	return {
		type: POPULAR_TAGS,
		tags: tags
	}
}

export const popularTags = (project_id) => {
	return dispatch => {
		projectService.getPopularTags(project_id)
		.then(response => {
			if(response.data && response.data.length > 0)
				dispatch(popularaTags(response.data));
		})
		.catch(err => {});
	}
};

// clear inbox
export const CLEAR_INBOX = 'CLEAR_INBOX';
export const clearInbox = () => {
	return {
		type: CLEAR_INBOX
	}
}

// clear platform
export const CLEAR_PLATFORM = 'CLEAR_PLATFORM';
export const clearPlatform = () => {
	return {
		type: CLEAR_PLATFORM
	}
}

// reset platform clicked status
export const CLEAR_PLATFORM_CLICKED = 'CLEAR_PLATFORM_CLICKED';
export const clearPlatformClicked = () => {
	return {
		type: CLEAR_PLATFORM_CLICKED
	}
}

// reset platform count active status
export const CLEAR_PLATFORM_COUNT = 'CLEAR_PLATFORM_COUNT';
export const clearPlatformCount = () => {
	return {
		type: CLEAR_PLATFORM_COUNT
	}
}

// clear popular tags
export const CLEAR_POP_TAGS = 'CLEAR_POP_TAGS';
export const clearPopularTags = () => {
	return {
		type: CLEAR_POP_TAGS
	}
}

// clear project info
export const CLEAR_PROJECT_INFO = 'CLEAR_PROJECT_INFO';
export const clearProjectInfo = () => {
	return {
		type: CLEAR_PROJECT_INFO
	}
}

// set left side loading
export const USER_LOADING = 'USER_LOADING';
export const userLoading = () => {
	return {
		type: USER_LOADING
	}
}

// set left side more loading
export const USER_MORE_LOADING = 'USER_MORE_LOADING';
export const userMoreLoading = () => {
	return {
		type: USER_MORE_LOADING
	}
}

// set right side loading
export const CONVERSATION_LOADING = 'CONVERSATION_LOADING';
export const conversationLoading = () => {
	return {
		type: CONVERSATION_LOADING
	}
}

// set right side more loading
export const CONVERSATION_MORE_LOADING = 'CONVERSATION_MORE_LOADING';
export const conversationMoreLoading = () => {
	return {
		type: CONVERSATION_MORE_LOADING
	}
}

// set current user
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const setCurrentUser = (user) => {
	return {
		type: SET_CURRENT_USER,
		user: user
	}
}

// clear current user
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';
export const clearCurrentUser = () => {
	return {
		type: CLEAR_CURRENT_USER
	}
}

// set left side more loading
export const PLATFORM_CLICKED = 'PLATFORM_CLICKED';
export const platformClicked = (platform) => {
	return {
		type: PLATFORM_CLICKED,
		platform: platform
	}
}

// set platform count active status
export const PLATFORM_COUNT = 'PLATFORM_COUNT';
export const platformCount = (platform) => {
	return {
		type: PLATFORM_COUNT,
		platform: platform
	}
}

///////////////////////// Messenger Actions ////////////////////////

// clear message
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const clearMessages = () => {
	return {
		type: CLEAR_MESSAGE
	}
}

// clear current messages
export const CLEAR_CURRENT_MESSAGES = 'CLEAR_CURRENT_MESSAGES';
export const clearCurrentMessages = () => {
	return {
		type: CLEAR_CURRENT_MESSAGES
	}
}

// update user list
export const UPDATE_USER_LIST = 'UPDATE_USER_LIST';
export const updateUserList = (user, index) => {
	return {
		type: UPDATE_USER_LIST,
		user: user,
		index: index
	}
}

// get message users from server
export const GET_MESSAGE_USERS = 'GET_MESSAGE_USERS';
export const getaMessageUsers = (users, keyword, start_date, end_date) => {
	return {
		type: GET_MESSAGE_USERS,
		users: users,
		keyword: keyword,
		start_date: start_date,
		end_date: end_date
	}
}
export const getMessageUsers = (project_id, skip, limit, keyword, start_date, end_date, loading = false) => {
	return dispatch => {
		messageService.getUsers(project_id, skip, limit, keyword, start_date, end_date)
		.then(response => {
			dispatch(getaMessageUsers(response.data, keyword, start_date, end_date));
			dispatch(platformClicked('messenger'));
			dispatch(platformCount('messenger'));
			if(loading) dispatch(userLoading());
			else dispatch(userMoreLoading());
		})
		.catch(err => {
			if(loading) dispatch(userLoading());
			else dispatch(userMoreLoading());
		});
	}
};

// search message users
export const SEARCH_MESSAGE_USERS = 'SEARCH_MESSAGE_USERS';
export const searchaMessageUsers = (users, keyword, start_date, end_date) => {
	return {
		type: SEARCH_MESSAGE_USERS,
		users: users,
		keyword: keyword,
		start_date: start_date,
		end_date: end_date
	}
}
export const searchMessageUsers = (project_id, keyword, start_date, end_date, followup_status, fcom_status) => {
	return dispatch => {
		dispatch(clearCurrentUser());
		messageService.getUsers(project_id, 0, process.env.REACT_APP_MSG_USER_PER_PAGE, keyword, start_date, end_date, followup_status, fcom_status)
		.then(response => {
			dispatch(searchaMessageUsers(response.data, keyword, start_date, end_date));
			dispatch(userLoading());
			if(document.getElementById("search_message")) document.getElementById("search_message").focus();
		})
		.catch(err => {
			dispatch(userLoading());
		});
	}
};

// get conversation from server
export const GET_CONVERSATION = 'GET_CONVERSATION';
export const getaConversation = (conversation, currentMessage) => {
	return {
		type: GET_CONVERSATION,
		conversation: conversation,
		currentMessage: currentMessage
	}
}
export const getConversation = (project_id, user, skip, limit, currentMessage, bot_enabled, index, csrf) => {
	return dispatch => {
		dispatch(clearCurrentMessages());
		dispatch(clearCurrentUser());
		dispatch(setCurrentUser(user));
		messageService.getConversation(project_id, user.psid, skip, limit)
		.then(response => {
			dispatch(conversationLoading());
			dispatch(getaConversation(response.data, currentMessage));

			if(bot_enabled) {
				if(!user.current_thread || (user.current_thread && user.current_thread === 'inbox')) {
					// we need to check whether this user is in normal mode or listenyzen mode
					messageService.getThread(project_id, user.psid)
					.then(response2 => {
						if(response2 && response2.data && response2.data.status && response2.data.status === 'inbox') {
							// we need to update last message table customer current_thread to "inbox"
							messageService.updateThread(project_id, 'inbox', user.psid, csrf)
							.then(response3 => {
								user.current_thread = 'inbox';
								dispatch(updateUserList(user, index));
							})
							.catch(err => {});
						} else if(response2 && response2.data && response2.data.app_id && response2.data.app_id === '585799875189161') {
							// we need to update last message table customer current_thread to "listenyzen"
							messageService.updateThread(project_id, 'listenyzen', user.psid, csrf)
							.then(response3 => {
								user.current_thread = 'listenyzen';
								dispatch(updateUserList(user, index));
							})
							.catch(err => {});
						} else if(response2 && response2.data && response2.data.app_id && response2.data.app_id !== '585799875189161') {
							// we need to update last message table customer current_thread to "bot"
							messageService.updateThread(project_id, 'bot', user.psid, csrf)
							.then(response3 => {
								user.current_thread = 'bot';
								dispatch(updateUserList(user, index));
							})
							.catch(err => {});
						} else {
							// // we need to update last message table customer current_thread to "normal"
							// messageService.updateThread(project_id, 'normal', user.psid, csrf)
							// .then(response3 => {
							// 	user.current_thread = 'normal';
							// 	dispatch(updateUserList(user, index));
							// })
							// .catch(err => {});
						}
					})
					.catch(err => {
						// do nothing
					});
				}
			}

			if(document.getElementById('conversation')) {
				document.getElementById('conversation').scrollTop = document.getElementById('conversation').scrollHeight;
				if(document.getElementById('reply-conversation')) document.getElementById('reply-conversation').focus();
			}
		})
		.catch(err => {
			dispatch(conversationLoading());
		});
	}
};

// load more conversation from server
export const LOAD_CONVERSATION = 'LOAD_CONVERSATION';
export const loadaConversation = (conversation) => {
	return {
		type: LOAD_CONVERSATION,
		conversation: conversation
	}
}
export const loadConversation = (project_id, user_id, skip, limit, scroll) => {
	return dispatch => {
		messageService.getConversation(project_id, user_id, skip, limit)
		.then(response => {
			dispatch(conversationMoreLoading());
			dispatch(loadaConversation(response.data));
			if(document.getElementById('conversation')) {
				document.getElementById('conversation').scrollTop = document.getElementById('conversation').scrollHeight - scroll;
				document.getElementById('conversation').style.pointerEvents = 'auto';
			}
		})
		.catch(err => {
			dispatch(conversationMoreLoading());
		});
	}
};

// new message from socket
export const NEW_MESSAGE = 'NEW_MESSAGE';
export const newMessage = (message, currentUser) => {
	return {
		type: NEW_MESSAGE,
		message: message,
		currentUser: currentUser
	}
}

// change current message status
export const CHANGE_CURRENT_MESSAGE_STATUS = 'CHANGE_CURRENT_MESSAGE_STATUS';
export const changeCurrentMessage = (status) => {
	return {
		type: CHANGE_CURRENT_MESSAGE_STATUS,
		status: status
	}
}

// select messages
export const SELECT_MESSAGE = 'SELECT_MESSAGE';
export const selectMessage = (id) => {
	if(document.getElementById(id)) {
		if(classService.hasClass(document.getElementById(id), 'selected')) {
			classService.removeClass(document.getElementById(id), 'selected');
		} else {
			classService.addClass(document.getElementById(id), 'selected');
		}
	}
	return {
		type: SELECT_MESSAGE,
		id: id
	}
}

// add message tags
export const ADD_MESSAGE_TAG = 'ADD_MESSAGE_TAG';
export const addaMessageTag = (tags, ids) => {
	return {
		type: ADD_MESSAGE_TAG,
		tags: tags,
		ids: ids
	}
}
export const addMessageTag = (type, tag, ids, csrf) => {
	return dispatch => {
		var tags = [];
		tag.forEach(t => {
			tags.push(t.value);
		});

		projectService.addTag(type, tags, ids, csrf)
		.then(response => {
			dispatch(addaMessageTag(tags, ids));
			ids.forEach(id => {
				if(document.getElementById(id)) classService.removeClass(document.getElementById(id), 'selected');
			});
		})
		.catch(err => {});
	}
};

// update message followup
export const UPDATE_MESSAGE_FOLLOWUP = 'UPDATE_MESSAGE_FOLLOWUP';
export const updateMessageFollowup = (status) => {
	return {
		type: UPDATE_MESSAGE_FOLLOWUP,
		status: status
	}
}

// update message fcom
export const UPDATE_MESSAGE_FCOM = 'UPDATE_MESSAGE_FCOM';
export const updateMessageFollowupFcom = (status) => {
	return {
		type: UPDATE_MESSAGE_FCOM,
		status: status
	}
}

// set default keyword in search bar
export const SET_KEYWORD_MESSENGER = 'SET_KEYWORD_MESSENGER';
export const setKeywordMessenger = (keyword) => {
	return {
		type: SET_KEYWORD_MESSENGER,
		keyword: keyword
	}
}

/////////////////////////////// Facebook Comment Actions //////////////////

// clear comments
export const CLEAR_COMMENTS = 'CLEAR_COMMENTS';
export const clearComments = () => {
	return {
		type: CLEAR_COMMENTS
	}
}

// clear current comments
export const CLEAR_CURRENT_COMMENTS = 'CLEAR_CURRENT_COMMENTS';
export const clearCurrentComments = () => {
	return {
		type: CLEAR_CURRENT_COMMENTS
	}
}

// update user list
export const UPDATE_USER_LIST_COMMENT = 'UPDATE_USER_LIST_COMMENT';
export const updateUserListComment = (user, index) => {
	return {
		type: UPDATE_USER_LIST_COMMENT,
		user: user,
		index: index
	}
}

// get facebook users from server
export const GET_FACEBOOK_USERS = 'GET_FACEBOOK_USERS';
export const getaFacebookUsers = (users, keyword, start_date, end_date, postid) => {
	return {
		type: GET_FACEBOOK_USERS,
		users: users,
		keyword: keyword,
		start_date: start_date,
		end_date: end_date,
		postid: postid
	}
}
export const getFacebookUsers = (project_id, skip, limit, keyword, start_date, end_date, postid, loading = false) => {
	return dispatch => {
		if(postid && postid.value) {
			postid = postid.value
		} else {
			postid = null;
		}
		commentService.getUsers(project_id, skip, limit, keyword, start_date, end_date, postid)
		.then(response => {
			dispatch(getaFacebookUsers(response.data, keyword, start_date, end_date, postid));
			dispatch(platformClicked('facebook'));
			dispatch(platformCount('facebook'));
			if(loading) dispatch(userLoading());
			else dispatch(userMoreLoading());
		})
		.catch(err => {
			if(skip === 0) dispatch(userLoading());
			else dispatch(userMoreLoading());
		});
	}
};

export const UPDATE_HANDOVER = 'UPDATE_HANDOVER';
export const newHandover = (psid, status) => {
	return {
		type: UPDATE_HANDOVER,
		psid: psid,
		status: status
	}
};

export const UPDATE_CURRENT_THREAD = 'UPDATE_CURRENT_THREAD';
export const updateCurrentThread = (status) => {
	return {
		type: UPDATE_CURRENT_THREAD,
		status: status
	}
};

// search comment users
export const SEARCH_FACEBOOK_USERS = 'SEARCH_FACEBOOK_USERS';
export const searchaCommentUsers = (users, keyword, start_date, end_date, postid) => {
	return {
		type: SEARCH_FACEBOOK_USERS,
		users: users,
		keyword: keyword,
		start_date: start_date,
		end_date: end_date,
		postid: postid
	}
}
export const searchCommentUsers = (project_id, keyword, start_date, end_date, postid, followup_status, fcom_status) => {
	return dispatch => {
		if(postid && postid.value) {
			postid = postid.value
		} else {
			postid = null;
		}
		dispatch(clearCurrentUser());
		commentService.getUsers(project_id, 0, process.env.REACT_APP_CMT_USER_PER_PAGE, keyword, start_date, end_date, postid, followup_status, fcom_status)
		.then(response => {
			dispatch(searchaCommentUsers(response.data, keyword, start_date, end_date, postid));
			dispatch(userLoading());
			if(document.getElementById("search_comment")) document.getElementById("search_comment").focus();
		})
		.catch(err => {
			dispatch(userLoading());
		});
	}
};

// change fb post show status
export const CHANGE_FB_POST_SHOW = 'CHANGE_FB_POST_SHOW';
export const changeFbPostShow = () => {
	return {
		type: CHANGE_FB_POST_SHOW
	}
}


// clear fb post
export const CLEAR_FB_POST = 'CLEAR_FB_POST';
export const clearFbPost = () => {
	return {
		type: CLEAR_FB_POST
	}
}
// get comment conversation from server
export const GET_COMMENT_CONVERSATION = 'GET_COMMENT_CONVERSATION';
export const getaCommentConversation = (conversation, comment_id, comment) => {
	return {
		type: GET_COMMENT_CONVERSATION,
		conversation: conversation,
		comment_id: comment_id,
		comment: comment
	}
}
export const getCommentConversation = (project_id, user, comment_id, comment) => {
	return dispatch => {
		dispatch(clearFbPost());
		dispatch(clearCurrentComments());
		dispatch(clearCurrentUser());
		dispatch(setCurrentUser(user));
		commentService.getConversation(project_id, comment_id)
		.then(response => {
			dispatch(conversationLoading());
			dispatch(getaCommentConversation(response.data, comment_id, comment));
			if(document.getElementById('conversation_comment')) {
				// document.getElementById('conversation_comment').scrollTop = document.getElementById('conversation_comment').scrollHeight;
				if(document.getElementById('reply-conversation-comment')) document.getElementById('reply-conversation-comment').focus();
			}
		})
		.catch(err => {
			dispatch(conversationLoading());
		});
	}
};

// new comment from socket
export const NEW_COMMENT = 'NEW_COMMENT';
export const newComment = (comment, currentUser) => {
	return {
		type: NEW_COMMENT,
		comment: comment,
		currentUser: currentUser
	}
}

// page comment deleted from socket
export const PAGE_COMMENT_DELETED = 'PAGE_COMMENT_DELETED';
export const pageCommentDeleted = (comment, currentUser) => {
	return {
		type: PAGE_COMMENT_DELETED,
		comment: comment,
		currentUser: currentUser
	}
}

// new comment from socket
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const updateComment = (comment, currentUser) => {
	return {
		type: UPDATE_COMMENT,
		comment: comment,
		currentUser: currentUser
	}
}

// change current comment status
export const CHANGE_CURRENT_COMMENT_STATUS = 'CHANGE_CURRENT_COMMENT_STATUS';
export const changeCurrentComment = (status) => {
	return {
		type: CHANGE_CURRENT_COMMENT_STATUS,
		status: status
	}
}


// select comments
export const SELECT_COMMENT = 'SELECT_COMMENT';
export const selectComment = (id) => {
	if(document.getElementById(id)) {
		if(classService.hasClass(document.getElementById(id), 'selected')) {
			classService.removeClass(document.getElementById(id), 'selected');
		} else {
			classService.addClass(document.getElementById(id), 'selected');
		}
	}
	return {
		type: SELECT_COMMENT,
		id: id
	}
}

// add comment tags
export const ADD_COMMENT_TAG = 'ADD_COMMENT_TAG';
export const addaCommentTag = (tags, ids) => {
	return {
		type: ADD_COMMENT_TAG,
		tags: tags,
		ids: ids
	}
}
export const addCommentTag = (type, tag, ids, csrf) => {
	var tags = [];
	tag.forEach(t => {
		tags.push(t.value);
	});
	return dispatch => {
		projectService.addTag(type, tags, ids, csrf)
		.then(response => {
			dispatch(addaCommentTag(tags, ids));
			ids.forEach(id => {
				if(document.getElementById(id)) {
					classService.removeClass(document.getElementById(id), 'selected');
				}
			});
		})
		.catch(err => {});
	}
};

// update comment followup
export const UPDATE_COMMENT_FOLLOWUP = 'UPDATE_COMMENT_FOLLOWUP';
export const updateCommentFollowup = (status) => {
	return {
		type: UPDATE_COMMENT_FOLLOWUP,
		status: status
	}
}

// update comment fcom
export const UPDATE_COMMENT_FCOM = 'UPDATE_COMMENT_FCOM';
export const updateCommentFollowupFcom = (status) => {
	return {
		type: UPDATE_COMMENT_FCOM,
		status: status
	}
}

// set default keyword in search bar
export const SET_KEYWORD_COMMENT = 'SET_KEYWORD_COMMENT';
export const setKeywordComment = (keyword) => {
	return {
		type: SET_KEYWORD_COMMENT,
		keyword: keyword
	}
}

///////////////////////// Viber Actions ////////////////////////

// clear message
export const CLEAR_VMESSAGE = 'CLEAR_VMESSAGE';
export const clearvMessages = () => {
	return {
		type: CLEAR_VMESSAGE
	}
}

// clear current messages
export const CLEAR_CURRENT_VMESSAGES = 'CLEAR_CURRENT_VMESSAGES';
export const clearCurrentvMessages = () => {
	return {
		type: CLEAR_CURRENT_VMESSAGES
	}
}

// get message users from server
export const GET_VMESSAGE_USERS = 'GET_VMESSAGE_USERS';
export const getavMessageUsers = (users, keyword, start_date, end_date) => {
	return {
		type: GET_VMESSAGE_USERS,
		users: users,
		keyword: keyword,
		start_date: start_date,
		end_date: end_date
	}
}
export const getvMessageUsers = (project_id, skip, limit, keyword, start_date, end_date, loading = false) => {
	return dispatch => {
		viberService.getUsers(project_id, skip, limit, keyword, start_date, end_date)
		.then(response => {
			dispatch(getavMessageUsers(response.data, keyword, start_date, end_date));
			dispatch(platformClicked('viber'));
			dispatch(platformCount('viber'));
			if(loading) dispatch(userLoading());
			else dispatch(userMoreLoading());
		})
		.catch(err => {
			if(skip === 0) dispatch(userLoading());
			else dispatch(userMoreLoading());
		});
	}
};

// search message users
export const SEARCH_VMESSAGE_USERS = 'SEARCH_VMESSAGE_USERS';
export const searchavMessageUsers = (users, keyword, start_date, end_date) => {
	return {
		type: SEARCH_VMESSAGE_USERS,
		users: users,
		keyword: keyword,
		start_date: start_date,
		end_date: end_date
	}
}
export const searchvMessageUsers = (project_id, keyword, start_date, end_date) => {
	return dispatch => {
		dispatch(clearCurrentUser());
		viberService.getUsers(project_id, 0, process.env.REACT_APP_MSG_USER_PER_PAGE, keyword, start_date, end_date)
		.then(response => {
			dispatch(searchavMessageUsers(response.data, keyword, start_date, end_date));
			dispatch(userLoading());
			if(document.getElementById("search_viber")) document.getElementById("search_viber").focus();
		})
		.catch(err => {
			dispatch(userLoading());
		});
	}
};

// get conversation from server
export const GET_VCONVERSATION = 'GET_VCONVERSATION';
export const getavConversation = (conversation, currentMessage) => {
	return {
		type: GET_VCONVERSATION,
		conversation: conversation,
		currentMessage: currentMessage
	}
}
export const getvConversation = (project_id, user, skip, limit, currentMessage) => {
	return dispatch => {
		dispatch(clearCurrentMessages());
		dispatch(clearCurrentUser());
		dispatch(setCurrentUser(user));
		viberService.getConversation(project_id, user.id, skip, limit)
		.then(response => {
			dispatch(conversationLoading());
			dispatch(getavConversation(response.data, currentMessage));
			if(document.getElementById('conversation_viber')) {
				document.getElementById('conversation_viber').scrollTop = document.getElementById('conversation_viber').scrollHeight;
				if(document.getElementById('reply-conversation-viber')) document.getElementById('reply-conversation-viber').focus();
			}
		})
		.catch(err => {
			dispatch(conversationLoading());
		});
	}
};

// load more conversation from server
export const LOAD_VCONVERSATION = 'LOAD_VCONVERSATION';
export const loadavConversation = (conversation) => {
	return {
		type: LOAD_VCONVERSATION,
		conversation: conversation
	}
}
export const loadvConversation = (project_id, user_id, skip, limit, scroll) => {
	return dispatch => {
		viberService.getConversation(project_id, user_id, skip, limit)
		.then(response => {
			dispatch(conversationMoreLoading());
			dispatch(loadavConversation(response.data));
			if(document.getElementById('conversation_viber')) {
				document.getElementById('conversation_viber').scrollTop = document.getElementById('conversation_viber').scrollHeight - scroll;
				document.getElementById('conversation_viber').style.pointerEvents = 'auto';
			}
		})
		.catch(err => {
			dispatch(conversationMoreLoading());
		});
	}
};

// new message from socket
export const NEW_VMESSAGE = 'NEW_VMESSAGE';
export const newvMessage = (message, currentUser) => {
	return {
		type: NEW_VMESSAGE,
		message: message,
		currentUser: currentUser
	}
}

// change current message status
export const CHANGE_CURRENT_VMESSAGE_STATUS = 'CHANGE_CURRENT_VMESSAGE_STATUS';
export const changeCurrentvMessage = (status) => {
	return {
		type: CHANGE_CURRENT_VMESSAGE_STATUS,
		status: status
	}
}

// select messages
export const SELECT_VMESSAGE = 'SELECT_VMESSAGE';
export const selectvMessage = (id) => {
	if(document.getElementById(id)) {
		if(classService.hasClass(document.getElementById(id), 'selected')) {
			classService.removeClass(document.getElementById(id), 'selected');
		} else {
			classService.addClass(document.getElementById(id), 'selected');
		}
	}
	return {
		type: SELECT_VMESSAGE,
		id: id
	}
}

// add message tags
export const ADD_VMESSAGE_TAG = 'ADD_VMESSAGE_TAG';
export const addavMessageTag = (tags, ids) => {
	return {
		type: ADD_VMESSAGE_TAG,
		tags: tags,
		ids: ids
	}
}
export const addvMessageTag = (type, tag, ids, csrf) => {
	return dispatch => {
		var tags = [];
		tag.forEach(t => {
			tags.push(t.value);
		});

		projectService.addTag(type, tags, ids, csrf)
		.then(response => {
			dispatch(addavMessageTag(tags, ids));
			ids.forEach(id => {
				if(document.getElementById(id)) classService.removeClass(document.getElementById(id), 'selected');
			});
		})
		.catch(err => {});
	}
};

///////////////////////// Instagram Actions ////////////////////////

// clear comments
export const CLEAR_ICOMMENTS = 'CLEAR_ICOMMENTS';
export const cleariComments = () => {
	return {
		type: CLEAR_ICOMMENTS
	}
}

// clear current comments
export const CLEAR_CURRENT_ICOMMENTS = 'CLEAR_CURRENT_ICOMMENTS';
export const clearCurrentiComments = () => {
	return {
		type: CLEAR_CURRENT_ICOMMENTS
	}
}

// get instagram users from server
export const GET_INSTAGRAM_USERS = 'GET_INSTAGRAM_USERS';
export const getaInstagramUsers = (users, keyword, start_date, end_date) => {
	return {
		type: GET_INSTAGRAM_USERS,
		users: users,
		keyword: keyword,
		start_date: start_date,
		end_date: end_date
	}
}
export const getInstagramUsers = (project_id, skip, limit, keyword, start_date, end_date, loading = false) => {
	return dispatch => {
		instagramService.getUsers(project_id, skip, limit, keyword, start_date, end_date)
		.then(response => {
			dispatch(getaInstagramUsers(response.data, keyword, start_date, end_date));
			dispatch(platformClicked('instagram'));
			dispatch(platformCount('instagram'));
			if(loading) dispatch(userLoading());
			else dispatch(userMoreLoading());
		})
		.catch(err => {
			if(skip === 0) dispatch(userLoading());
			else dispatch(userMoreLoading());
		});
	}
};

// search comment users
export const SEARCH_INSTAGRAM_USERS = 'SEARCH_INSTAGRAM_USERS';
export const searchaiCommentUsers = (users, keyword, start_date, end_date) => {
	return {
		type: SEARCH_INSTAGRAM_USERS,
		users: users,
		keyword: keyword,
		start_date: start_date,
		end_date: end_date
	}
}
export const searchiCommentUsers = (project_id, keyword, start_date, end_date) => {
	return dispatch => {
		dispatch(clearCurrentUser());
		instagramService.getUsers(project_id, 0, process.env.REACT_APP_CMT_USER_PER_PAGE, keyword, start_date, end_date)
		.then(response => {
			dispatch(searchaiCommentUsers(response.data, keyword, start_date, end_date));
			dispatch(userLoading());
			if(document.getElementById("search_icomment")) document.getElementById("search_icomment").focus();
		})
		.catch(err => {
			dispatch(userLoading());
		});
	}
};


// get comment conversation from server
export const GET_ICOMMENT_CONVERSATION = 'GET_ICOMMENT_CONVERSATION';
export const getaiCommentConversation = (conversation, comment_id, comment) => {
	return {
		type: GET_ICOMMENT_CONVERSATION,
		conversation: conversation,
		comment_id: comment_id,
		comment: comment
	}
}
export const getiCommentConversation = (project_id, user, comment_id, comment) => {
	return dispatch => {
		dispatch(clearCurrentiComments());
		dispatch(clearCurrentUser());
		dispatch(setCurrentUser(user));
		instagramService.getConversation(project_id, comment_id)
		.then(response => {
			dispatch(conversationLoading());
			dispatch(getaiCommentConversation(response.data, comment_id, comment));
			if(document.getElementById('conversation_icomment')) {
				document.getElementById('conversation_icomment').scrollTop = document.getElementById('conversation_icomment').scrollHeight;
				if(document.getElementById('reply-conversation-icomment')) document.getElementById('reply-conversation-icomment').focus();
			}
		})
		.catch(err => {
			dispatch(conversationLoading());
		});
	}
};

// new comment from socket
export const NEW_ICOMMENT = 'NEW_ICOMMENT';
export const newiComment = (comment, currentUser) => {
	return {
		type: NEW_ICOMMENT,
		comment: comment,
		currentUser: currentUser
	}
}

// change current comment status
export const CHANGE_CURRENT_ICOMMENT_STATUS = 'CHANGE_CURRENT_ICOMMENT_STATUS';
export const changeCurrentiComment = (status) => {
	return {
		type: CHANGE_CURRENT_ICOMMENT_STATUS,
		status: status
	}
}


// select comments
export const SELECT_ICOMMENT = 'SELECT_ICOMMENT';
export const selectiComment = (id) => {
	if(document.getElementById(id)) {
		if(classService.hasClass(document.getElementById(id), 'selected')) {
			classService.removeClass(document.getElementById(id), 'selected');
		} else {
			classService.addClass(document.getElementById(id), 'selected');
		}
	}
	return {
		type: SELECT_ICOMMENT,
		id: id
	}
}

// add comment tags
export const ADD_ICOMMENT_TAG = 'ADD_ICOMMENT_TAG';
export const addaiCommentTag = (tags, ids) => {
	return {
		type: ADD_ICOMMENT_TAG,
		tags: tags,
		ids: ids
	}
}
export const addiCommentTag = (type, tag, ids, csrf) => {
	var tags = [];
	tag.forEach(t => {
		tags.push(t.value);
	});
	return dispatch => {
		projectService.addTag(type, tags, ids, csrf)
		.then(response => {
			dispatch(addaiCommentTag(tags, ids));
			ids.forEach(id => {
				if(document.getElementById(id)) {
					classService.removeClass(document.getElementById(id), 'selected');
				}
			});
		})
		.catch(err => {});
	}
};

/////////////////////////// tags ////////////////////////////////

// remove commnt tags
export const REMOVE_COMMENT_TAGS = 'REMOVE_COMMENT_TAGS';
export const removeaCommentTag = (comment_id, tag) => {
	return {
		type: REMOVE_COMMENT_TAGS,
		id: comment_id,
		tag: tag
	}
}
export const removeCommentTag = (comment_id, tag, csrf) => {
	return dispatch => {
		projectService.removeTag('comment', tag, comment_id, csrf)
		.then(response => {
			dispatch(removeaCommentTag(comment_id, tag));
		})
		.catch(err => {});
	}
};

// remove message tags
export const REMOVE_MESSAGE_TAGS = 'REMOVE_MESSAGE_TAGS';
export const removeaMessageTag = (message_id, tag) => {
	return {
		type: REMOVE_MESSAGE_TAGS,
		id: message_id,
		tag: tag
	}
}
export const removeMessageTag = (message_id, tag, csrf) => {
	return dispatch => {
		projectService.removeTag('message', tag, message_id, csrf)
		.then(response => {
			dispatch(removeaMessageTag(message_id, tag));
		})
		.catch(err => {});
	}
};

// remove viber tags
export const REMOVE_VMESSAGE_TAGS = 'REMOVE_VMESSAGE_TAGS';
export const removeavMessageTag = (message_id, tag) => {
	return {
		type: REMOVE_VMESSAGE_TAGS,
		id: message_id,
		tag: tag
	}
}
export const removevMessageTag = (message_id, tag, csrf) => {
	return dispatch => {
		projectService.removeTag('viber', tag, message_id, csrf)
		.then(response => {
			dispatch(removeavMessageTag(message_id, tag));
		})
		.catch(err => {});
	}
};

// remove instagram tags
export const REMOVE_ICOMMENT_TAGS = 'REMOVE_ICOMMENT_TAGS';
export const removeaiCommentTag = (comment_id, tag) => {
	return {
		type: REMOVE_ICOMMENT_TAGS,
		id: comment_id,
		tag: tag
	}
}
export const removeiCommentTag = (comment_id, tag, csrf) => {
	return dispatch => {
		projectService.removeTag('instagram', tag, comment_id, csrf)
		.then(response => {
			dispatch(removeaiCommentTag(comment_id, tag));
		})
		.catch(err => {});
	}
};