import React from 'react';
import analyzenLogo from '../../assets/img/analyzen-logo.png';

const Copyright = (props) => {
	return (
		<div className="copy-text">
			A product of<a href="https://www.analyzenbd.com" target="_blank" rel="noopener noreferrer"><img src={analyzenLogo} alt="Analyzen" /></a>
		</div>
	);
};

export default Copyright;