import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ForgetPassword from '../../components/ForgetPassword/ForgetPassword';
import analyzenLogo from '../../assets/img/analyzen-logo.png';
import '../../assets/css/login.css';
import logo from '../../assets/img/logo.png';

class ForgetPass extends Component {
	constructor(props) {
		super(props);
		this.varticalCenterStuff = this.varticalCenterStuff.bind(this);
	}

	varticalCenterStuff = () => {
		var loginBoxHeight = document.querySelectorAll('.login-block')[0].clientHeight;
		var mathLogin = (window.innerHeight / 2) - (loginBoxHeight / 2);
		document.querySelectorAll('.login-block')[0].style.marginTop = mathLogin + "px";
	}
	componentDidMount() {
		document.title = 'Forget Password - listenyzen';
		document.body.classList.add('login-body');
		setTimeout(() => {
			this.varticalCenterStuff();
		}, 100);
		window.addEventListener("resize", this.varticalCenterStuff);
	}

	componentWillUnmount() {
		document.body.classList.remove('login-body');
		window.removeEventListener("resize", this.varticalCenterStuff);
	}

	goToLogin = () => {
		window.location.replace(process.env.REACT_APP_CLIENT_URL);
	}

	render() {
		return (
			<div className="Login">
				<div id="login-wrapper">
					<section className="login-block">
						<div className="container">
							<div className="row">
								<div className="col-sm-4 login-sec pt">
									<div className="login-heading text-center">
										<Link to="/"><img src={logo} alt="listenyzen Logo" /></Link>
										{/* <h2 className="title text-center">Login</h2> */}
										<p></p>
										<p>Smart Social Media Customer Query Management system<br/>
										Powered by Artificial Intelligence Technology</p>
									</div>
									<ForgetPassword />
									<div className="sfp" onClick={this.goToLogin}>Back to Login</div>
								</div>
								<div className="col-sm-8 banner-sec"></div>
							</div>
						</div>
						<div className="container footer">
							<div className="col-sm-6">
								&copy; {new Date().getFullYear()} <a href="https://www.analyzenbd.com" target="_blank" rel="noopener noreferrer"><img src={analyzenLogo} alt="Analyzen" style={{verticalAlign: 'sub', width: '10%'}} /></a>. All rights reserved.
							</div>
							<div className="col-sm-6 text-right">
								<Link to="/terms">Terms & Privacy</Link>
							</div>
						</div>
					</section>
				</div>
			</div>
		);
	}
};

export default ForgetPass;