import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { addProject } from '../../../services/ProjectService';
import placeHolderImage from '../../../assets/img/350x350.jpg';

class AddProject extends Component {
	schema = yup.object({
		_csrf: yup.string(),
		name: yup.string().required('Name is required'),
		email: yup.string().email('Email is invalid').required('Email is required'),
		projectImage: yup.mixed().required('You must upload project image')
	});

	initialValues = { name: '', email: localStorage.getItem('loginEmail') ? localStorage.getItem('loginEmail') : '', projectImage: null };

	// submit = () => {
	// 		let formData = new FormData();
	// 		formData.append('name', document.getElementById("project-name").value);
	// 		formData.append('email', document.getElementById("project-email").value);
	// 		formData.append('projectImage', document.getElementById("imgInp").files[0]);

	// 		addProject(formData)
	// 			.then()
	// 			.catch();
	// };

	render() {
		return (
			<Modal className="add-project-modal" show={this.props.show} onHide={this.props.manage.bind(this, 'add_project_modal')}>
				<Formik
					initialValues={this.initialValues}
					validationSchema={this.schema} 
					onSubmit={(values, { setSubmitting }) => {
						// process
						let formData = new FormData();
						formData.append('name', values.name);
						formData.append('email', values.email);
						formData.append('projectImage', values.projectImage);
						formData.append('_csrf', this.props.csrf);
						addProject(formData)
							.then(response => {
								if(response.data) {
									this.props.manage('add_project_modal', response.data);
									setSubmitting(false);
								}
							})
							.catch(err => {
								setSubmitting(false);
							});
					}}
				>
					{({
						values,
						touched,
						errors,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit,
						setFieldValue
					}) => (
						<form onSubmit={handleSubmit}>
							<Modal.Header closeButton>
								<Modal.Title>Add Project</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div className="form-group">
									<label className="control-label">Name</label>
									<input type="text" id="project-name" name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} className={
										errors.name && touched.name ? 'form-control error' : 'form-control'
									} />
									{errors.name && touched.name && <div className="input-feedback">{errors.name}</div>}
								</div>
								<div className="form-group">
									<label className="control-label">Email</label>
									<input type="text" id="project-email" name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} className={
										errors.email && touched.email ? 'form-control error' : 'form-control'
									} />
									{errors.email && touched.email && <div className="input-feedback">{errors.email}</div>}
								</div>
								<div className="form-group">
									<label className="control-label">Upload Project Image</label>
									<div className="upload-project-image">
										<div className="row">
											<div className="col-sm-3">
												<div className="project-image">
													<img className="img-responive" id="img-uploaded" src={placeHolderImage} alt="Project Logo" />
												</div>
											</div>
											<div className="col-sm-9">
												<span className="file-wrapper">
													<input type="file" accept=".jpg,.jpeg,.png" name="projectImage" id="imgInp" className="uploader" onChange={(event) => {
														if(event.currentTarget.files && event.currentTarget.files[0]) {
															if(event.currentTarget.files[0].size <= 1048576) {
																setFieldValue("projectImage", event.currentTarget.files[0]);
																if(document.getElementById('size')) document.getElementById('size').style.color = 'black';
																var reader = new FileReader();
																reader.onload = function (e) {
																	if(document.getElementById("img-uploaded")) document.getElementById("img-uploaded").src = e.target.result;
																	if(document.getElementById("btntext")) document.getElementById("btntext").innerText = 'Change Image';
																}
																reader.readAsDataURL(event.currentTarget.files[0]);
															} else {
																if(document.getElementById('size')) document.getElementById('size').style.color = 'red';
															}
														}
													}} />
													<span className="btn" id="btntext">Upload Image</span>
													<p id="size">Maximum image size 1MB and only jpg, jpeg or png image is allowed</p>
													{errors.projectImage && touched.projectImage && <div className="input-feedback">{errors.projectImage}</div>}
												</span>
											</div>
										</div>
									</div>
								</div>
							</Modal.Body>
							<Modal.Footer className="text-right">
								<button type="submit" disabled={isSubmitting} className="btn btn-primary">Submit </button>
							</Modal.Footer>
						</form>
					)}
				</Formik>
			</Modal>
		);
	}
}

const mapStateToProps = state => {
	return {
		csrf: state.site_state.csrf
  };
}

export default connect(mapStateToProps)(AddProject);