import React from 'react';
import Header from '../Header/Header';
import Conversation from '../FbCommentSection/Conversation/Conversation';

const FacebookSection = (props) => {
	return (
		<>
			<Header />
			<Conversation modal={props.modal} />
		</>
	);
};

export default FacebookSection;