import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import loader from '../../../assets/img/loader.svg';
import Waypoint from 'react-waypoint';
import User from './User';
import nophoto from '../../../assets/img/nophoto.jpg';
import * as actions from '../../../store/actions/inbox_actions';

class UserList extends Component { // props are 'users'
	constructor(props) {
		super(props);
		this.adjustScreen = this.adjustScreen.bind(this);
	}

	offset = (el) => {
		var rect = el.getBoundingClientRect(),
			scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
			scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		return {
			top: rect.top + scrollTop,
			left: rect.left + scrollLeft
		}
	}

	adjustScreen = () => {
		document.querySelectorAll('.sideBar')[0].style.height = (window.innerHeight - 18 - this.offset(document.getElementsByClassName("sideBar")[0]).top) + "px";
	}

	componentDidMount() {
		this.adjustScreen();
		window.addEventListener("resize", this.adjustScreen);
		if(this.props.match.params.platform === 'messenger') {
			if(this.props.skip === 0) {
				this.props.usersLoading();
				setTimeout(
					function() {
						this.props.getMessageUsers(this.props.match.params.project_id, 0, process.env.REACT_APP_MSG_USER_PER_PAGE, this.props.keyword, this.props.start_date_message, this.props.end_date_message, true);
					}
					.bind(this),
					100
				);
			}
		}
		if(this.props.match.params.platform === 'facebook') {
			if(this.props.skip_comment === 0) {
				this.props.usersLoading();
				setTimeout(
					function() {
						this.props.getFacebookUsers(this.props.match.params.project_id, 0, process.env.REACT_APP_CMT_USER_PER_PAGE, this.props.keyword_comment, this.props.start_date_comment, this.props.end_date_comment, this.props.postid, true);
					}
					.bind(this),
					3000
				);
			}
		}
		if(this.props.match.params.platform === 'viber') {
			if(this.props.skip_viber === 0) {
				this.props.usersLoading();
				this.props.getvMessageUsers(this.props.match.params.project_id, 0, process.env.REACT_APP_MSG_USER_PER_PAGE, this.props.keyword_viber, this.props.start_date_viber, this.props.end_date_viber, true);
			}
		}
		if(this.props.match.params.platform === 'instagram') {
			if(this.props.skip_instagram === 0) {
				this.props.usersLoading();
				this.props.getInstagramUsers(this.props.match.params.project_id, 0, process.env.REACT_APP_CMT_USER_PER_PAGE, this.props.keyword_instagram, this.props.start_date_instagram, this.props.end_date_instagram, true);
			}
		}
	}

	componentDidUpdate() {
		this.adjustScreen();
	}

	componentWillUnmount() {
		window.removeEventListener("resize",  this.adjustScreen);
	}

	getConversation = (user, comment_id = '', comment = {}, index) => {
		if(user) {
			if(!this.props.conversationLoading && !this.props.conversationMoreLoading && !this.props.userLoading && !this.props.userMoreLoading) {
				this.props.conversationLoadingF();
				if(this.props.match.params.platform === 'messenger')
					this.props.getConversation(this.props.match.params.project_id, user, 0, process.env.REACT_APP_MSG_PER_PAGE, comment, this.props.bot_enabled, index, this.props.csrf);
				if(this.props.match.params.platform === 'facebook')
					this.props.getCommentConversation(this.props.match.params.project_id, user, comment_id, comment);
				if(this.props.match.params.platform === 'viber')
					this.props.getvConversation(this.props.match.params.project_id, user, 0, process.env.REACT_APP_MSG_PER_PAGE, comment);
				if(this.props.match.params.platform === 'instagram')
					this.props.getiCommentConversation(this.props.match.params.project_id, user, comment_id, comment);
			}
		}
	}

	loadMoreUsers = () => {
		if(this.props.match.params.platform === 'messenger') {
			if(this.props.message_users.length !== this.props.message_users_count) {
				this.props.usersMoreLoading();
				this.props.getMessageUsers(this.props.match.params.project_id, this.props.skip, process.env.REACT_APP_MSG_USER_PER_PAGE, this.props.keyword, this.props.start_date_message, this.props.end_date_message);
			}
		}
		if(this.props.match.params.platform === 'facebook') {
			if(this.props.comment_users.length !== this.props.comment_users_count) {
				this.props.usersMoreLoading();
				this.props.getFacebookUsers(this.props.match.params.project_id, this.props.skip_comment, process.env.REACT_APP_CMT_USER_PER_PAGE, this.props.keyword_comment, this.props.start_date_comment, this.props.end_date_comment, this.props.postid);
			}
		}
		if(this.props.match.params.platform === 'viber') {
			if(this.props.viber_users.length !== this.props.viber_users_count) {
				this.props.usersMoreLoading();
				this.props.getvMessageUsers(this.props.match.params.project_id, this.props.skip_viber, process.env.REACT_APP_MSG_USER_PER_PAGE, this.props.keyword_viber, this.props.start_date_viber, this.props.end_date_viber);
			}
		}
		if(this.props.match.params.platform === 'instagram') {
			if(this.props.instagram_users.length !== this.props.instagram_users_count) {
				this.props.usersMoreLoading();
				this.props.getInstagramUsers(this.props.match.params.project_id, this.props.skip_instagram, process.env.REACT_APP_CMT_USER_PER_PAGE, this.props.keyword_instagram, this.props.start_date_instagram, this.props.end_date_instagram);
			}
		}
	}

	render() {
		return (
			<div className="row sideBar" id="style-10">
				{this.props.userLoading ? <div style={{width: '100%', paddingTop: '10px', textAlign: 'center'}}><img src={loader} alt="loading..." /></div> :
				<Scrollbars style={{ height: '100%' }}>
					{ this.props.match.params.platform === 'messenger' && this.props.message_users ? this.props.message_users.sort(function(a,b){
										return new Date(b.time_stamp) - new Date(a.time_stamp);
									}).sort(function(c,d){
										return (d.replied_by > c.replied_by) ? -1 : (d.replied_by < c.replied_by) ? 1 : 0;
									}).sort(function(c,d){
										return (d.resolved_status < c.resolved_status) ? -1 : (d.resolved_status > c.resolved_status) ? 1 : 0;
									}).map((user, index) => (
						user.customer ? <User key={index} platform="messenger" new_user={user.new_user && user.new_user === true ? true : false} name={user.customer && user.customer.name ? user.customer.name : 'Anonymous User'} time={user.time_stamp} userIcon={user.customer && user.customer.profile_pic ? user.customer.profile_pic : nophoto} resolved_status={user.resolved_status} resolved_by={user.resolved_by ? user.resolved_by.username ? user.resolved_by.username : '' : ''} replied_by={user.replied_by} followup_by={user.followup_by && user.followup_by.username ? user.followup_by.username : null} followup_status={user.followup_status} click={user.customer && user.customer.psid ? this.getConversation.bind(this, user.customer, '', user, index) : this.getConversation.bind(this, null, '', user, index)} activeClass={this.props.currentUser && this.props.currentUser.psid && this.props.currentUser.psid === user.customer.psid} /> : null
					)) : null } 
					{ this.props.match.params.platform === 'facebook' && this.props.comment_users ? this.props.comment_users.sort(function(a,b){
										return new Date(b.time_stamp) - new Date(a.time_stamp);
									}).sort(function(c,d){
										return (d.resolved_status < c.resolved_status) ? -1 : (d.resolved_status > c.resolved_status) ? 1 : 0;
									}).map((user, index) => (
						user.customer ? <User key={index} platform="facebook" name={user.customer && user.customer.name ? user.customer.name : 'Anonymous User'} time={user.time_stamp} userIcon={user.customer && user.customer.profile_pic ? user.customer.profile_pic : nophoto} resolved_status={user.resolved_status} resolved_by={user.resolved_by ? user.resolved_by.username ? user.resolved_by.username : '' : ''} followup_by={user.followup_by && user.followup_by.username ? user.followup_by.username : null} followup_status={user.followup_status} click={this.getConversation.bind(this, user.customer, user._id, user)} activeClass={this.props.currentCommentId === user._id} /> : null
					)) : null }
					{ this.props.match.params.platform === 'viber' && this.props.viber_users ? this.props.viber_users.sort(function(a,b){
										return new Date(b.time_stamp) - new Date(a.time_stamp);
									}).sort(function(c,d){
										return (d.resolved_status < c.resolved_status) ? -1 : (d.resolved_status > c.resolved_status) ? 1 : 0;
									}).map((user, index) => (
										<User key={index} platform="viber" name={user.customer && user.customer.name ? user.customer.name : 'Anonymous User'} time={user.time_stamp} userIcon={user.customer && user.customer.avatar ? user.customer.avatar : nophoto} resolved_status={user.resolved_status} resolved_by={user.resolved_by ? user.resolved_by.username ? user.resolved_by.username : '' : ''} click={this.getConversation.bind(this, user.customer, '', user)} activeClass={this.props.currentUser && this.props.currentUser.psid && this.props.currentUser.psid === user.customer.psid} />
					)) : null }
					{ this.props.match.params.platform === 'instagram' && this.props.instagram_users ? this.props.instagram_users.sort(function(a,b){
										return new Date(b.time_stamp) - new Date(a.time_stamp);
									}).sort(function(c,d){
										return (d.resolved_status < c.resolved_status) ? -1 : (d.resolved_status > c.resolved_status) ? 1 : 0;
									}).map((user, index) => (
										<User key={index} platform="instagram" name={user.customer && user.customer.username ? user.customer.username : 'Anonymous User'} time={user.time_stamp} userIcon={user.customer && user.customer.profile_pic ? user.customer.profile_pic : nophoto} resolved_status={user.resolved_status} resolved_by={user.resolved_by ? user.resolved_by.username ? user.resolved_by.username : '' : ''} click={this.getConversation.bind(this, user.customer, user._id, user)} activeClass={this.props.currentiCommentId === user._id} />
					)) : null }
					{this.props.userMoreLoading ? <div style={{width: '100%', paddingTop: '10px', textAlign: 'center'}}><img src={loader} alt="loading..." /></div> : <Waypoint onEnter={this.loadMoreUsers} /> }
				</Scrollbars>}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		message_users: state.message_state.userList,
		message_users_count: state.message_state.userCount,
		comment_users: state.comment_state.userList,
		comment_users_count: state.comment_state.userCount,
		viber_users: state.viber_state.userList,
		viber_users_count: state.viber_state.userCount,
		instagram_users: state.instagram_state.userList,
		instagram_users_count: state.instagram_state.userCount,
		skip: state.message_state.skip_user,
		skip_comment: state.comment_state.skip_user,
		skip_viber: state.viber_state.skip_user,
		skip_instagram: state.instagram_state.skip_user,
		keyword: state.message_state.keyword,
		keyword_comment: state.comment_state.keyword,
		keyword_viber: state.viber_state.keyword,
		keyword_instagram: state.instagram_state.keyword,
		start_date_message: state.message_state.start_date,
		end_date_message: state.message_state.end_date,
		start_date_comment: state.comment_state.start_date,
		end_date_comment: state.comment_state.end_date,
		start_date_viber: state.viber_state.start_date,
		end_date_viber: state.viber_state.end_date,
		start_date_instagram: state.instagram_state.start_date,
		end_date_instagram: state.instagram_state.end_date,
		postid: state.comment_state.postid,
		userLoading: state.inbox_state.userLoading,
		userMoreLoading: state.inbox_state.userMoreLoading,
		conversationLoading: state.inbox_state.conversationLoading,
		conversationMoreLoading: state.inbox_state.conversationMoreLoading,
		currentUser: state.inbox_state.currentUser,
		currentCommentId: state.comment_state.currentCommentId,
		currentiCommentId: state.instagram_state.currentCommentId,
		bot_enabled: state.inbox_state.bot_enabled,
		csrf: state.site_state.csrf
    };
}

const mapActionToProps = dispatch => {
    return {
        getMessageUsers: (project_id, skip, limit, keyword, start_date, end_date, loading) => dispatch(actions.getMessageUsers(project_id, skip, limit, keyword, start_date, end_date, loading)),
		getFacebookUsers: (project_id, skip, limit, keyword, start_date, end_date, postid, loading) => dispatch(actions.getFacebookUsers(project_id, skip, limit, keyword, start_date, end_date, postid, loading)),
		getvMessageUsers: (project_id, skip, limit, keyword, start_date, end_date, loading) => dispatch(actions.getvMessageUsers(project_id, skip, limit, keyword, start_date, end_date, loading)),
		getInstagramUsers: (project_id, skip, limit, keyword, start_date, end_date, loading) => dispatch(actions.getInstagramUsers(project_id, skip, limit, keyword, start_date, end_date, loading)),
		getConversation: (project_id, user, skip, limit, currentMessage, bot_enabled, index, csrf) => dispatch(actions.getConversation(project_id, user, skip, limit, currentMessage, bot_enabled, index, csrf)),
		getCommentConversation: (project_id, user, comment_id, comment) => dispatch(actions.getCommentConversation(project_id, user, comment_id, comment)),
		getvConversation: (project_id, user, skip, limit, currentMessage) => dispatch(actions.getvConversation(project_id, user, skip, limit, currentMessage)),
		getiCommentConversation: (project_id, user, comment_id, comment) => dispatch(actions.getiCommentConversation(project_id, user, comment_id, comment)),
		conversationLoadingF: () => dispatch(actions.conversationLoading()),
		usersLoading: () => dispatch(actions.userLoading()),
		usersMoreLoading: () => dispatch(actions.userMoreLoading())
    };
};

export default withRouter(connect(mapStateToProps, mapActionToProps)(UserList));