import React, { Component } from 'react';
import Header from '../Header/Header';
import Wrapper from '../Wrapper/Wrapper';
import sad from '../../assets/img/sad.png';

class MobileWarning extends Component {
	constructor(props) {
		super(props);
		this.varticalCenterStuff = this.varticalCenterStuff.bind(this);
	}

	varticalCenterStuff = () => {
		var loginBoxHeight = document.querySelectorAll('.warning-block')[0].clientHeight;
		var mathLogin = (window.innerHeight / 2) - (loginBoxHeight);
		document.querySelectorAll('.warning-block')[0].style.marginTop = mathLogin + "px";
	}

	componentDidMount() {
		document.title = 'listenyzen';
		document.body.classList.add('main-body');
		setTimeout(() => {
			this.varticalCenterStuff();
		}, 100);
		window.addEventListener("resize", this.varticalCenterStuff);
	}

	componentWillUnmount() {
		document.body.classList.remove('main-body');
		window.removeEventListener("resize", this.varticalCenterStuff);
	}

	render() {
		return (
			<div className="NotFound">
				<Header />
				<Wrapper>
					<div className="text-center warning-block" style={{padding: '2%', fontSize: '16px'}}>
						<img src={sad} alt="Sad" />
						<p>listenyzen is only available in desktop! Please browse listenyzen through a desktop device. Sorry for any inconvenience.</p>
					</div>
				</Wrapper>
			</div>
		);
	}
};

export default MobileWarning;