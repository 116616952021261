import React from 'react';

const TrialAlert = (props) => {
	return (
		<div>{props.trial === true ? <div className="row" style={{padding: '0 20px'}}>
			<div className="col-sm-12 trial-alert">
				You are currently enjoying <b>"1 Month FREE Trial Period"</b>. You can add maximum <b>2 projects</b> and can listenyzen upto <b>2,000 queries</b> within this trial period. Your trial period expires on {props.ends}
			</div>
		</div> : (props.trial === false) ? 
			(props.billing === 'pending_bill') ? <div className="row" style={{padding: '0 20px'}}>
				<div className="col-sm-12 pending-bill-alert">
					You have pending bills! Please pay all due bills at your earliest to avoid disconnection and continue using listenyze<sup>n</sup>
				</div>
			</div> : !props.billing ? (props.trial === false && localStorage.getItem('showTrialAlert') !== "false") ? <div className="row" style={{padding: '0 20px'}}>
				<div className="col-sm-12 trial-expired-alert">
					Your trial period has expired! Please contact us if you want to activate billing and use listenyze<sup>n</sup>.
				</div>
			</div> : null : null 
		: null}
		</div>
	);
};

export default TrialAlert;