import React, { Component } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import ReactGA from 'react-ga';
import './assets/css/fonts.css';
import './assets/css/style.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Login from './containers/Login/Login';
import ForgetPass from './containers/ForgetPass/ForgetPass';
import PasswordReset from './containers/PasswordReset/PasswordReset';
import Logout from './components/Logout/Logout';
import Signup from './containers/Signup/Signup';
import Projects from './containers/Projects/Projects';
import Inbox from './containers/Inbox/Inbox';
import Dashboard from './containers/Dashboard/Dashboard';
import Databank from './containers/Databank/Databank';
import Replymaker from './containers/Replymaker/Replymaker';
import TagManager from './containers/TagManager/TagManager';
import Trainer from './containers/Trainer/Trainer';
import Terms from './containers/Terms/Terms';
// import Pricing from './containers/Pricing/Pricing';
import NotFound from './components/404/404';
import MobileWarning from './components/MobileWarning/MobileWarning';
import PrivateRoute from './components/CustomRoute/PrivateRoute';
import PublicRoute from './components/CustomRoute/PublicRoute';
import * as actions from './store/actions/site_actions';

class App extends Component {

  componentDidMount() {
    this.props.setCsrf();
  }

  render() {
    ReactGA.initialize('UA-135036132-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      !isMobile ? <BrowserRouter>
        <div className="App">
          <Switch>
            <PublicRoute path='/' exact component={Login} />
            <PublicRoute path='/verify/:email/:verification_code?' exact component={Login} />
            <PublicRoute path='/forget_password' exact component={ForgetPass} />
            <PublicRoute path='/password_reset/:userid/:reset_code' exact component={PasswordReset} />
            <PublicRoute path='/signup/:facebook_user_id' exact component={Signup} />
            <PrivateRoute path='/logout' exact component={Logout} />
            <PrivateRoute path='/projects' exact component={Projects} />
            <PrivateRoute path='/inbox/:project_id/:platform(messenger|facebook|viber|instagram)/:psid?' exact component={Inbox} />
            <PrivateRoute path='/dashboard/:project_id' exact component={Dashboard} />
            <PrivateRoute path='/databank/:project_id' exact component={Databank} />
            <PrivateRoute path='/replymaker/:project_id' exact component={Replymaker} />
            <PrivateRoute path='/tag_manager/:project_id' exact component={TagManager} />
            <PrivateRoute path='/trainer/:project_id' exact component={Trainer} />
            {/* <Route path='/pricing' exact component={Pricing} /> */}
            <Route path='/terms' exact component={Terms} />
            <Route component={NotFound} />
          </Switch>
          <ToastContainer
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
        </div>
      </BrowserRouter> : <BrowserRouter>
        <div className="App">
        <Switch>
            {/* <Route path='/pricing' exact component={Pricing} /> */}
            <Route component={MobileWarning} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

const mapActionToProps = dispatch => {
  return {
    setCsrf: (csrf) => dispatch(actions.setCsrf(csrf))
  };
};

export default connect(null, mapActionToProps)(App);
