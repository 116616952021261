import React, { Component } from 'react';
import { connect } from 'react-redux';
import loader from '../../../assets/img/loader.svg';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Cell, CartesianGrid} from 'recharts';

class IntentSection extends Component {
	render() {
		return (
			<div className="tag-cloud-graph">
				<div className="panel panel-default">
					<div className="panel-heading">
						<h3 className="panel-title">User Intent Analysis</h3>
					</div>
					<div className="panel-body" style={{height: '400px', textAlign: 'center'}}>
					{this.props.intentloading ? <img src={loader} alt="loader" className="aligncenter" /> : this.props.intents && this.props.intents.length > 0 ? <><button className="btn cwz" onClick={this.props.manage.bind(this, 'intent', this.props.intents)}>Detail Report</button><ResponsiveContainer height="100%">
							<BarChart
								data={this.props.intents.sort((a, b) => (a.count > b.count) ? -1 : 1)}
								margin={{top: 5, right: 30, left: 20, bottom: 5}}
							>
								<CartesianGrid stroke="#ebf3f0" />
								<XAxis dataKey="_id" />
								<YAxis />
								<Tooltip cursor={false} />
								<Bar dataKey="count">
									{
										this.props.intents.sort((a, b) => (a.count > b.count) ? -1 : 1).map((entry, index) => (
											<Cell key={index} fill={index % 2 === 0 ? '#0088FE' : '#FFBB28'} />
										))
									}
								</Bar>
							</BarChart>
						</ResponsiveContainer></> : <p className="aligncenter">No data available</p>}
					</div>
				</div>
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		intentloading: state.report_state.intentloading,
		intents: state.report_state.intents
	};
}

export default connect(mapStateToProps)(IntentSection);