import React from 'react';
import facebookIcon from '../../../assets/img/social-icons/facebook.svg';
import messengerIcon from '../../../assets/img/social-icons/messenger.svg';
import viberIcon from '../../../assets/img/social-icons/vibermain.svg';
import instaIcon from '../../../assets/img/social-icons/instagram.png';

const numberWithCommas = (x) => {
	var parts = x.toString().split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return parts.join(".");
}

const PlatformIcon = (props) => { // props are 'status', 'platform', 'title
	const platforms = {
		'facebook': {
			'icon': facebookIcon
		},
		'messenger': {
			'icon': messengerIcon
		},
		'viber': {
			'icon': viberIcon
		},
		'instagram': {
			'icon': instaIcon
		}
	};

	const btnClass = ['btn', 'btn-' + props.platform, props.status].join(" ");

	return (
		<button className={btnClass}>
			<img src={platforms[props.platform].icon} alt={props.title} /> {props.unresolved_count && props.unresolved_count !== 0 ? <span className="platformUcount">{numberWithCommas(props.unresolved_count)}</span> : null}
		</button>
	);
};

export default PlatformIcon;