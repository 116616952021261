import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { FormControl, ControlLabel } from 'react-bootstrap';
import * as yup from 'yup';
import * as UserService from '../../services/UserService';

class LoginForm extends Component {

	state = {
		email: null,
		password: null,
		verify_form: 'hide'
	}

	render() {
		let schema1 = yup.object({
			email: yup.string().email('Email is invalid').required('Email is required'),
			password: yup.string().required('You must enter password!')
		});

		let schema2 = yup.object({
			name: yup.string().required('Name is required'),
			company: yup.string(),
			role: yup.string().required('Choose which role you want to signup as'),
			terms: yup.bool().oneOf([true], 'You must agree to our Terms & Conditions to proceed'),
		});

		let schema3 = yup.object({
			verification_code: yup.number().required('Verification code is required')
		});
	
		let initialValues1 
		if(localStorage.getItem('loginEmail')) {
			initialValues1 = { email: localStorage.getItem('loginEmail'), password: '' };
		} else {
			initialValues1 = { email: '', password: '' };
		}
		let initialValues2 = { name: '', company: '', role: '', terms: false };
		let initialValues3 = { verification_code: '' };

		return (
			<>
				<Formik
					initialValues={initialValues1}
					validationSchema={schema1} 
					onSubmit={(values, { setSubmitting, resetForm }) => {
						UserService.checkLogin(null, null, values.email, values.password)
							.then(response => {
								if(response.data.existing_user === 'yes') {
									setSubmitting(false);
									resetForm();
									localStorage.setItem('userData', JSON.stringify({
										role: response.data.user.role
									}));
									localStorage.setItem('loginEmail', values.email);
									window.location.replace(process.env.REACT_APP_CLIENT_URL + '/projects');
								} else if(response.data.existing_user === 'no') {
									// show signup form fields
									this.setState({
										email: values.email,
										password: values.password
									});
									// change to signup mode
									this.props.modeHandler(true);
									setSubmitting(false);
								} else {
									setSubmitting(false);
									toast.error("😪 Sorry! We could not log you in. Please try again later.", {
										autoClose: 5000,
										position: 'bottom-right',
										className: 'notification-message'
									});
								}
							})
							.catch(err => {
								setSubmitting(false);
							});
					}}
				>
					{({
							values,
							touched,
							errors,
							isSubmitting,
							handleChange,
							handleBlur,
							handleSubmit
						}) => (
							<form onSubmit={handleSubmit} className={this.props.login}>
								<div className="form-group">
									<label className="control-label">Email Address</label>
									<input name="email" placeholder="Email address" type="text" value={values.email} onChange={handleChange} onBlur={handleBlur} className={
											errors.email && touched.email ? 'form-control error' : 'form-control'
										} />
									{errors.email && touched.email && <div className="input-feedback">{errors.email}</div>}
								</div>
								<div className="form-group">
									<label className="control-label">Password</label>
									<input name="password" placeholder="Password" type="password" value={values.password} onChange={handleChange} onBlur={handleBlur} className={
											errors.password && touched.password ? 'form-control error' : 'form-control'
										} />
									{errors.password && touched.password && <div className="input-feedback">{errors.password}</div>}
								</div>
								<button type="submit" disabled={isSubmitting} className="btn btn-success btn-block" style={{fontSize: '16px'}}>Continue</button>
							</form>
				)}
				</Formik>
				<Formik
				initialValues={initialValues2}
				validationSchema={schema2} 
				onSubmit={(values, { setSubmitting, resetForm }) => {
					values.email = this.state.email;
					values.password = this.state.password;
					UserService.signup(values)
						.then(response => {
							if(response.data.verify_email) {
								setSubmitting(false);
								resetForm();
								this.props.goToVerify();
								this.setState({
									verify_form: 'show'
								});
								toast.success("😀 We have sent you an email to verify your email address. Please follow the email to complete signup. Please don't forget to check your spam or junk folder.", {
									autoClose: false,
									position: 'bottom-right',
									className: 'notification-message'
								});
							} else {
								setSubmitting(false);
							}
						})
						.catch(err => {
							setSubmitting(false);
						});
				}}
			>
				{({
						values,
						touched,
						errors,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit
					}) => (
						<form onSubmit={handleSubmit} className={this.props.signup}>
							<div className="form-group">
								<label className="control-label">Name</label>
								<input name="name" placeholder="Your name" type="text" value={values.name} onChange={handleChange} onBlur={handleBlur} className={
										errors.name && touched.name ? 'form-control error' : 'form-control'
									} />
								{errors.name && touched.name && <div className="input-feedback">{errors.name}</div>}
							</div>
							<div className="form-group">
								<label className="control-label">Company</label>
								<input name="company" placeholder="Your company name" type="text" value={values.company} onChange={handleChange} onBlur={handleBlur} className={
										errors.company && touched.company ? 'form-control error' : 'form-control'
									} />
								{errors.company && touched.company && <div className="input-feedback">{errors.company}</div>}
							</div>
							<div className="form-group">
								<label className="control-label">Signup as a &nbsp;&nbsp;&nbsp;</label>
								<ControlLabel className="radio">Project Manager <FormControl type="radio" name="role" value='company' onChange={handleChange} className={
									errors.role && touched.role ? 'error' : ''
								} /><span className="checkround" /></ControlLabel>
								<ControlLabel className="radio">Query Manager <FormControl type="radio" name="role" value='query_manager' onChange={handleChange} className={
									errors.role && touched.role ? 'error' : ''
								} /><span className="checkround" /></ControlLabel>
								{errors.role && touched.role && <div className="input-feedback">{errors.role}</div>}
							</div>
							<div className="form-group">
							<input type="checkbox" name="terms" onChange={handleChange} className={
								errors.terms && touched.terms ? 'terms error' : 'terms'
							} /> &nbsp;&nbsp;By signing up I agree to all <a href="/terms" target="_blank">Terms & Privacy</a> policy
							{errors.terms && touched.terms && <div className="input-feedback">{errors.terms}</div>}
							</div>
							<button type="submit" disabled={isSubmitting} className="btn btn-success btn-block" style={{fontSize: '16px'}}>Complete Signup</button>
						</form>
			)}
			</Formik>
			<Formik
				initialValues={initialValues3}
				validationSchema={schema3} 
				onSubmit={(values, { setSubmitting, resetForm }) => {
					values.email = this.state.email;
					UserService.verify_email(values)
						.then(response => {
							if(response.data.success) {
								setSubmitting(false);
								resetForm();
								this.setState({
									verify_form: 'hide'
								});
								this.props.goBack();
								toast.success("😀 Congrats! You have successfully verified your email address. Now you can login and start using listenyzen!", {
									autoClose: false,
									position: 'bottom-right',
									className: 'notification-message'
								});
							} else {
								setSubmitting(false);
								toast.error("😪 Sorry! We could not verify your email address. Please check your email again & enter valid verification code again.", {
									autoClose: 5000,
									position: 'bottom-right',
									className: 'notification-message'
								});
							}
						})
						.catch(err => {
							setSubmitting(false);
						});
				}}
			>
				{({
						values,
						touched,
						errors,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit
					}) => (
						<form onSubmit={handleSubmit} className={this.state.verify_form}>
							<div className="form-group">
								<label className="control-label">Verification Code</label>
								<input name="verification_code" placeholder="Enter verification code" type="number" value={values.verification_code} onChange={handleChange} onBlur={handleBlur} className={
										errors.verification_code && touched.verification_code ? 'form-control error' : 'form-control'
									} />
								{errors.verification_code && touched.verification_code && <div className="input-feedback">{errors.verification_code}</div>}
							</div>
							<button type="submit" disabled={isSubmitting} className="btn btn-success btn-block" style={{fontSize: '16px'}}>Verify Account</button>
						</form>
			)}
			</Formik>
			</>
		);
	}
}

export default withRouter(LoginForm);