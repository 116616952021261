import axios from '../api/private';

export const gettagByProject = (project_id) => {
	return axios.get('/tagsByProject/' + project_id);
}

export const deleteTagbyProject = (project_id, tag_id) => {
	return axios.post('/tag/delete/' + project_id, {
		tag_id: tag_id
	});
};