import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { connectViber } from '../../../services/ProjectService';

class ViberModal extends Component {
	schema = yup.object({
		access_token: yup.string().required('Viber Access Token is required')
	});

	initialValues = { access_token: '' };

	render() {
		return (
			<Modal className="add-project-modal" show={this.props.show} onHide={this.props.manage.bind(this, 'viber_modal')}>
				<Formik
					initialValues={this.initialValues}
					validationSchema={this.schema} 
					onSubmit={(values, { setSubmitting }) => {
						// process
						let formData = new FormData();
						formData.append('access_token', values.access_token);
						formData.append('project_id', this.props.project_id);
						connectViber(formData)
							.then(response => {
								if(response.data && response.data.message === 'Connection successful') {
									this.props.manage('viber_modal', this.props.project_id, [], { access_token: values.access_token });
									setSubmitting(false);
								}
							})
							.catch(err => {
								setSubmitting(false);
							});
					}}
				>
					{({
						values,
						touched,
						errors,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit,
						setFieldValue
					}) => (
						<form onSubmit={handleSubmit}>
							<Modal.Header closeButton>
								<Modal.Title>Connect Viber</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div className="form-group">
									<label className="control-label">Viber Access Token</label>
									<input type="text" id="project-name" name="access_token" value={values.access_token} onChange={handleChange} onBlur={handleBlur} className={
										errors.access_token && touched.access_token ? 'form-control error' : 'form-control'
									} />
									{errors.access_token && touched.access_token && <div className="input-feedback">{errors.access_token}</div>}
								</div>
							</Modal.Body>
							<Modal.Footer className="text-right">
								<button type="submit" disabled={isSubmitting} className="btn btn-primary">Submit </button>
							</Modal.Footer>
						</form>
					)}
				</Formik>
			</Modal>
		);
	}
}

export default ViberModal;