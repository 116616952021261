import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TableSection from '../../components/DdashboardContent/Table/Table';
import * as actions from '../../store/actions/report_actions';

class DdashboardContent extends Component {
	
	componentWillUnmount() {
		this.props.clearReports();
	}

	render() {
		return (
			<div className="analytics-dashboard-content">
				<div className="row">
					<div className="col-sm-12">
						<TableSection />
					</div>
				</div>
			</div>
		);
	}
};

const mapActionToProps = dispatch => {
	return {
		clearReports: () => dispatch(actions.clearReports())
	};
};

export default withRouter(connect(null, mapActionToProps)(DdashboardContent));