import React, { Component } from 'react';
import { connect } from 'react-redux';
import loader from '../../../assets/img/loader.svg';
import TagCloud from 'react-tag-cloud';
import randomColor from 'randomcolor';

class TagCloudSection extends Component {
	typingTimer;
	componentDidMount() {
		this.typingTimer = setInterval(() => {
		  this.forceUpdate();
		}, 4000);
	}

	componentWillUnmount() {
		clearTimeout(this.typingTimer);
	}

	render() {
		var final_tags = this.props.tags.sort((a, b) => (a.count > b.count) ? -1 : 1);
		return (
			<div className="tag-cloud-graph">
				<div className="panel panel-default">
					<div className="panel-heading">
						<h3 className="panel-title">Most Used Tags</h3>
					</div>
					<div className="panel-body" style={{height: '400px', textAlign: 'center'}}>
						{this.props.tagloading ? <img src={loader} alt="loader" className="aligncenter" /> : this.props.tags && this.props.tags.length > 0 ? <><button className="btn cwz" onClick={this.props.manage.bind(this, 'tag', this.props.tags)}>Detail Report</button><TagCloud 
							className='tag-cloud'
							style={{
								fontFamily: 'SF Display',
								color: () => randomColor(),
								padding: 5,
								width: '100%',
								height: '100%'
						}}>
							{final_tags.slice(0, 50).map((tag, index) => <div key={index} style={{fontSize: ((50 * tag.count) / final_tags[0].count) > 10 ? ((50 * tag.count) / final_tags[0].count) : 12}}>{tag._id}</div>)}
						</TagCloud></> : <p className="aligncenter">No data available</p>}
					</div>
				</div>
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		tagloading: state.report_state.tagloading,
		tags: state.report_state.tags
	};
}

export default connect(mapStateToProps)(TagCloudSection);