import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import * as UserService from '../../services/UserService';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = UserService.isLoggedIn();
  return (
    <Route
      {...rest}
      render={props =>
        !isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/projects', state: { from: props.location } }} />
        )
      }
    />
  )
}

export default PrivateRoute